export default { 
  Dashboard: require('./Dashboard').default,
  Certif: require('./certificate').default,
  Account: require('./account').default,
  Home: require('./home').default,
  MyClass: require('./my-class').default,
  Subscription: require('./subscription').default,
  Checkpoint: require('./checkpoint').default,
  Jobs: require('./jobs').default,
  Applyment: require('./applyment').default,
  Company: require('./companys').default,
}