var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user_sistem.talent_social_medias.length > 0)?_c('div',{staticStyle:{"margin-top":"2rem","margin-bottom":"2rem","border":"1px solid #E5E7EB","border-radius":"10px","font-family":"'Inter', sans-serif","padding":"20px"}},[_c('div',{staticStyle:{"font-size":"18px","font-weight":"600","margin-bottom":"1rem"}},[_vm._v(" Sosial Media ")]),_vm._l((_vm.user_sistem.talent_social_medias),function(socialMedia,i){return _c('div',{key:i},[_c('div',{staticStyle:{"display":"flex","justify-content":"start","align-items":"center","align-content":"center","gap":"15px","margin-top":"1rem"}},[_c('i',{class:`ri-${socialMedia.talent_social_media_name.toLowerCase()}-fill`,style:(`
          font-size: 20px;
          color: ${_vm.setMediaColor(socialMedia.talent_social_media_name)}
        `)}),_c('a',{style:(`
          font-size: 14px;
          color: ${_vm.setMediaColor(socialMedia.talent_social_media_name)}
        `),attrs:{"href":socialMedia.talent_social_media_url,"target":"_blank"}},[_c('div',{staticStyle:{"font-weight":"500"}},[_vm._v(" "+_vm._s(socialMedia.talent_social_media_name)+" ")]),_c('div',{staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(socialMedia.talent_social_media_url)+" ")])])])])})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }