<template>
  <v-row align-content="center">
    <v-col v-show="regis" class="py-0" md="12">
      <v-row >
        <v-col class="white pa-0" style="height: 100vh;">
          <v-row justify="center" align="center" :style="$vuetify.breakpoint.name == 'xs' ? '' : 'height: 100vh;'">
            <v-col cols="12" md="8" :class="$vuetify.breakpoint.name == 'xs' ? 'px-8 mt-6' : ''">
              <div class="fs-24 font-weight-bold">Daftar Akun Peserta</div>
              <div class="fs-14 text-gray-500">Daftar menggunakan Email Anda</div>

              <AuthRegisterUser @submit="submitRegister" />

            </v-col>
          </v-row>
        </v-col>
        <v-col class="pa-0" :order="$vuetify.breakpoint.name == 'xs' ? 'first': 'last'">
          <v-img
            style="object-fit: cover; object-position: center;"
            :style="$vuetify.breakpoint.name == 'xs' ? 'width: 100vw;' : 'height: 100vh;'"
            :src="require(`@/assets/images/${$vuetify.breakpoint.name == 'xs' ? 'bg-register-mobile.png' : 'bg-login.png'}`)">
          </v-img>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-show="verify" class="py-0 mx-auto" md="12">
      <v-row>
        <v-col class="white mx-auto" md="6" style="height: 100vh">
          <v-spacer class="py-16"></v-spacer>
          <div class="px-8">
            <v-row class="mb-0" justify="center">
              <v-col sm="6" lg="12" class="text-center">
                <v-icon class="text-h2 color-strike">mdi-message-processing-outline</v-icon>
                <div class="text-h5 color-strike">Masukan Kode Verifikasi</div>
                <p class="text-subtitle-1 color-disable">
                  Kode verifikasi telah dikirim melalui WA ke {{ this.nomer }}
                </p>
              </v-col>
            </v-row>
            <v-form>
              <v-container>
                <v-row>
                  <v-col id="kode" cols="12" sm="6" class="mx-auto py-0">
                    <v-otp-input
                      :disabled="loading"
                      @finish="verifikasi()"
                      length="6"
                      v-model="kodeOtp"
                    ></v-otp-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <div class="text-subtitle-2 text-center color-disable mb-3">
              <span v-show="timerMessage"
                >Mohon tunggu dalam <b>{{ totalSeconds | minutesAndSeconds }} detik </b> untuk kirim ulang</span
              >
              <span v-show="resend">
                Tidak menerima kode ?
                <v-btn plain color="primary" @click="sendOtp()" class="pa-0 font-weight-bold">Kirim ulang </v-btn>
              </span>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-snackbar top v-model="snackbar.state" color="primary" outlined>
        <div v-html="snackbar.text"></div>
        <template v-slot:action="{ attrs }">
          <v-btn small icon color="error" v-bind="attrs" @click="snackbar.state = false">
            <v-icon>$close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-col>
  </v-row>
</template>

<script>
import AuthRegisterUser from './_components/AuthRegisterUser.vue';
import { LMS_API } from "@/constants/api";

export default {
  name: "registration",
  components: {
    AuthRegisterUser
  },
  data() {
    return {
      show: false,
      errors: [],
      regis: true,
      verify: false,
      valid: true,
      checkbox: false,
      nomer: "",
      password: "",
      kodeOtp: "",
      totalSeconds: 60,
      timer: {},
      interval: {},

      dialog: false,

      form: {
        name: "",
        email: "",
        phone: "",
        password: "",
        password_confirm: ""
      },

      submiting: false,
      timerMessage: true,
      resend: false,

      snackbar: {
        state: false,
        text: "",
      },
      submitingSend: false,
      submitingOtp: false,
      response: null,
      loading:false,

    };
  },
  computed: {},
  filters: {
    minutesAndSeconds(value) {
      var seconds = `${value % 60}`.padStart(2, 0);
      return `${seconds}`;
    },
  },
  methods: {
    submitRegister(form_data) {
      this.form = form_data;
      // this.dialog = true;
      this.verify = true;
    },
    otp() {
      this.submitingSend = true;

      let access_key = process.env.VUE_APP_ACCESS_KEY;
      let provider = "email"
      let first_name = this.form.name
      let phone = this.form.phone
      let email  = this.form.email
      let password = this.form.password
      let confirm_password = this.form.password_confirm

      this.axios
        .post(`${LMS_API.AUTH.REGISTER}`,
          { provider, access_key, phone, password, confirm_password, first_name, email },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          // let res = response.data;
          this.verify = true;
          // this.dialog = false;
          this.regis = false;
          this.timer_start();
        })
        .catch((error) => {
          this.submitting = false;
            this.dialog=false;
            this.response = error.response.data.message;
            setTimeout(() => {this.response = null;}, 4000);

          // if (res.response) {
          //   this.snackbar.text = res.response.data.message;
          //   this.snackbar.state = true;
          // }
        })
        .finally(() => {
          this.submitingSend = false;
        });
    },
    timer_start() {
      this.timer = setInterval(() => {
        if (this.totalSeconds === 0) {
          this.totalSeconds = 60;
          clearInterval(this.timer);
          this.timerMessage = false;
          this.resend = true;
        }
        this.totalSeconds -= 1;
      }, 1000);
    },
    sendOtp() {
      this.timerMessage = true;
      this.resend = false;
      this.otp();
    },
    verifikasi() {
      this.submitingOtp = true;

      let access_key = process.env.VUE_APP_ACCESS_KEY;
      let phone = this.nomer;
      let otp = this.kodeOtp;

      this.axios
        .post(
          `/users/v1/member/auth/verify_otp`,
          { access_key, phone, otp },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.submitingOtp = false;
          // let res = response.data;
          if (res.status == 200) {
            this.$router.push({ name: "login" });
          }
        })
        .catch((res) => {
          this.submitting = false;
          this.verify = true;
          this.regis = false;
          this.kodeOtp='';
          if (res.response) {
            this.snackbar.text = res.response.data.message;
            this.snackbar.state = true;
          }
        })
        .finally(() => {
          this.submitingOtp = false;
        });
    },
  },
};
</script>

<style>
#line {
  width: 100%;
  height: max-content;
  text-align: center;
  position: relative;
}

#line:after {
  content: "";
  width: 100%;
  border-bottom: solid 1px #e0e6ed;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 1;
}

#line span {
  width: auto;
  background: #fff;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}
#kode .v-text-field input {
  font-size: 35px;
  text-align: center;
  letter-spacing: 10px;
}
.v-text-field--enclosed .v-input__append-inner {
  margin-top: 12px;
}
</style>
