<template>
  <v-card outlined flat class="rounded-lg">
    <v-card-title>
      Tentang Saya 
      <v-spacer></v-spacer>
      <v-btn 
        v-if="!showDescription" 
        color="#1F2A37"
        style="border: 1px solid #E5E7EB"
        class="rounded-lg"
        x-small
        outlined
        @click="() => {
          this.talent_about_me = profileData
          showDescription = !showDescription
        }">
        Ubah
      </v-btn>
      <div v-else>
        <v-btn
          color="primary"
          x-small
          depressed
          class="rounded-lg"
          @click="onSaveTalentProfileResume()">
          Simpan
        </v-btn>
        <v-btn 
          color="#1F2A37"
          style="border: 1px solid #E5E7EB"
          class="ml-2 rounded-lg"
          x-small
          outlined
          @click="showDescription = !showDescription">
          Tutup
        </v-btn>
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text v-show="!showDescription">

      <div class="text-left" v-if="profileData">
        {{ profileData }}
      </div>

      <div class="text-center" v-else>
        Beritahu perusahaan apa yang membuatmu unggul untuk dipekerjakan
      </div>

    </v-card-text>
    <v-expand-transition>
      <v-card-text v-show="showDescription">
        <v-textarea
          outlined
          auto-grow
          v-model="talent_about_me"
          placeholder="Tuliskan Deskripsi Diri"
          class="rounded-lg"
          rows="6">
        </v-textarea>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  props: {
    profileData: {
      type: String,
      default: () => ""
    }
  },
  data: () => ({
    showDescription: false,

    talent_about_me: ""
  }),
  methods: {
    onSaveTalentProfileResume() {

      this.showDescription = !this.showDescription;

      this.$emit("save", this.talent_about_me);
    }
  }
}
</script>