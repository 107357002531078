<template>
  <v-row>
    <v-col cols="12">

      <Snackbar ref="snackbar" />

      <v-card outlined class="rounded-lg">
        <v-card-title  style="background: #F9FAFB; height: 100px;position: relative;" class="py-0">
          <v-switch
            inset
            dense
            small
            v-model="open_to_work"
            @change="onUpdateOpenToWork"
            class="font-weight-regular caption"
            :label="`Siap Bekerja`">
          </v-switch>
          <v-spacer></v-spacer>
          <v-menu
            bottom
            offset-y
            origin="center center"
            transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="rounded-lg black--text"
                style="border: 1px solid #E5E7EB;"
                depressed
                outlined
                x-small
                v-bind="attrs"
                v-on="on"
              >
                Pengaturan
                <v-icon right>ri-arrow-down-s-fill</v-icon>
              </v-btn>
            </template>

            <v-list outlined dense class="rounded-lg pa-0">
              <v-list-item @click="() => { $router.push('/account/edit') }">
                <v-list-item-title>Edit Profile</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item  @click="() => { $router.push('/account/password') }">
                <v-list-item-title>Ubah Password</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-avatar
            size="80"
            style="position: absolute; right: 50%; top: 60%; left: 40%;">
            <img 
              v-if="user_sistem.talent_photo_url"
              :src="user_sistem.talent_photo_url" 
              alt="image profile">
            <img 
              v-else
              :src="require('@/assets/images/no-pict.png')" 
              alt="image profile">
          </v-avatar>
        </v-card-title>
        <v-card-text class="justify-center mt-16 text-center d-flex flex-column align-center">

          <div class="mb-2 body-1 font-weight-medium black--text">
            {{ user_fullname }}
          </div>

          <div class="justify-center mb-2 d-flex align-center" style="gap: 15px;">
            <div class="d-flex">
              <img src="@/assets/images/icon/user-outline.svg" alt="">
              <div class="ml-1">{{ user_ages }} Tahun</div>
            </div>
            <div class="d-flex">
              <img src="@/assets/images/icon/dna-outline.svg" alt="">
              <div class="ml-1">{{ user_sistem.talent_gender ? user_sistem.talent_gender : "-"  }}</div>
            </div>
            <!-- <div class="d-flex">
              <img src="@/assets/images/icon/users-outline.svg" alt="">
              <div class="ml-1">Lajang</div>
            </div> -->
          </div>

          <div class="justify-center d-flex align-center" style="gap: 1rem;">
            <div class="d-flex">
              <img src="@/assets/images/icon/mail-box-outline.svg" alt="">
              <div class="ml-1">{{ user_sistem.talent_email }}</div>
            </div>
            <div class="d-flex">
              <img src="@/assets/images/icon/phone-outline.svg" alt="">
              <div class="ml-1">{{ user_sistem.talent_phone ? user_sistem.talent_phone : "xxxx-xxxx-xxxx" }}</div>
            </div>
          </div>
          
          <v-chip small class="py-3 my-3 font-weight-regular" color="#F3F4F6" label>
            <v-icon left size="16">ri-map-pin-line</v-icon>
            {{ user_sistem.talent_address ? user_sistem.talent_address.address_district.district_name : "-" }}
          </v-chip>
          <v-btn
            color="#1F2A37"
            style="border: 1px solid #E5E7EB"
            class="rounded-lg"
            href="/account/resume"
            x-small
            depressed
            outlined>
            <v-icon left>ri-download-2-line</v-icon>
            Unduh Resume Anda
          </v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>

          <div class="font-weight-medium black--text">Lengkapi informasi profile & resume Anda</div>

          <div class="mb-2 d-flex flex-column">
            <div class="text-right navy-soft--text">{{ countProgress/10 }}/10</div>
            <v-progress-linear
              background-color="#E5E7EB"
              height="6"
              rounded
              :value="countProgress">
            </v-progress-linear>
          </div>

          <div class="caption">
            Silahkan lengkapi informasi data diri dan unggah resume Anda untuk mendapatkan rekomendasi loker
          </div>

        </v-card-text>
      </v-card>

    </v-col>
  </v-row>
</template>

<script>

import { JOB_PORTAL_API } from "@/constants/api";

import moment from "moment";
import { mapGetters } from "vuex";

import Snackbar from "@/components/Snackbar";

export default {
  name: "AccountProfile",
  components: {
    Snackbar
  },
  data: () => ({
    open_to_work: false,
    certificate_list: []
  }),
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["user_sistem"]),

    user_fullname() {
      return this.user_sistem.talent_first_name + ' ' + this.user_sistem.talent_last_name
    },

    user_ages() {
      let dob = new Date(moment(this.user_sistem.talent_birth_date).format("MM/DD/YYYY"));

      //calculate month difference from current date in time
      let month_diff = Date.now() - dob.getTime();

      //convert the calculated difference in date format
      let age_dt = new Date(month_diff); 
      
      //extract year from date    
      let year = age_dt.getUTCFullYear();
      
      //now calculate the age of the user
      let age = Math.abs(year - 1970);

      return age

    },

    countProgress() {
      let count = 0

      if (this.user_fullname && this.user_ages) count++
      if (this.user_sistem.talent_about_me) count++
      if (this.user_sistem.talent_work_experiences.length > 0) count++
      if (this.user_sistem.talent_educations.length > 0) count++
      if (this.user_sistem.talent_skills.length > 0) count++
      if (this.user_sistem.talent_organization_experiences.length > 0) count++
      if (this.user_sistem.talent_preference.talent_preference_job_categories.length > 0) count++
      if (this.user_sistem.talent_social_medias.length > 0) count++
      if (this.user_sistem.talent_language && this.user_sistem.talent_language.length > 0) count++
      if (this.certificate_list.length > 0) count++

      return count*10
    },
  },
  mounted() {
    this.getMe()
    this.onGetListCertificate()
  },
  methods: {
    // GET ME
    async getMe() {
      await this.axios.get(`${JOB_PORTAL_API.USER.GET_ME}`)
      .then((response => {
        let res = response.data
        this.open_to_work = res.open_to_work
      }))
    },
    async onGetListCertificate() {
      await this.axios.get(`${JOB_PORTAL_API.CERTIFICATE.GET_LIST}`, {
        params: {
          talent_id: this.user_sistem.talent_id
        }
      })
      .then((response) => {
        this.certificate_list = response.data.data
      }).catch((error) => {
        this.$refs.snackbar.open('error', error.response.data.message)
      })
    },
    async onUpdateOpenToWork() {
      await this.axios.put(`${JOB_PORTAL_API.USER.OPEN_TO_WORK}`, {
        open_to_work: this.open_to_work
      })
      .then(() => {
        this.$refs.snackbar.open("success", "Berhasil mengubah status siap bekerja")
      }).catch(() => {
        this.$refs.snackbar.open("error", "Gagal mengubah status siap bekerja")
      })
    }
  }
}
</script>