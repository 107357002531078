var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"transparent px-2",attrs:{"elevation":"0"},on:{"click":_vm.showCertif}},[_c('vue-html2pdf',{ref:"certifPeserta",attrs:{"enable-download":true,"preview-modal":false,"show-layout":true,"pdf-quality":2,"filename":_vm.certificateName,"pdf-orientation":"landscape","manual-pagination":true,"pdf-content-width":"100%","pdf-format":"legal"},on:{"hasDownloaded":function($event){return _vm.hasGenerated($event)}}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('section',{staticClass:"pdf-item",style:({
        backgroundImage: `url('${require('@/assets/images/certificate/bg-new-certif.jpg')}')`,
        backgroundSize: `100% 100%`,
        backgroundPosition: `center center`,
        backgroundRepeat: `no-repeat`,
        width: `100%`,
        height: `810px`,
        color: `#4B4B4B`,
      })},[(Object.keys(_vm.data).length > 0)?_c('div',{staticClass:"style1"},[_c('div',{staticStyle:{"font-style":"normal","text-align":"center","font-family":"Urbanist, sans-serif","font-weight":"900","font-size":"50px","line-height":"auto","margin-top":"40dvh","color":"black"}},[_vm._v(" "+_vm._s(_vm.data.user_name)+" ")]),_c('div',{staticStyle:{"font-style":"normal","font-family":"Urbanist, sans-serif","font-weight":"800","font-size":"20px","text-align":"center","line-height":"20px","margin-top":"10dvh","color":"black"}},[_vm._v(" Periode "+_vm._s(_vm.certificatePeriode(_vm.data.created_at))+" ")])]):_vm._e()]),(_vm.data.program_name.toLowerCase() == 'TEFA DevOps'.toLowerCase())?_c('section',{staticClass:"pdf-item",style:({
          backgroundImage: `url('${require('@/assets/images/certificate/DevOps.png')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `790px`,
          color: `#4B4B4B`,
        })}):_vm._e(),(_vm.data.program_name.toLowerCase() == 'Quality Assurance'.toLowerCase())?_c('section',{staticClass:"pdf-item",style:({
          backgroundImage: `url('${require('@/assets/images/certificate/QualityAssurance.png')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `790px`,
          color: `#4B4B4B`,
        })}):_vm._e(),(_vm.data.program_name.toLowerCase() == 'Project Manager'.toLowerCase())?_c('section',{staticClass:"pdf-item",style:({
          backgroundImage: `url('${require('@/assets/images/certificate/ProjectManager.png')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `790px`,
          color: `#4B4B4B`,
        })}):_c('section',{staticClass:"pdf-item",style:({
        backgroundImage: `url('${require('@/assets/images/certificate/bg-new-certif-2.jpg')}')`,
        backgroundSize: `100% 100%`,
        backgroundPosition: `center center`,
        backgroundRepeat: `no-repeat`,
        width: `100%`,
        height: `810px`,
        color: `#4B4B4B`,
      })},[(Object.keys(_vm.data).length > 0)?_c('div',{staticStyle:{"padding-top":"200px"}},[_c('div',{staticStyle:{"text-align":"center","margin-bottom":"15px"}},[_c('img',{staticStyle:{"width":"200px"},attrs:{"src":require("@/assets/images/logo-dash.png"),"alt":"","srcset":""}})]),_c('div',{staticStyle:{"font-family":"Roboto","font-style":"normal","font-weight":"bold","font-size":"26px","line-height":"47px","text-align":"center","letter-spacing":"0.015em","margin-top":"10px"}},[_vm._v(" KOMPETENSI YANG DILATIH ")]),_c('div',{staticStyle:{"font-family":"Roboto","font-weight":"normal","font-size":"16px","line-height":"20px","text-align":"center","margin-top":"4px"}},[_vm._v(" "+_vm._s(_vm.data.program_name)+" ")]),_c('div',{staticStyle:{"font-family":"Roboto","font-style":"normal","font-weight":"400","font-size":"14px","line-height":"16px","text-align":"left","margin-top":"30px","display":"flex","justify-content":"center"}},[_c('div',[_c('p',{staticStyle:{"font-weight":"bold","font-size":"16px","text-align":"center","margin-bottom":"10px","margin-top":"0px"}},[_vm._v(" Kompetensi")]),_vm._l((_vm.data.competence),function(item,index){return _c('div',{key:index,staticStyle:{"padding-left":"0px","margin-left":"0px"}},[_c('p',{staticStyle:{"margin-bottom":"2px","font-size":"14px"}},[_vm._v(" "+_vm._s(index + 1)+". "+_vm._s(item)+" ")])])})],2)])]):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }