<template>
  <div style="width: 1024px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;">
    <AccountResumePDF />
  </div>
</template>


<script>
import AccountResumePDF from './_components/AccountResumePDF.vue'

export default {
  name: 'AccountResume',
  components: {
    AccountResumePDF
  },
  mounted() {
    // console.log('mounted', window);
    if (window.innerWidth < 768) {
      document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=1024, user-scalable=no');
    }else {
      document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0');
    }
  },
  beforeDestroy() {
    // window.addEventListener('resize', this.setViewport);
    document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0');
  },
  methods: {
    setViewport() {
      if (window.innerWidth < 768) {
        // if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
        //   let viewportmeta = document.querySelector('meta[name="viewport"]');
        //   if (viewportmeta) {
        //     viewportmeta.setAttribute('content', 'width=1024, user-scalable=no');
        //     document.documentElement.style.width = '1024px'; // Set HTML width
        //     document.body.style.width = '1024px'; // Set body width
        //     document.body.style.overflowX = 'scroll'; // Enable horizontal scrolling
        //   }
        // }else {
          document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=1024, user-scalable=no');
        // }
      }else {
        document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0');
      }
    }
  }
}
</script>