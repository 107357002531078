<template>
  <v-container style="height: 100vh; font-family: 'Inter', sans-serif;">
    <v-row justify="center" align="center">
      <v-col cols="12" md="12" class="d-flex justify-space-between">
        <v-btn
          text
          color="primary"
          small
          @click="() => {
            $router.push('/account')
          }">
          <v-icon left>ri-arrow-left-s-line</v-icon>
          Kembali
        </v-btn>
        <div v-if="$vuetify.breakpoint.name !== 'xs'" class="text-h3 text-decoration-underline">
          Data Resume Anda
        </div>
        <v-btn
          text
          small
          :loading="isloading"
          @click="exportToPDF()">
          <!-- @click="windowPrint"> -->
          <v-icon color="orange" left>ri-download-2-line</v-icon>
          Unduh Resume Anda
        </v-btn>
      </v-col>
      <v-col cols="12" md="12" class="text-center">
        <div v-if="$vuetify.breakpoint.name == 'xs'" class="text-h5 text-decoration-underline">
          Data Resume Anda
        </div>
      </v-col>
      <!-- <v-col v-show="showCertif">
        <vue-html2pdf
          ref="certifPeserta" 
          :enable-download="true" 
          :preview-modal="true" 
          :show-layout="false" 
          :pdf-quality="2"
          :filename="`Resume-${this.user_fullname}`" 
          pdf-orientation="landscape" 
          :manual-pagination="true" 
          pdf-content-width="100%"
          pdf-format="legal"
          @hasDownloaded="hasGenerated($event)">
          <section slot="pdf-content">
            <section slot="pdf-item">
              <div 
                style="
                  display: flex; 
                  justify-content: space-between;
                  align-items: start;
                  gap: 30px;
                ">

                <div style="flex:1;">
                  <DataDiri />

                  <AboutMe />

                  <Experiences />
                  
                  <Educations />

                  <Certificates />

                  <Organizations />


                </div>

                <div style="width: 300px">
                  <Languages />

                  <Skills />
                  
                  <SocialMedia />
                </div>
              </div>
            </section>
          </section>
        </vue-html2pdf>
      </v-col> -->
      
      <v-col cols="12" md="12" class="d-flex justify-center align-center" id="print-this-element">
        <v-card elevation="24" class="rounded-lg" style="width: 1000px;">
          <div class="pa-10">
            <div ref="document">
              <div id="element-to-convert">

                <div 
                  style="
                    display: flex; 
                    justify-content: space-between;
                    align-items: start;
                    gap: 30px;
                  ">

                  <div style="flex:1;">
                    <DataDiri />

                    <AboutMe />

                    <Experiences />
                    
                    <Educations />

                    <Certificates />

                    <Organizations />


                  </div>

                  <div style="width: 300px">
                    <Languages />

                    <Skills />
                    
                    <SocialMedia />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import html2pdf from 'html2pdf.js'

import moment from 'moment'
import { mapGetters } from 'vuex'

// import VueHtml2pdf from "vue-html2pdf";
// import PdfResumeContent from './PdfResumeContent.vue'

import DataDiri from './_components_resume/data_diri.vue'
import AboutMe from './_components_resume/about_me.vue'
import Experiences from './_components_resume/experiences.vue'
import Educations from './_components_resume/educations.vue'
import Certificates from './_components_resume/certificates.vue'
import Skills from './_components_resume/skills.vue'
import Languages from './_components_resume/languages.vue'
import Organizations from './_components_resume/organizations.vue'
import SocialMedia from './_components_resume/social_media.vue'

export default {
  name: 'AccountResumePDF',
  components: {
    // VueHtml2pdf,
    // PdfResumeContent,

    DataDiri,
    AboutMe,
    Experiences,
    Educations,
    Certificates,
    Skills,
    Languages,
    Organizations,
    SocialMedia,
  },
  data() {
    return {
      isloading: false
    }
  },
  computed: {
    ...mapGetters(['user_sistem']),
    user_fullname() {
      return this.user_sistem.talent_first_name + ' ' + this.user_sistem.talent_last_name
    },
    user_skills() {
      return this.user_sistem.talent_skills
    },
    initialName() {
      let firstAlias = "";
      let secondAlias = "";
      let name = this.user_sistem ? this.user_fullname : "USER TESTING";
        let splitname = name.split(" ");

        if (splitname[0]) {
          firstAlias = splitname[0].substring(0, 1);
        }

        if (splitname[1]) {
          secondAlias = splitname[1].substring(0, 1);
        }
      return firstAlias + secondAlias;
    }
  },
  mounted() {},
  methods: {
    hasGenerated($event) {
      console.log($event);
    },
    showCertif() {
      this.$refs.certifPeserta.generatePdf();
    },
    windowPrint() {
      // let printContents = document.getElementById('element-to-convert').innerHTML;
      let printContents = document.getElementById('print-this-element').innerHTML;
      var a = window.open('', '', ''); 
      a.document.write('<html>'); 
      a.document.write('<body>'); 
      a.document.write(printContents); 
      a.document.write('</body></html>'); 
      a.document.close(); 
      a.print(); 
      // window.print(printContents);
    },
    async exportToPDF() {
      this.isloading = true
      const element = document.getElementById('print-this-element')
      await html2pdf()
        .set({
          margin: 3,
          filename: `Resume-${this.user_fullname}-${moment(new Date()).format("YYMMDDHHmmss")}.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          enableLinks: true,
          html2canvas: { scale: 2 },
          jsPDF: { 
            font: 'Inter',
            unit: 'em', 
            // format: 'letter',
            format: [70, 85],
            orientation: 'portrait' 
          }
        })
        .from(element)
        .toPdf()
        .save()

      this.isloading = false
    }
  }
}
</script>

<style scoped>

@media print and (width: 24cm) and (height: 29.7cm) {
  @page {
    margin: 3cm;
  }
  .print-this-element {
    display: block;
  }
}
</style>
