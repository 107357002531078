<template>
  <v-card outlined flat class="mt-4 rounded-lg">
    <v-card-title>
      Bahasa 
      <v-spacer></v-spacer>
      <v-btn 
        v-if="!showLanguage" 
        color="#1F2A37"
        style="border: 1px solid #E5E7EB"
        class="rounded-lg"
        x-small
        outlined
        @click="showLanguage = !showLanguage">
        Tambah
      </v-btn>
      <div v-else>
        <v-btn 
          color="primary"
          x-small
          depressed
          class="rounded-lg"
          @click="onSaveLanguage()">
          Simpan
        </v-btn>
        <v-btn 
          color="#1F2A37"
          style="border: 1px solid #E5E7EB"
          class="ml-2 rounded-lg"
          x-small
          outlined
          @click="showLanguage = !showLanguage">
          Tutup
        </v-btn>
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text v-show="!showLanguage">

      <div class="my-3 text-left" v-if="profileData.length > 0">

        <v-row>
          <div v-for="(lang, index) in profileData" :key="index">
            <v-chip
              class="ma-2"
              small
              label
              color="#E5E7EB"
              @click="onDeleteLangage(index)">
              <div class="mr-2 black--text">{{ lang }}</div>
              <v-icon right size="16" color="#1F2A37">mdi-close</v-icon>
            </v-chip>
          </div>
        </v-row>

      </div>

      <div class="text-center" v-else>
        Beritahu perusahaan terkait kemampuan berbahasa Anda
      </div>
    </v-card-text>
    <v-expand-transition>
      <v-card-text v-show="showLanguage">
        
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
          <div>
            <div class="mb-1 font-weight-medium">Bahasa</div>
            <v-text-field
              outlined
              class="rounded-lg"
              dense
              :rules="[rules.required]"
              v-model="form.talent_language"
              persistent-hint
              hint="Contoh: Indonesia, Inggris, Jepang, dll"
              placeholder="Tuliskan Kemampuan Bahasa. Contoh: Indonesia, Inggris">
            </v-text-field>
          </div>
        </v-form>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  props: {
    profileData: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    valid: true,
    showLanguage: false,

    state_index: 0,
    state_id: "",
    state_form: "create",

    rules: {
      required: value => !!value || 'field tidak boleh kosong',
    },
    
    form: {
      talent_language: ''
    }
  }),
  methods: {
    onSaveLanguage() {
      // this.$refs.snackbar.open('success', 'Bahasa berhasil disimpan!')

      if(!this.$refs.form.validate()) return

      this.showLanguage = false

      this.$emit("save", this.form.talent_language)
      

      // this.onSaveTalentProfileResume()
    },

    onDeleteLangage(index) {
      this.talent_language = [...this.profileData]

      this.talent_language.splice(index, 1)

      this.form.talent_language = this.talent_language.join(",")

      // this.$refs.snackbar.open('success', 'Bahasa berhasil dihapus!')

      this.$emit("save", this.form.talent_language)

      // this.onSaveTalentProfileResume()
    },
  }
}
</script>