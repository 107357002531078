<template>
  <v-card>
    <v-card-title class="white--text primary py-2 justify-center">
      <div>{{ title }}</div>
    </v-card-title>
    <v-row class="pa-4">
      <v-col>
        <input-discussion input @click:send="addDiscussion" />
        <input-discussion
          v-for="(item, index) in discussion"
          :key="index"
          :data="item"
          @click:send="replyDiscussion($event)"
        />
      </v-col>
    </v-row>

    <v-snackbar top v-model="snackbar.state" :color="snackbar.color" outlined :timeout="snackbar.timeout">
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn small icon color="error" v-bind="attrs" @click="snackbar.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { LMS_API } from "@/constants/api"

import InputDiscussion from "./input-discussion.vue";

export default {
  components: { InputDiscussion },
  props: {
    title: String,
    act_title: String,
    topic_title: String,
    id_topic: String,
		id_act: String,
    id_program: String
  },
  data: () => ({
    isLoading: false,
    snackbar: {
      state: false,
      text: "",
      color: "",
      timeout: 4000
    },
    discussion: [],
  }),
  mounted() {
    this.fetchDiscussion()
  },
  methods: {
    fetchDiscussion() {
      this.isLoading = true
      this.axios.get(`${LMS_API.DISCUSSION.LIST}`, {
        params: {
          sort: 'created_at', 
          sort_type: 'desc',
          limit: -1,
          program_id: this.id_program,
          topic_id: this.id_topic,
          activity_id: this.id_act,
        }
      })
      .then((response) => {
        let res = response.data
        let resData= res.data.list
        let dataDiscussion = this.list_to_tree(resData)

        this.discussion = dataDiscussion
        this.isLoading = false
      })
    },

    list_to_tree(list) {
      var map = {}, node, roots = [], i;

      // console.log(list);
      
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
      }
      
      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parent_id !== "") {
          // if you have dangling branches check that map[node.parentId] exists
          list[map[node.parent_id]].children.push(node);
        } else {
          roots.push(node);
        }
      }
      return roots;
    },
    addDiscussion(event) {
      let data = {
        parent_id: "",
        activity_id: this.id_act,
        topic_id: this.id_topic,
        program_id: this.id_program,
        content: event
      }

      this.createReplay(data)
    },
    onHandleContentEmpty(text) {
      let valid = true
       // check if message is empty and must be filled not only space
       if (text.trim() == "") {
        text = ""
        this.snackbar = {
          state: true,
          text: "Pesan tidak boleh kosong",
          color: "error",
          timeout: 4000
        }
        valid = false
      }
      return valid
    },
    replyDiscussion(event) {
      if (!this.onHandleContentEmpty(event.content)) return
      // console.log('replyDiscussion',event);
      let dataReplay = {
        parent_id: event.data.id.toString(),
        activity_id: event.data.activity_id,
        topic_id: event.data.topic_id,
        program_id: event.data.program_id,
        content: event.content
      }
      this.createReplay(dataReplay)
    },
    async createReplay(data) {
      this.isLoading = true
      await this.axios.post(`${LMS_API.DISCUSSION.PUBLIC_CREATE}`, data)
      .then(() => {
        this.fetchDiscussion()
        this.isLoading = false
      }).catch((error) => {
        
        if(error.response.data.message == 'error validation') {
          let errors = "";
          Object.keys(error.response.data.validation).forEach((resp) => {
            errors += error.response.data.validation[resp] + "<br>";
          })
          this.snackbar = {
            state: true,
            text: errors,
            color: "red",
            timeout: 4000
          }
        }else {
          this.snackbar = {
            state: true,
            text: error.response.data.message,
            color: "red",
            timeout: 4000
          }
        }

      })
    }
  },
};
</script>
