<template>
  <v-row align-content="center">
    <div v-if="isLoading" class="d-flex flex-column justify-center align-center mx-auto" style="height: 100vh; width: 100vw;">
      <v-progress-circular
        :size="100"
        color="primary"
        indeterminate>
      </v-progress-circular>
      <div class="mt-10">Menyiapkan aplikasi untuk Anda, silahkan perbolehkan notifikasi ....</div>
    </div>
    <v-row v-else no-gutters align-content="center">
      <v-col cols="12" md="6" class="white pa-0">

        <v-row justify="center" align="center" :style="$vuetify.breakpoint.name == 'xs' ? '' : 'height: 100vh;'">
          <v-col cols="12" md="8" :class="$vuetify.breakpoint.name == 'xs' ? 'px-8 mt-6' : ''">
            <v-row class="mb-2" v-if="$vuetify.breakpoint.name == 'xs'">
              <v-col>
                <v-btn
                  class="rounded-lg fs-12"
                  :color="showAuth == 'user' ? 'primary' : '#1F2A37'"
                  :style="showAuth == 'company' && 'border: 1px solid #E5E7EB'"
                  x-small
                  block
                  outlined
                  @click="showAuth = 'user'">
                  Dashboard User
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  class="rounded-lg fs-12"
                  :color="showAuth == 'company' ? 'primary' : '#1F2A37'"
                  :style="showAuth == 'user' && 'border: 1px solid #E5E7EB'"
                  x-small
                  block
                  outlined
                  @click="showAuth = 'company'">
                  Dashboard Perusahaan
                </v-btn>
              </v-col>
            </v-row>
            <div class="fs-24 font-weight-bold">Login Dashboard</div>
            <div class="fs-14 text-gray-500">Login menggunakan Email dan Kata Sandi Anda</div>
            <v-row class="mt-4" v-if="$vuetify.breakpoint.name !== 'xs'">
              <v-col>
                <v-btn
                  class="rounded-lg fs-12"
                  :color="showAuth == 'user' ? 'primary' : '#1F2A37'"
                  :style="showAuth == 'company' && 'border: 1px solid #E5E7EB'"
                  x-small
                  block
                  outlined
                  @click="showAuth = 'user'">
                  Dashboard User
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  class="rounded-lg fs-12"
                  :color="showAuth == 'company' ? 'primary' : '#1F2A37'"
                  :style="showAuth == 'user' && 'border: 1px solid #E5E7EB'"
                  x-small
                  block
                  outlined
                  @click="showAuth = 'company'">
                  Dashboard Perusahaan
                </v-btn>
              </v-col>
            </v-row>

            <AuthLoginUser :type-form="showAuth" />

          </v-col>
        </v-row>

      </v-col>
      <v-col cols="12" md="6" class="pa-0" :style="$vuetify.breakpoint.name == 'xs' ? `order: -1 !important` : `order: 1 !important`">
        <v-img
          style="object-fit: cover; object-position: center;"
          :style="$vuetify.breakpoint.name == 'xs' ? 'width: 100%; height: 200px' : 'height: 100%;'"
          :src="require(`@/assets/images/${$vuetify.breakpoint.name == 'xs' ? 'bg-login-mobile.png' : 'bg-login.png'}`)">
        </v-img>
        <!-- :order="$vuetify.breakpoint.name == 'xs' ? 'first': 'last'" -->
        <!-- <v-img
          style="object-fit: cover; object-position: center;"
          :style="$vuetify.breakpoint.name == 'xs' ? 'width: 100%;' : 'height: 100%;'"
          :src="require(`@/assets/images/${$vuetify.breakpoint.name == 'xs' ? 'bg-login-mobile.png' : 'bg-login.png'}`)">
        </v-img> -->
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import AuthLoginUser from "./_components/AuthLoginUser.vue";

export default {
  name: "login",
  components: {
    AuthLoginUser
  },
  data() {
    return {
      showAuth: "user",
      isLoading: false,
    };
  },
  created() {},
  computed: {},
  methods: {},
};
</script>

<style>

.bg-login {
  background-image: url("../../assets/images/bg-login.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.google-signin-button {
  color: white;
  background-color: red;
  height: 50px;
  font-size: 16px;
  border-radius: 10px;
  padding: 10px 20px 25px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
#line {
  width: 100%;
  height: max-content;
  text-align: center;
  position: relative;
}

#line:after {
  content: "";
  width: 100%;
  border-bottom: solid 1px #e0e6ed;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 1;
}

#line span {
  width: auto;
  background: #fff;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}
</style>
