<template>
  <v-form
    class="mt-6"
    @submit.prevent="submit"
    lazy-validation
    ref="form"
    v-model="valid">

    <div>
      <div class="my-2 font-weight-medium">Nama Lengkap</div>
      <v-text-field
        v-model="form.name"
        placeholder="Input Nama Lengkap"
        autocomplete="off"
        :rules="nameRules"
        class="rounded-lg"
        dense
        required
        outlined
        hide-details="auto">
      </v-text-field>
    </div>

    <v-row>
      <v-col>
        <div>
          <div class="my-2 font-weight-medium">Email</div>
          <v-text-field
            v-model="form.email"
            placeholder="Input Email"
            autocomplete="off"
            :rules="emailRules"
            class="rounded-lg"
            dense
            required
            outlined
            hide-details="auto">
          </v-text-field>
        </div>
      </v-col>
      <v-col>
        <div>
          <div class="my-2 font-weight-medium">No Telephone</div>
          <v-text-field
            v-model="form.phone"
            placeholder="Input No Telephone"
            autocomplete="off"
            :rules="phoneRules"
            class="rounded-lg"
            dense
            required
            outlined
            hide-details="auto">
          </v-text-field>
        </div>
      </v-col>
    </v-row>

    <div>
      <div class="my-2 font-weight-medium">Password</div>
      <v-text-field
        v-model="form.password"
        placeholder="Input Password"
        :rules="passwordRules"
        class="rounded-lg"
        autocomplete="new-password"
        dense
        required
        outlined
        :append-icon="showPassword ? 'ri-eye-off-line' : 'ri-eye-line'"
        @click:append="showPassword = !showPassword"
        :type="showPassword ? 'text' : 'password'"
        hide-details="auto">
      </v-text-field>
    </div>

    <div>
      <div class="my-2 font-weight-medium">Konfirmasi Password</div>
      <v-text-field
        v-model="form.password_confirm"
        placeholder="Input Konfirmasi Password"
        :rules="passwordConfirmRules"
        class="rounded-lg"
        autocomplete="new-password"
        dense
        required
        outlined
        :append-icon="showPasswordConfirm ? 'ri-eye-off-line' : 'ri-eye-line'"
        @click:append="showPasswordConfirm = !showPasswordConfirm"
        :type="showPasswordConfirm ? 'text' : 'password'"
        hide-details="auto">
      </v-text-field>
    </div>

    <v-btn
      class="mt-4 rounded-lg"
      type="submit"
      color="primary"
      block
      small
      depressed
      :loading="process.run"
      :disabled="process.run">
      Daftar
    </v-btn>

    <div class="mt-4 text-center fs-12" >
      <div class="my-2 font-weight-medium text-gray-500">Sudah memiliki akun Perusahaan ? <router-link to="/auth/login" class="primary--text">Login disini</router-link></div>
    </div>

  </v-form>
</template>

<script>
// import { LMS_API } from "@/constants/api";

export default {
  name: "auth-register-company",
  data() {
    return {
      valid: true,
      form: {
        name: "",
        email: "",
        phone: "",
        password: "",
        password_confirm: ""
      },
      showPassword: false,
      showPasswordConfirm: false,
      process: {
        run: false
      },
      nameRules: [
        (v) => !!v || "Nama Lengkap tidak boleh kosong",
        (v) => (v && v.length >= 10) || "Nama Lengkap harus minimal 10 karakter",
      ],
      emailRules: [
        (v) => !!v || "Email tidak boleh kosong",
        (v) => /.+@.+\..+/.test(v) || "Email tidak valid",
      ],
      passwordRules: [
        (v) => !!v || "Password tidak boleh kosong",
        (v) => (v && v.length >= 8) || "Password harus minimal 8 karakter",
      ],
      passwordConfirmRules: [
        (v) => !!v || "Konfirmasi password tidak boleh kosong",
        (v) => (v && v.length >= 8) || "Password harus minimal 8 karakter",
        (v) => v === this.form.password || "Konfirmasi password tidak sama",
      ],
      phoneRules: [
        (v) => !!v || "No telephone tidak boleh kosong",
        (v) => (v && v.length >= 8) || "No telephone harus minimal 8 karakter",
      ]
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.process.run = true;

        // await this.$axios.post(`${LMS_API.AUTH.REGISTER}`, {
        //   access_key: process.env.VUE_APP_ACCESS_KEY,
        //   provider: "email",
        //   first_name: this.form.name,
        //   phone: this.form.phone,
        //   email : this.form.email,
        //   password: this.form.password,
        //   confirm_password: this.form.password_confirm
        // })
        // .then((response) => {
        //   this.process.run = false;
        //   this.$emit("submit", this.form);
        // })
        // .catch((error) => {
        //   this.process.run = false;
        // });
      }
    }
  }
};
</script>