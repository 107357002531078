import { LMS_API } from "@/constants/api"

import axios from "axios";

const state = () => ({
  trainers: [],
});

const mutations = {
  SET_TRAINER(state, trainer) {
    state.trainers = trainer;
  }
};

const actions = {
  async getTrainer({ commit }, payload) {
    const response = await axios.get(`${LMS_API.PROGRAM.DETAIL(payload)}`)
    let res = response.data

    commit("SET_TRAINER", res.data.program.trainers);
  }
}

export const trainerModule = {
  namespaced: true,
  state,
  actions,
  mutations,
};