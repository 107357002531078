<template>
  <div>

    <Snackbar ref="snackbar" />

    <v-card outlined flat class="mt-4 rounded-lg">
      <v-card-title>
        Social Media 
        <v-spacer></v-spacer>
        <v-btn 
          v-if="!showSocialMedia" 
          color="#1F2A37"
          style="border: 1px solid #E5E7EB"
          class="rounded-lg"
          x-small
          outlined
          @click="onOpenForm()">
          Tambah
        </v-btn>
        <div v-else>
          <v-btn
            color="primary"
            x-small
            depressed
            class="rounded-lg"
            @click="onSubmitTalentSocialMedias">
            Simpan
          </v-btn>
          <v-btn 
            color="#1F2A37"
            style="border: 1px solid #E5E7EB"
            class="ml-2 rounded-lg"
            x-small
            outlined
            @click="onOpenForm()">
            Tutup
          </v-btn>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-show="!showSocialMedia">
  
        <div class="text-left" v-if="profileData.length > 0">
  
          <v-row v-for="(socialMedia, index) in profileData" :key="index" no-gutters class="my-4" align="center">
            <v-col cols="10">
              <v-btn depressed small text :href="socialMedia.talent_social_media_url" target="_blank" class="justify-start d-flex align-center" :color="setMediaColor(socialMedia.talent_social_media_name)">
                <v-icon size="20" :color="setMediaColor(socialMedia.talent_social_media_name)" left >ri-{{ socialMedia.talent_social_media_name.toLowerCase() }}-fill</v-icon>
                <div>
                  <div class="font-weight-bold body-2" :style="`color: ${setMediaColor(socialMedia.talent_social_media_name)}`">{{ socialMedia.talent_social_media_name }}</div>
                  <div class="font-weight-medium text-lowercase grey--text text--darken-1 caption text-truncate" :style="$vuetify.breakpoint.name == 'xs' ? 'max-width: 190px;' : 'max-width: 300px;'">{{ socialMedia.talent_social_media_url }}</div>
                </div> 
              </v-btn>
            </v-col>
            <!-- <v-col cols="7" class="text-left">
              <div class="font-weight-medium black--text">{{ socialMedia.talent_social_media_url }}</div>
            </v-col> -->
            <v-col cols="2" class="justify-end text-right d-flex align-end">
              <v-btn
                color="#1F2A37"
                class="rounded-lg"
                x-small
                icon
                @click="onOpenEditSocialMedias(index)">
                <v-icon size="20">ri-pencil-line</v-icon>
              </v-btn>
              <v-btn
                color="#1F2A37"
                class="rounded-lg"
                x-small
                icon
                @click="onDeleteSocialMedias(index)">
                <v-icon size="20">ri-delete-bin-7-line</v-icon>
              </v-btn>
            </v-col>
          </v-row>
  
        </div>
  
        <div class="text-center" v-else >
          Beritahu perusahaan tentang diri Anda melalui social media yang Anda miliki
        </div>
  
      </v-card-text>
      <v-expand-transition>
        <v-card-text v-show="showSocialMedia">
  
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-row v-for="(socialMedia, index) in form.talent_social_medias" :key="index" class="py-0">
              <v-col class="py-0">
                <div>
                  <div class="mb-1 font-weight-medium">Sosial Media</div>
                  <v-select
                    outlined
                    class="rounded-lg"
                    dense
                    :rules="[rules.required]"
                    :items="['Facebook', 'Instagram', 'LinkedIn', 'Twitter']"
                    v-model="socialMedia.talent_social_media_name"
                    placeholder="Pilih Sosial Media">
                  </v-select>
                </div>
              </v-col>
              <v-col class="py-0">
                <div>
                  <div class="mb-1 font-weight-medium">Link Social Media</div>
                  <v-text-field
                    outlined
                    class="rounded-lg"
                    dense
                    :rules="[rules.required]"
                    v-model="socialMedia.talent_social_media_url"
                    placeholder="Tuliskan Link Akun Social Media">
                  </v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-form>
  
          <v-btn
            x-small
            color="primary"
            text
            @click="onAddSocialMedias">
            <v-icon left>mdi-plus</v-icon>
            Tambah Sosial Media
          </v-btn>
  
        </v-card-text>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>

import Snackbar from "@/components/Snackbar";

export default {
  props: {
    profileData: {
      type: Array,
      default: () => ([])
    }
  },
  components: {
    Snackbar
  },
  data: () => ({
    valid: true,
    showSocialMedia: false,

    state_index: 0,
    state_id: "",
    state_form: "create",

    rules: {
      required: value => !!value || 'field tidak boleh kosong',
    },

    form: {
      talent_social_medias: []
    }

  }),
  computed: {
    socialMediaColors() {
      return {
        Facebook: "#1877F2",
        Instagram: "#E1306C",
        LinkedIn: "#0077B5",
        Twitter: "#1DA1F2"
      }
    },
  },
  methods: {

    onOpenForm() {
      this.showSocialMedia = !this.showSocialMedia

      this.form.talent_social_medias = this.profileData
    },

    setMediaColor(media) {
      return this.socialMediaColors[media]
    },

    onValidation() {
      let isValid = true

      this.form.talent_social_medias.forEach((item) => {
        if(!item.talent_social_media_url || !item.talent_social_media_name) {
          isValid = false
        }
      })

      this.$refs.snackbar.open("error", "Terdapat field yang masih kosong")

      return isValid
    },

    onSubmitTalentSocialMedias() {

      if(!this.$refs.form.validate()) return

      this.$emit('save', this.form.talent_social_medias)

      this.showSocialMedia = !this.showSocialMedia
    },
    
    //  ADD SOCIAL MEDIAS
    onAddSocialMedias() {
      this.form.talent_social_medias.push({
        talent_social_media_name: "",
        talent_social_media_url: "",
      })
    },

    // ON OPEN EDIT SOCIAL MEDIAS
    onOpenEditSocialMedias(index) {
      let talentSocialMedias = [...this.profileData]

      this.form.talent_social_medias = talentSocialMedias

      // this.talent_social_medias = {
      //   talent_social_media_name: talentSocialMedias.talent_social_media_name,
      //   talent_social_media_url: talentSocialMedias.talent_social_media_url,
      // }

      this.state_index = index

      this.showSocialMedia = !this.showSocialMedia

      this.state_form = "edit"
    },

    // DELETE SOCIAL MEDIAS
    onDeleteSocialMedias(index) {
      let talentSocialMedias = [...this.profileData]

      talentSocialMedias.splice(index, 1)

      this.$emit("save", talentSocialMedias)

      // this.$refs.snackbar.open('success', 'Social Media berhasil dihapus!')

      // this.onSaveTalentProfileResume()
      
      this.showSocialMedia = false
    },
  }
}
</script>