<template>
  <div class="px-2 py-2">
    <section class="py-5">
      <div class="col-md-8 mx-auto">
        <v-breadcrumbs large :items="breadcrumbItems" class="ps-0"></v-breadcrumbs>
        <v-card elevation="0" class="rounded-lg">
          <template v-if="false">
            <v-tabs v-model="subscriptionTab" background-color="transparent">
              <template v-if="loadingTab">
                <div class="pl-8 pt-2">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    :size="30"
                  ></v-progress-circular>
                </div>
              </template>
              <template v-else>
                <v-tab class="text-capitalize" @click="changeTabSubs()">Aktif</v-tab>
                <v-tab class="text-capitalize" @click="changeTabSubs()">Tidak Aktif</v-tab>
              </template>
            </v-tabs>
            <v-divider class="mb-4"></v-divider>
          </template>
          <template v-if="loading">
            <div class="py-5">
              <div class="pb-3 text-center text-body-1 cl-slate">
                Sedang memuat data
              </div>
              <v-progress-linear
                indeterminate
              ></v-progress-linear>
            </div>
          </template>
          <template v-if="!loading">
            <div class="px-lg-4 pt-2 pb-4">
              <template v-if="subscription.list.length > 0">
                <v-col cols="12" class="pb-2">
                  <div class="text-body-1 cl-slate">
                    Berikut adalah list paket subskripsi Anda
                  </div>
                </v-col>
                <v-col cols="12" lg="6" class="pt-0">
                  <template v-for="(list, i) in subscription.list">
                    <v-card outlined class="mt-3 pa-3 rounded-lg" :class="i == 0 ? 'active-package' : ''" :key="i">
                      <div class="text-subtitle-1 font-weight-bold">Paket Akses Semua Kelas - {{ list.package.title }}</div>
                      <div class="pt-3 d-lg-flex justify-space-between">
                        <div class="text-body-1 cl-slate font-weight-medium">Aktif Sampai</div>
                        <div class="text-body-1 primary--text font-weight-medium">{{ formatDate(list.expired_at) }}</div>
                      </div>
                    </v-card>
                  </template>
                </v-col>
              </template>
              <template v-else>
                <v-col cols="12 text-center">
                  <v-img
                    class="col-3 mx-auto my-4"
                    src="@/assets/images/data_empty.png"
                  ></v-img>
                  <div class="text-body-1 cl-slate">
                    Anda belum memiliki paket berlangganan
                  </div>
                </v-col>
              </template>
            </div>
          </template>
        </v-card>
      </div>
    </section>
  </div>
</template>

<script>
import { LMS_API } from "@/constants/api"

import moment from "moment";

export default {
  name: "subscription",
  data() {
    return {
      breadcrumbItems: [
        {
          text: "Beranda",
          disabled: false,
          color: "primary",
          href: "/dashboard",
        },
        {
          text: "Subskripsi",
          disabled: true,
          href: "#",
        },
      ],
      subscriptionTab: null,
      loadingTab: false,
      loading: false,
      params: {
        dir: "desc",
        sort: "created_at",
        limit: 1000,
        page: 1,
      },
      subscription: {
        list: [],
      }
    }
  },
  methods: {
    formatDate(val) {
      return val ? moment(val).locale('en-gb').format("DD MMMM YYYY") : null;
    },
    changeTabSubs() {
      this.loadingTab = true
      setTimeout(() => {
        this.loadingTab = false
      }, 500)
    },
    getData() {
      this.loading = true
      this.axios.get(`${LMS_API.SUBSCRIBE.HISTORY}?dir=${this.params.dir}&sort=${this.params.sort}&limit=${this.params.limit}&page=${this.params.page}`)
      .then((response) => {
        if (response.status == 200) {
          let res = response.data.data
          this.subscription.list = res.list
          this.loading = false
        } else {
          this.subscription.list = {}
          this.loading = false
        }
      });
    },
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped>
.active-package {
  background-color: #ffede7;
}
</style>