<template>
  <v-row>
    <v-col class="pt-0 pt-sm-6">
      <v-container fluid class="px-4">
        <!-- :style="{
          maxWidth: !$vuetify.breakpoint.smAndUp ? 'unset !important' : '',
        }" -->
        <Snackbar ref="snackbar" />

        <v-row>
          <v-col class="pa-4 pa-sm-3" cols="12">
            <v-card 
              style="
                border-radius: 8px;
              "
              class="rounded-lg card-box-shadow white--text">
              <v-img
                height="200"
                class="rounded-lg"
                :src="require('@/assets/images/bg-redeem-class.svg')">
                <v-card-text>
                  <v-row align="center" justify="space-between">
                    <v-col cols="12" md="7" xl="8">
                      <div class="fs-18 cl-white font-weight-bold">
                        Masukkan Kode reedem yang telah dikirim ke Email Anda
                      </div>
                      <div class="pt-1 cl-white font-weight-regular fs-12">
                        Akses kelas Anda telah dikirimkan ke-email setiap peserta saat mendaftar
                      </div>
                      <v-form
                        @submit.prevent="submit"
                        lazy-validation
                        ref="form"
                        v-model="valid"
                      >
                        <v-row no-gutters>
                          <v-col cols="12" md="6" lg="6" class="d-flex">
                            <v-text-field
                              class="mr-3 mt-10 rounded-lg"
                              style="background: white;"
                              outlined
                              dense
                              hide-details
                              placeholder="Silahkan masukkan kode"
                              v-model="voucher"
                              required
                            >
                            </v-text-field>
                            <v-btn
                              color="white"
                              elevation="0"
                              class="rounded-lg mt-10"
                              small
                              @click="dialog = true">
                              <!-- :disabled="!voucher" -->
                              Redeem
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-img>
              <v-img
                v-if="$vuetify.breakpoint.smAndUp"
                style="position: absolute; right: 0; z-index: 1; top: -55px;"
                :src="require('@/assets/images/illustrations/illustration_redeem.svg')">
              </v-img>
            </v-card>
          </v-col>
        </v-row>

        <v-alert
          dense 
          class="mt-3"
          type="error" text v-show="response !== null">
          {{ response }}
        </v-alert>

        <!-- SUMMARY CARD -->
        <v-row>
          <v-col 
            v-for="(item, index) in summaryCard" :key="index"
            cols="12" md="4" lg="4">
            <v-card style="border: 1px solid #E5E7EB" flat class="rounded-lg">
              <v-card-text class="d-flex justify-start align-center" :class="$vuetify.breakpoint.name == 'xs' ? 'flex-column text-center' : ''">
                <div class="mr-2">
                  <img 
                    width="48"
                    :src="item.icon" 
                    alt="icon" class="rounded-lg">
                </div>
                <div>
                  <div class="font-weight-medium text-uppercase fs-12" :class="$vuetify.breakpoint.name == 'xs' ? ' my-2' : ''">{{ item.title }}</div>
                  <div class="fs-20 font-weight-bold text-gray-900">{{ item.value }}</div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- FORM FILTER AND SEARCHING -->
        <v-row>
          <v-col cols="12" class="pt-6">
            <div class="text-h5">Kelas Saya</div>
          </v-col>
          <v-col cols="12" class="pt-2">
            <v-row class="d-flex justify-space-between">
              <v-col cols="12" md="6" sm="12" class="d-flex" style="gap: 20px">
                <v-select
                  outlined
                  dense
                  v-model="status_program"
                  :items="progress_program"
                  item-label="text"
                  item-value="value"
                  class="rounded-lg"
                  label="Status">
                </v-select>
                <v-select
                  outlined
                  dense
                  v-model="sort_program"
                  :items="sorting_program"
                  class="rounded-lg"
                  label="Urutkan">
                </v-select>
              </v-col>
              <v-col cols="12" md="3" sm="12" class="d-flex" style="gap: 8px">
                <v-text-field
                  outlined
                  dense
                  prepend-inner-icon="mdi-magnify"
                  v-model="searching_program"
                  clearable
                  persistent-hint
                  hint="Tekan enter / button disamping untuk mencari"
                  @keydown.enter="$refs.listClassRef.searchProgram(searching_program)"
                  @click:clear="$refs.listClassRef.searchProgram('')"
                  class="rounded-lg"
                  label="Cari Kelas"
                  placeholder="Cari Kelas">
                </v-text-field>
                <v-btn 
                  fab tile small 
                  depressed
                  class="rounded-lg"
                  @click="$refs.listClassRef.searchProgram(searching_program)"
                  color="primary">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>  
        </v-row>
      </v-container>


      <v-row
        v-show="loadContent"
        class="fill-height"
        align-content="center"
        justify="center"
        style="height: 60vh"
      >
        <v-col class="text-subtitle-1 text-center color-navy-soft" cols="12">
          Tunggu beberapa saat ...
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-row v-show="!loadContent" class="mb-3">
        <v-container fluid class="px-4">
          <ListClass
            ref="listClassRef"
            :isSubscribe="isSubscribe"
            @loadContentTrue="loadContentTrue"
            @loadContentFalse="loadContentFalse"
          >
          </ListClass>
        </v-container>
      </v-row>
    </v-col>
    <v-dialog v-model="dialog" width="700" persistent>
      <v-card>
        <v-card-title class="text-h6"> Perhatian </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-left pt-2">
          <p class="text-h6 font-weight-medium">
            Anda yakin mau reedem voucher {{ useprakerja ? ' dan menggunakan kode redeem kartu prakerja ' : '' }} dengan akun
            <b>{{ user_fullname }}</b> dengan no HP. {{ user_sistem.talent_phone }} ?
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false" class="mb-3">Batal</v-btn>
          <v-btn
            color="primary"
            elevation="0"
            :loading="submitting"
            @click="submit"
            class="mb-3">
            Ya, yakin!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { LMS_API } from "@/constants/api"

import { mapGetters } from "vuex";
import ListClass from "./ListClass.vue";

import Snackbar from "@/components/Snackbar.vue";


export default {
  name: "my-class",
  components: {
    ListClass,
    Snackbar
  },
  data() {
    return {
      loadContent: false,
      useprakerja: false,
      meta: {
        pmo_voucher: "",
      },
      voucher: "",
      referral: "",
      valid: true,
      submitting: false,
      response: null,
      dialog: false,
      isSubscribe: 0,
      summaryTotal: {
        pelatihan_aktif: 0,
        pelatihan_lulus: 0,
        sertifikat: 0,
        lamaran: 0,
      },
      summaryCard: [],
      
      searching_program: "",
      status_program: null,
      sort_program: "desc",

      sorting_program: [
        {
          text: "Terbaru",
          value: "desc"
        },
        {
          text: "Terlama",
          value: "asc"
        },
      ],

      progress_program: [
        {
          text: "Semua",
          value: null
        },
        {
          text: "Belum Selesai",
          value: 0
        },
        {
          text: "Sudah Selesai",
          value: 1
        },
      ],
    };
  },
  watch: {
    status_program(val) {
      this.$refs.listClassRef.filterByStatus(val)
    },
    sort_program(val) {
      this.$refs.listClassRef.sortingProgram(val)
    },
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["user_sistem"]),
    user_fullname() {
      return this.user_sistem.talent_first_name + ' ' + this.user_sistem.talent_last_name
    },
  },
  mounted() {
    this.getData(0);
    this.getSummaryCard()
    this.$store.commit('SET_PROGRAM_NAME', "")
    localStorage.setItem('sistem_program_name', "")
  },
  methods: {
    getSummarys() {
      let _self = this
      return {
          async getProgramActive() {
            await _self.axios
              .get(`${LMS_API.PROGRAM.SUB_TOTAL.PROGRAM_ACTIVE}`)
              .then((response) => {
                let res = response.data.data;
                if (response.status == 200) {
                  _self.summaryTotal.pelatihan_aktif = res.total
                }
              });
          },
          async getProgramCompleted() {
            await _self.axios
              .get(`${LMS_API.PROGRAM.SUB_TOTAL.PROGRAM_COMPLETED}`)
              .then((response) => {
                let res = response.data.data;
                if (response.status == 200) {
                  _self.summaryTotal.pelatihan_lulus = res.total
                }
              });
          },
          async getProgramCertificate() {
            await _self.axios
              .get(`${LMS_API.PROGRAM.SUB_TOTAL.PROGRAM_CERTIFICATE}`)
              .then((response) => {
                let res = response.data.data;
                if (response.status == 200) {
                  _self.summaryTotal.sertifikat = res.total
                }
              });
          },
        }
    },
    async getSummaryCard() {

      await this.getSummarys().getProgramActive()
      await this.getSummarys().getProgramCompleted()
      await this.getSummarys().getProgramCertificate()

      this.summaryCard = [
        {
          title: "Pelatihan Aktif",
          icon: require("@/assets/images/icon/icon-pelatihan-aktif.svg"),
          color: "#1C64F2",
          color_medium: "#C3DDFD",
          color_soft: "#EBF5FF",
          value: this.summaryTotal.pelatihan_aktif,
        },
        {
          title: "Pelatihan Lulus",
          icon: require("@/assets/images/icon/icon-pelatihan-lulus.svg"),
          color: "#E3A008",
          color_medium: "#FDF6B2",
          color_soft: "#FDFDEA",
          value: this.summaryTotal.pelatihan_lulus,
        },
        {
          title: "Sertifikat",
          icon: require("@/assets/images/icon/icon-sertifikat.svg"),
          color: "#0E9F6E",
          color_medium: "#DEF7EC",
          color_soft: "#F3FAF7",
          value: this.summaryTotal.sertifikat,
        }
      ]
    },
    goToTutorRedeem() {
      this.$router.push({ name: "prakerja", hash: "#redeem" });
    },
    loadContentTrue() {
      this.loadContent = true;
    },
    loadContentFalse() {
      this.loadContent = false;
    },
    getData(val) {
      this.isSubscribe = 0;
      this.$refs.listClassRef.getClass(true, val);
    },
    submit() {
      this.submitting = true;
      let meta = {
        pmo_voucher: this.meta.pmo_voucher,
      };
      let code = this.voucher;
      let referral = this.referral;
      if (code != "") {
        if(this.meta.pmo_voucher && this.useprakerja) {
          setTimeout(() => {
            this.axios
              .post(
                `${LMS_API.PROGRAM.REDEEM_PRAKERJA}`,
                { meta, code },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((response) => {
                let res = response.data
                this.submitting = false;
                this.useprakerja = false
                this.getData(0);

                setTimeout(() => {
                  window.open(
                    res.data.url,
                    '_self'
                  )
                }, 10);
                this.$refs.form.reset();
                this.dialog = false;
              })
              .catch((err) => {
                this.getData(0);
                this.dialog = false;
                this.submitting = false;
                this.useprakerja = false
                this.$refs.form.reset();
                // this.response = err.response.data.message;

                this.$refs.snackbar.open("error", err.response.data.message, this.$vuetify.breakpoint.name == 'xs' ? false : true);

                setTimeout(() => {
                  this.response = null;
                }, 5000);
              });
          }, 1000);
        }else {
          setTimeout(() => {
            this.axios
              .post(
                `${LMS_API.PROGRAM.REDEEM_CODE}`,
                { meta, code, referral },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then(() => {
                // let res = response.data
                // console.log('else',res);
                this.submitting = false;
                this.getData(0);
                this.$refs.form.reset();
                this.dialog = false;
              })
              .catch((err) => {
                this.dialog = false;
                this.submitting = false;
                this.$refs.form.reset();
                // this.response = err.response.data.message;

                this.$refs.snackbar.open("error", err.response.data.message, this.$vuetify.breakpoint.name == 'xs' ? false : true);
                setTimeout(() => {
                  this.response = null;
                }, 5000);
              });
          }, 1000);
        }
      } else {
        this.submitting = false;
        this.$refs.form.reset();
        this.response = true;
        this.response = "Kode Lisensi tidak boleh kosong";
        setTimeout(() => {
          this.response = null;
        }, 5000);
      }
    },
  },
};
</script>

<style type="text/css">
.text-dots-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>