<template>
  <transition name="fade">
    <v-app v-if="loaded">
      <!-- Sidenav / Navigation drawer -->
      <div>
        <v-dialog
          v-model="attend"
          width="550"
          persistent
        >

          <v-card class="text-center rounded-lg">
            <v-card-title class="px-4 text-center d-block">
              <div class="text-h5">
                Selamat Datang
              </div>
              <p class="fs-14 color-strike font-weight-medium">
                Terima kasih sudah mengikuti SISTEM.
              </p>
            </v-card-title>

            <v-card-text class="px-4 pb-0">
              <v-alert
                dense
                color="#E1EFFE"
                :class="$vuetify.breakpoint.name !== 'xs' ? 'fs-14' : 'fs-12'"
                class="px-3 border-0 color-strike primary--text">
                <p>Untuk akses semua materi dan assessment lebih mudah, kami sarankan menggunakan perangkat laptop atau komputer.</p>
                <div class="font-weight-medium">Apabila ada kendala bisa menghubungi SISTEM.</div>
              </v-alert>
            </v-card-text>
            <v-card-actions class="pb-3 d-block">
            <v-btn
                color="primary"
                @click="attend = false"
                small
                depressed
                block
                class="px-4 text-center rounded-lg"
              >
                Oke
              </v-btn> 
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Topnav / App bar -->
        <v-main class="white">
          <v-app-bar class="white" flat fixed>
            <v-toolbar-title>
              <div class="justify-start my-1 text-left d-flex align-center">
                <div class="justify-center d-flex align-center" 
                  style="cursor: pointer;" 
                  @click="hiddenArrowNavbar ? goBack() : Dashboard()">
                  <v-icon
                    v-if="hiddenArrowNavbar" 
                    class="mt-2">ri-arrow-left-s-line</v-icon>
                  <img
                    src="@/assets/images/logo-dash.png"
                    alt="Avatar"
                    class="cursor-pointer img-yec-test"
                  />
                </div>
                <div class="mt-1 ml-8 fs-16 font-weight-medium">{{ programName }}</div>
              </div>
            </v-toolbar-title>
            <v-list dense flat class="mt-1 d-none d-md-block" v-if="showMenuBarWhen">
              <v-list-item-group color="primary" style="display: flex">
                <div v-for="(item, i) in items" :key="i">
                    <!-- :disabled="
                      !user.is_verified && 
                      item.title != 'Account'
                    " -->
                  <v-list-item
                    class="pl-6"
                    :disabled="isDisabledMenu"
                    active-class="menu-active"
                    :to="item.link"
                    @click="pageChanged(0, item)"
                  >

                    <v-list-item-content>
                      <v-list-item-title
                        class="fs-14 color--text"
                        :class="
                          // user.is_verified ||
                          // item.title == 'Account' ||
                          'text--lighten-3'
                        "
                        >{{ item.title }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-list-item-group>
            </v-list>

            <v-spacer></v-spacer>
            <!-- NOTIFICATION -->
            <v-menu
              right
              :close-on-content-click="false"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  :content="total"
                  :value="total"
                  color="primary"
                  overlap
                  class="mx-3"
                >
                  <svg 
                    v-bind="attrs"
                    v-on="on"
                    @click="getAll()"
                    width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.079 14.8291C18.4881 14.2478 18.1488 13.4579 18.134 12.6291V10.8291C18.133 9.35225 17.6212 7.92122 16.6855 6.77863C15.7497 5.63605 14.4477 4.85224 13 4.5601V3.1001C13 2.83488 12.8946 2.58053 12.7071 2.39299C12.5196 2.20545 12.2652 2.1001 12 2.1001C11.7348 2.1001 11.4804 2.20545 11.2929 2.39299C11.1054 2.58053 11 2.83488 11 3.1001V4.5601C9.55183 4.85234 8.24937 5.63659 7.31359 6.77979C6.37781 7.923 5.86635 9.35474 5.866 10.8321V12.4181C5.86607 12.4887 5.87378 12.5591 5.889 12.6281H5.866C5.85125 13.4569 5.51194 14.2468 4.921 14.8281C4.38423 15.3543 4.05692 16.0576 4 16.8071C4 17.3501 4 19.0001 5.538 19.0001H7.746C8.03584 19.9004 8.60385 20.6856 9.36829 21.2426C10.1327 21.7995 11.0542 22.0996 12 22.0996C12.9458 22.0996 13.8673 21.7995 14.6317 21.2426C15.3961 20.6856 15.9642 19.9004 16.254 19.0001H18.462C20 19.0001 20 17.3501 20 16.8071C19.9428 16.0579 19.6156 15.355 19.079 14.8291ZM12 20.1001C11.5932 20.0996 11.1928 19.999 10.834 19.8072C10.4753 19.6153 10.1693 19.3382 9.943 19.0001H14.057C13.8307 19.3382 13.5247 19.6153 13.166 19.8072C12.8072 19.999 12.4068 20.0996 12 20.1001ZM18 17.0001H6C6 16.9331 6 16.8641 6 16.8071C6.1063 16.5401 6.26627 16.2978 6.47 16.0951C7.35569 15.158 7.85559 13.9214 7.87 12.6321V10.8321C7.8681 9.71364 8.2954 8.63709 9.06382 7.82439C9.83224 7.01169 10.8832 6.5248 12 6.4641C13.1175 6.52381 14.1695 7.01026 14.9387 7.82307C15.708 8.63587 16.1359 9.71299 16.134 10.8321V12.4181C16.1342 12.4887 16.1416 12.559 16.156 12.6281H16.134C16.1484 13.9174 16.6483 15.154 17.534 16.0911C17.7377 16.2938 17.8977 16.5361 18.004 16.8031C18 16.8641 18 16.9331 18 17.0001Z" fill="#6B7280"/>
                  </svg>
                </v-badge>
              </template>

              <v-card
                class="mt-3"
                elevation="6"
              >
                <v-card-title class="pt-2 pb-2 mb-0 text-subtitle-1 font-weight-bold">
                 Notifikasi
                </v-card-title>
                <v-divider></v-divider>
                <v-tabs grow background-color="primary" class="notif">
                  <v-tab class="text-capitalize white--text" @click="getAll('')">Semua</v-tab>
                  <v-tab class="text-capitalize white--text" @click="getAll('reads=1')">Dibaca</v-tab>
                  <v-tab class="text-capitalize white--text" @click="getAll('reads=0')">Belum Dibaca</v-tab>
                </v-tabs>
                <v-list dense class="" style="width: 360px;max-height: 450px;overflow-y: scroll;">
                  <v-list-item v-if="notif.length ==0">
                    <v-list-item-content>Belum ada notifikasi</v-list-item-content>
                  </v-list-item>

                  <v-list-item v-else v-for="(item, i) in notif" :key="i" style="border-bottom:1px solid #0000001f;" @click="changeStatus(item.id)" :class="{'bg-list-notif': item.read === false}">
                    <v-list-item-content>
                      <div class="my-1 d-flex">
                        <v-icon
                          v-if="item.id"
                          class="mt-0 mr-1"
                          color="green darken-3"
                          small
                          >ri-check-</v-icon
                        >
                        <div>
                          
                        <p class="mb-1 text-subtitle-1 font-weight-medium">{{ item.notification.title}}</p>
                        <div v-if="item.additional != null">
                          <div style="background: #bdc3c730 !important; width: 300px; font-size: 11px; line-height: 2em; " class="px-2 py-1 rounded-lg text-capitalize">
                            <div class="justify-start d-flex " style="gap: 4px">
                              <div class="font-weight-bold">Program:</div>
                              <div class="font-weight-bold" style="color: #636e72">{{ item.additional.program.name }}</div>
                            </div>
                            <div class="justify-start d-flex " style="gap: 4px">
                              <div class="font-weight-bold" style=" ">Topik:</div>
                              <div class="font-weight-bold" style="color: #636e72">{{ item.additional.topic.name }}</div>
                            </div>
                            <div class="justify-start d-flex " style="gap: 4px">
                              <div class="font-weight-bold" style="">Aktifitas:</div>
                              <div class="font-weight-bold" style="color: #636e72">{{ item.additional.activity.name }}</div>
                            </div>
                          </div>
                        </div>
                        <div class="text-muted text-body-2" v-html="item.notification.description"></div>
                        <span small class="text-caption primary--text font-weight-bold">{{ currentDateTime(item.created_at) }}</span>
                        </div>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-card-actions v-if="limitNotif <= totalNotif" class="justify-center d-flex align-center subtitle-2 primary--text" style="cursor: pointer">
                    <div v-if="isLoading" class="my-1 text-center">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </div>
                    <div
                      v-if="!isLoading"
                      @click="getNewNotifData()"
                      block
                      small>
                      Tampilkan lebih banyak
                    </div>
                  </v-card-actions>
                </v-list>
              </v-card>
            </v-menu>

            <!-- PRIVATE MESSAGE -->
            <!-- <v-menu
              right
              :close-on-content-click="false"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  :content="totalPrivate"
                  :value="totalPrivate"
                  color="primary"
                  overlap
                  class="mx-3"
                >
                  <svg 
                    v-bind="attrs" v-on="on" 
                    @click="getAllNotifPrivate()"
                    xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                    <path d="M18 0H2C1.46957 0 0.960859 0.224761 0.585786 0.624839C0.210714 1.02492 0 1.56754 0 2.13333V13.8667C0 14.4325 0.210714 14.9751 0.585786 15.3752C0.960859 15.7752 1.46957 16 2 16H18C18.5304 16 19.0391 15.7752 19.4142 15.3752C19.7893 14.9751 20 14.4325 20 13.8667V2.13333C20 1.56754 19.7893 1.02492 19.4142 0.624839C19.0391 0.224761 18.5304 0 18 0ZM17.453 2.13333L10.043 8.83627L2.556 2.13333H17.453ZM2 13.8667V4.42133L8.8 10.5013C9.14214 10.7758 9.55889 10.9237 9.987 10.9227C10.4415 10.9208 10.8829 10.7604 11.244 10.4661L18 4.42133V13.8667H2Z" fill="#6B7280"/>
                  </svg>
                </v-badge>
              </template>

              <v-card
                class="mt-3"
                elevation="6"
              >
                <v-card-title class="pt-2 pb-2 mb-0 text-subtitle-1 font-weight-bold">
                Pesan Diskusi Private
                </v-card-title>
                <v-divider></v-divider>

                <v-list dense class="" style="width: 360px;max-height: 450px;overflow-y: scroll;">
                  <v-list-item v-if="notifPrivate.length == 0">
                    <v-list-item-content>Belum ada pesan terbaru</v-list-item-content>
                  </v-list-item>

                  <v-list-item v-else v-for="(item, i) in notifPrivate" :key="i" style="border-bottom:1px solid #0000001f;" @click="changeStatusPrivate(item)" :class="{'bg-list-notif': item.read === false}">
                    <v-badge
                      :content="item.unread_message"
                      :value="item.unread_message"
                      class="mr-4"
                      overlap>
                      <v-avatar>
                        <v-img
                          :src="require('@/assets/images/no-pict.png')"
                          :alt="item.trainer.fullname"
                          ></v-img>
                      </v-avatar>
                    </v-badge>
                    <v-list-item-content>
                      <div class="my-1 d-flex">
                          <div>                          
                            <p class="mb-1 font-weight-bold">{{ item.trainer.}}</p>
                            <div style="background: #bdc3c730 !important; width: 300px; font-size: 11px; line-height: 2em; " class="px-2 py-1 rounded-lg text-capitalize">
                              <div class="justify-start d-flex " style="gap: 4px">
                                <div class="font-weight-bold">Program:</div>
                                <div class="font-weight-bold" style="color: #636e72">{{ item.program.title }}</div>
                              </div>
                            </div>
                            <div class="text-muted caption" v-html="item.last_message"></div>
                            <span small class="text-caption color-disable">{{ currentDateTime(item.updated_at) }}</span>
                          </div>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu> -->

            <!-- {{ user_sistem.talent_photo_url }} -->
            <v-menu offset-y class="d-none d-md-block transparent" v-if="$vuetify.breakpoint.mdAndUp">
              <template v-slot:activator="{ on, attrs }" >
                <v-avatar 
                  v-if="user_sistem.talent_photo_url"
                  v-bind="attrs"
                  v-on="on"
                  class="mx-5"
                  size="36px">
                  <img
                    alt="Avatar"
                    :src="user_sistem.talent_photo_url"
                  >
                </v-avatar>
                <v-avatar 
                  v-else
                  v-bind="attrs"
                  v-on="on"
                  class="mx-5"
                  size="36"
                  color="primary">
                  <span class="subtitle-1 white--text">{{ initialName }}</span>
                </v-avatar>
              </template>

              <v-list dense outlined class="rounded-lg">
                <v-list-item link @click="Setting">
                  <v-list-item-title>
                    <div class="d-flex">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                        <path d="M7.99967 8.14904C7.36069 8.14904 6.73605 7.96384 6.20476 7.61684C5.67346 7.26985 5.25936 6.77665 5.01483 6.19962C4.7703 5.62259 4.70632 4.98764 4.83098 4.37507C4.95564 3.7625 5.26334 3.19982 5.71518 2.75818C6.16701 2.31654 6.74267 2.01578 7.36938 1.89393C7.99609 1.77208 8.64569 1.83462 9.23604 2.07363C9.82638 2.31265 10.331 2.7174 10.686 3.23672C11.041 3.75603 11.2304 4.36658 11.2304 4.99115C11.2295 5.82839 10.8888 6.63107 10.2831 7.22309C9.67744 7.81511 8.85624 8.14811 7.99967 8.14904ZM7.99967 3.23676C7.64468 3.23676 7.29766 3.33966 7.0025 3.53243C6.70733 3.7252 6.47728 3.9992 6.34143 4.31977C6.20558 4.64035 6.17004 4.99309 6.23929 5.33341C6.30855 5.67373 6.47949 5.98633 6.73051 6.23169C6.98153 6.47704 7.30134 6.64413 7.64951 6.71182C7.99768 6.77952 8.35857 6.74477 8.68654 6.61199C9.01451 6.4792 9.29483 6.25434 9.49206 5.96583C9.68928 5.67733 9.79455 5.33813 9.79455 4.99115C9.79455 4.52586 9.60544 4.07962 9.26884 3.75061C8.93224 3.4216 8.4757 3.23676 7.99967 3.23676Z" fill="#111928"/>
                        <path d="M11.9484 15.1666H4.05096C3.86054 15.1666 3.67793 15.0927 3.54329 14.961C3.40865 14.8294 3.33301 14.6509 3.33301 14.4648V12.3596C3.33415 11.4293 3.71272 10.5375 4.38568 9.87972C5.05864 9.22194 5.97104 8.85191 6.92275 8.8508H9.0766C10.0283 8.85191 10.9407 9.22194 11.6137 9.87972C12.2866 10.5375 12.6652 11.4293 12.6663 12.3596V14.4648C12.6663 14.6509 12.5907 14.8294 12.4561 14.961C12.3214 15.0927 12.1388 15.1666 11.9484 15.1666ZM4.76891 13.7631H11.2304V12.3596C11.2304 11.8012 11.0035 11.2657 10.5996 10.8709C10.1957 10.4761 9.64783 10.2543 9.0766 10.2543H6.92275C6.35152 10.2543 5.80368 10.4761 5.39975 10.8709C4.99583 11.2657 4.76891 11.8012 4.76891 12.3596V13.7631Z" fill="#111928"/>
                      </svg>
                      <div class="pl-2 my-auto">Profile & Resume</div>
                    </div>
                  </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <!-- <v-list-item link>
                  <v-list-item-title>
                    <div class="d-flex">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                        <path d="M12.188 1.83326H7.30633C7.07269 1.83243 6.84122 1.88379 6.62536 1.98439C6.4095 2.08498 6.21355 2.2328 6.04887 2.41926L4.37304 4.30459C4.2073 4.48986 4.07591 4.71031 3.98649 4.95316C3.89708 5.196 3.85142 5.45641 3.85216 5.71926V7.16659C3.85216 7.3434 3.91459 7.51297 4.02572 7.638C4.13685 7.76302 4.28758 7.83326 4.44474 7.83326C4.60191 7.83326 4.75263 7.76302 4.86376 7.638C4.9749 7.51297 5.03733 7.3434 5.03733 7.16659V6.49993H6.81508C7.12941 6.49993 7.43086 6.35945 7.65312 6.1094C7.87538 5.85935 8.00025 5.52022 8.00025 5.16659V3.16659H12.1483L12.188 13.8333H5.03733V12.4999C5.03733 12.3231 4.9749 12.1535 4.86376 12.0285C4.75263 11.9035 4.60191 11.8333 4.44474 11.8333C4.28758 11.8333 4.13685 11.9035 4.02572 12.0285C3.91459 12.1535 3.85216 12.3231 3.85216 12.4999V13.8333C3.84724 14.1808 3.96508 14.5164 4.17983 14.7664C4.39457 15.0163 4.68868 15.1603 4.99763 15.1666H12.188C12.497 15.1603 12.7911 15.0163 13.0058 14.7664C13.2206 14.5164 13.3384 14.1808 13.3335 13.8333V3.16659C13.3384 2.81901 13.2206 2.48342 13.0058 2.23346C12.7911 1.9835 12.497 1.83957 12.188 1.83326ZM5.28266 5.16659L6.81508 3.44259V5.16659H5.28266Z" fill="#111928"/>
                        <path d="M7.61744 11.2073C7.56084 11.2688 7.5157 11.3423 7.48464 11.4237C7.45359 11.505 7.43724 11.5925 7.43656 11.681C7.43587 11.7695 7.45087 11.8573 7.48066 11.9392C7.51046 12.0212 7.55446 12.0956 7.6101 12.1582C7.66574 12.2208 7.7319 12.2703 7.80473 12.3038C7.87755 12.3373 7.95558 12.3542 8.03427 12.3534C8.11295 12.3527 8.19071 12.3343 8.26301 12.2993C8.3353 12.2644 8.40069 12.2136 8.45536 12.1499L10.0956 10.3046C10.2067 10.1796 10.2691 10.01 10.2691 9.83326C10.2691 9.65648 10.2067 9.48694 10.0956 9.36192L8.45536 7.51659C8.40069 7.45292 8.3353 7.40213 8.26301 7.36719C8.19071 7.33225 8.11295 7.31386 8.03427 7.31309C7.95558 7.31232 7.87755 7.32919 7.80473 7.36271C7.7319 7.39623 7.66574 7.44573 7.6101 7.50833C7.55446 7.57092 7.51046 7.64536 7.48066 7.72729C7.45087 7.80922 7.43587 7.89701 7.43656 7.98553C7.43724 8.07404 7.45359 8.16152 7.48464 8.24286C7.5157 8.3242 7.56084 8.39776 7.61744 8.45926L8.24558 9.16659H3.25958C3.10241 9.16659 2.95169 9.23683 2.84056 9.36185C2.72942 9.48688 2.66699 9.65645 2.66699 9.83326C2.66699 10.0101 2.72942 10.1796 2.84056 10.3047C2.95169 10.4297 3.10241 10.4999 3.25958 10.4999H8.24558L7.61744 11.2073Z" fill="#111928"/>
                      </svg>
                      <div class="pl-2 my-auto">Lamaran Saya</div>
                    </div>
                  </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider> -->
                <v-list-item link @click="Logout">
                  <v-list-item-title>
                    <div class="d-flex">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                        <path d="M13.2823 8.24541C13.2483 8.16341 13.1997 8.09008 13.1377 8.02808L10.4717 5.36208C10.211 5.10141 9.78966 5.10141 9.52899 5.36208C9.26833 5.62275 9.26833 6.04408 9.52899 6.30475L11.0577 7.83341H5.33366C4.96499 7.83341 4.66699 8.13208 4.66699 8.50008C4.66699 8.86808 4.96499 9.16675 5.33366 9.16675H11.0577L9.52899 10.6954C9.26833 10.9561 9.26833 11.3774 9.52899 11.6381C9.65899 11.7681 9.82966 11.8334 10.0003 11.8334C10.171 11.8334 10.3417 11.7681 10.4717 11.6381L13.1377 8.97208C13.1997 8.91075 13.2483 8.83675 13.2823 8.75475C13.3497 8.59208 13.3497 8.40808 13.2823 8.24541Z" fill="#111928"/>
                        <path d="M6.66699 12.5001H4.66699C4.29899 12.5001 4.00033 12.2007 4.00033 11.8334V5.16675C4.00033 4.79941 4.29899 4.50008 4.66699 4.50008H6.66699C7.03566 4.50008 7.33366 4.20141 7.33366 3.83341C7.33366 3.46541 7.03566 3.16675 6.66699 3.16675H4.66699C3.56433 3.16675 2.66699 4.06408 2.66699 5.16675V11.8334C2.66699 12.9361 3.56433 13.8334 4.66699 13.8334H6.66699C7.03566 13.8334 7.33366 13.5347 7.33366 13.1667C7.33366 12.7987 7.03566 12.5001 6.66699 12.5001Z" fill="#111928"/>
                      </svg>
                      <div class="pl-2 my-auto">Logout</div>
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-app-bar-nav-icon
              v-if="!$vuetify.breakpoint.mdAndUp"
              @click="dialog.menuAuth = true">
              <v-avatar 
                v-if="user_sistem.talent_photo_url"
                class="mx-5"
                size="36">
                <img
                  alt="Avatar"
                  :src="user_sistem.talent_photo_url"
                >
              </v-avatar>
              <v-avatar 
                v-else
                class="mx-5"
                size="36"
                color="primary">
                <span class="subtitle-1 white--text">{{ initialName }}</span>
              </v-avatar>
            </v-app-bar-nav-icon>
            <v-dialog
              v-model="dialog.menuAuth"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <v-card>
                <v-toolbar>
                  <v-toolbar-title>
                    <div class="my-1 text-left">
                      <img
                        src="@/assets/images/logo-dash.png"
                        alt="Avatar"
                        class="img-yec-test"
                      />
                    </div>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn
                      icon
                      dark
                      @click="dialog.menuAuth = false"
                      color="primary"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-spacer></v-spacer>
                <v-card-text class="px-0">
                  <v-list dense id="list-menu-user">
                    <!-- <v-list-item link @click="Dashboard">
                      <v-list-item-title class="body-1">
                        Dashboard
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider> -->
                    <v-list-item link @click="Kelas">
                      <v-list-item-title class="body-1">
                        Bootcamp & TeFa
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <!-- <v-list-item link @click="Jobs">
                      <v-list-item-title class="body-1">
                        Lowongan Kerja
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider> -->
                    <v-list-item link @click="Setting">
                      <v-list-item-title class="body-1">
                        Profile & Resume
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <!-- <v-list-item link @click="Applyment">
                      <v-list-item-title class="body-1">
                        Lamaran Saya
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider> -->
                    <v-list-item link @click="Logout">
                      <v-list-item-title class="body-1">
                        Logout
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-app-bar>

          <!-- <v-alert
            color="#E1EFFE"
            style="position: fixed;margin-top: 64px;width: 100%;z-index: 2;"
            dismissible
            close-icon="mdi-close"
            class="px-16 primary--text fs-16">
            <div class="d-flex font-weight-bold">
              <v-icon left size="14" color="primary">mdi-check-circle</v-icon>
              Mohon untuk melengkapi data diri anda sebelum menggunakan platform lebih lanjut
            </div>
            <div class="mt-2 fs-14">
              kelengkapan data akun diperlukan untuk dapat mengakses berbagai kelebihan di platform talent scouting
            </div>
            :style="false ? 'margin-top: 128px;': 'margin-top: 64px;'"
          </v-alert> -->


          <!-- height: calc(100vh - 64px); -->
          <v-container fluid style="margin-top: 64px;" class="mb-16">
            <transition name="fade-up">
              <router-view v-if="loaded"></router-view>
            </transition>
          </v-container>
        </v-main>
      </div>
      <div class="py-5 text-center text-gray-700 font-weight-medium" style="background-color: #F9FAFB; position: fixed; bottom: 0; width: 100%;">
        <span>&copy; 2021 Talent Scouting</span>
      </div>
      <v-snackbar 
        top 
        v-model="snackbar.state"
        color="primary"
        outlined
        :timeout="timeout"
      >
        <div v-html="snackbar.text"></div>
        <template v-slot:action="{ attrs }">
          <v-btn
            small
            icon
            color="error"
            v-bind="attrs"
            @click="snackbar.state = false"
          >
            <v-icon>$close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-dialog
        v-model="showScan"
        persistent
      >
        <v-btn
          v-show="scanBarcode"
          @click="showScan = false"
          style="position: absolute;right: 0;top:0;"
          icon
        >
           <v-icon color="#000" large>ri-close-circle-line</v-icon>
        </v-btn>
        <v-card v-if="scanBarcode" class="pa-4">
          <v-card-text class="text-center pa-0">
            <p class="text-h6 font-weight-normal">Scan Barcode untuk absen masuk kelas yang sudah dibeli</p>
            <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
          </v-card-text>
        </v-card>
        <v-card v-if="result" class="rounded-xl pa-4">
          <v-card-text class="pb-0 text-center">
            <span class="text-h5">Scan QR Code Absen Kelas</span>
            <div class="pt-0 mx-auto col-md-8">
              <p class="pt-3 mb-0 text-body-1">{{program}}</p>
            </div>
            <template v-if="success_scan">
              <v-icon large color="success" class="mb-3">ri-checkbox-circle-fill</v-icon>
              <p color="success" class="text-body-1 font-weight-bold success--text">Berhasil</p>
            </template>
            <template v-if="!success_scan">
              <v-alert type="error" text v-show="response !== null">{{ response }}</v-alert>
              <v-icon large color="error" class="mb-3">ri-close-circle-line</v-icon>
              <p color="error" class="text-body-1 font-weight-bold error--text">Gagal</p>
            </template>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-app>
  </transition>
</template>
<script>
import { LMS_API } from "@/constants/api";

import { mapGetters } from "vuex";
import moment from "moment";
import firebase from 'firebase/app'
import 'firebase/firebase-messaging'
import { StreamBarcodeReader } from "vue-barcode-reader";


export default {
  components: {
    StreamBarcodeReader,
  },
  
  data() {
    return {
      breadcrumbs: [],
      loaded: true,
      isLoading: false,
      drawer: null,
      total:null,
      totalPrivate:null,
      notifications: [1],
      limitNotif: 10,
      totalNotif: 0,
      reads: '',
      pageTitle: "",
      notif:[],
      notifPrivate:[],
      snackbar: {
        state: false,
        text: "",
      },
      timeout:5000,
      // title:false,
      pathnya: "",
      dialog: {
        menuAuth: false,
      },
      attend:false,
      items: [
        // { title: "Dashboard", icon: "$Dashboard", link: "/dashboard" },
        // { title: "Resume", icon: "$kelas", link: "/resume" },
        { title: "Bootcamp & TeFa", icon: "$kelas", link: "/class" },
        // { title: "Lamaran", icon: "$kelas", link: "/application" },
        // { title: "Lowongan Pekerjaan", icon: "$kelas", link: "/jobs" },
      ],
      showScan:false,
      scanBarcode:true,
      result:false,
      success_scan: null,
      program:'',
      response:'',
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["user_sistem"]),

    programName() {
      let localStore = localStorage.getItem('sistem_program_name')
      let stores = this.$store.state.program_name

      return stores ? stores : localStore
    },

    isDisabledMenu() {
      // console.log(this.user);
      // console.log(this.user_sistem);
      // return true
      return this.user_sistem.talent_first_name == ""
    },
    user_fullname() {
      return this.user_sistem.talent_first_name + ' ' + this.user_sistem.talent_last_name
    },
    initialName() {
      let firstAlias = "";
      let secondAlias = "";
        let name = this.user_sistem ? this.user_fullname : "USER TESTING";
        let splitname = name.split(" ");

        if (splitname[0]) {
          firstAlias = splitname[0].substring(0, 1);
        }

        if (splitname[1]) {
          secondAlias = splitname[1].substring(0, 1);
        }
      return firstAlias + secondAlias;
    },

    hiddenArrowNavbar() {
      return (
        this.$route.path !== '/dashboard' &&
        this.$route.path !== '/class' &&
        this.$route.path !== '/application' &&
        this.$route.path !== '/jobs' &&
        this.$route.path !== '/resume' &&
        this.$route.path !== '/company' &&
        this.$route.path !== '/account'
      )
    },

    showMenuBarWhen() {
      return (
        this.$route.name !== 'checkpoint' &&
        this.$route.name !== 'checkpoint-disclaimer' &&
        this.$route.name !== 'checkpoint-specification' &&
        this.$route.name !== 'checkpoint-device' &&
        this.$route.name !== 'detail-class' && 
        this.$route.name !== 'detail-class-v2'
      )
    }

  },
  created() {
    this.$root.$on("setDialog", (attend) => {
      this.attend=attend
    })
    this.$root.$on("setPageTitle", (pageTitle) => {
      this.pageTitle = pageTitle;
    });

    this.pathnya = this.$route.path;
  },
  
  mounted() {
    // this.getAll();
    // this.getAllNotifPrivate()
    document.addEventListener("visibilitychange", async () => {
      if (!document.hidden) {
        this.getNotifUnread()
        this.getAllNotifPrivate()
      }
    }, false);
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    }
    // if (this.user.id) {
    //   if (this.$firebaseIsSupported) {
    //     this.fcmToken().then(() => {})
    //     this.$messaging.onTokenRefresh(() => {
    //       this.fcmToken()
    //     });
    //   }
    // }
  },
  methods: {
    onDecode (result) {
      if (result) {
        const data = {
          member_redeem_id: result,
        }
        this.axios
          .post("presence/v1/scan",data)
          .then(() => {
            this.success_scan = true
            this.getDetailClass(result);
            this.result = true;
            this.scanBarcode = false;
             setTimeout(()=> {
              this.success_scan = null
              this.showScan = false;
              this.result = false;
              this.scanBarcode = true;
            }, 5000);
          })
          .catch((error) => {
            this.success_scan = false
            this.getDetailClass(result);
            this.result = true;
            this.scanBarcode = false;
            this.res = false;
            this.response = error.response.data.message
             setTimeout(()=> {
              this.success_scan = null
              this.result = false;
              this.scanBarcode = true;
              this.res = false;
              this.response = null;
            }, 5000);
          });
      }
    },
    getDetailClass() {
      this.loadContent = true;
      this.axios.get(`${LMS_API.PROGRAM.DETAIL(this.$route.params.id)}`).then((response) => {
        this.program=response.data.data.program.title;
      })
    },
    onLoaded() {
    },
    getAllNotifPrivate(){
      this.getNotifPrivate();
      this.getNotifPrivateTotal();
    },
    getNewNotifData(){
      this.limitNotif = this.limitNotif + 5;
      this.getAll(this.reads);
    },
    getAll(reads){
      this.reads = reads;
      this.getNotif();
      this.getNotifUnread();
    },
    receiveMessage() {
      try {
        firebase.messaging().onMessage(() => {
          // console.log("payload ", payload);
        });
      } catch (e) {
        // console.log(e);
      }
    },
    fcmToken() {
         this.$messaging
          .requestPermission()
          .then(async () => {
             this.$messaging
              .getToken()
              .then(async (token) => {
                // console.log("token ", token);
                if (token && token != '') {
                    this.axios
                    .post(`${LMS_API.NOTIFICATION.SUBSCRIBE}`, {
                      token_fcm: token,
                    })
                    .then(async () => {
                      this.getNotif();
                    })
                    .catch(() => {
                      // console.log(error);
                    });

                    this.$messaging.onMessage(async (payload) => {
                      // console.log('onMessage',payload)
                        this.total = this.total+1;
                     this.getAll();
                     this.getAllNotifPrivate()
                    // console.log("payload ", payload);
                    // const notificationTitle = payload.notification.title;
                    const notificationTitle = payload.data.title;
                    const notificationOptions = {
                      // body: payload.notification.body,
                      body: payload.data.body,
                      icon: "https://i.postimg.cc/vTFgKvRZ/favicon.png",
                    };
                    /* return navigator.serviceWorker.showNotification(
                      notificationTitle,
                      notificationOptions
                    ) */
                    navigator.serviceWorker.getRegistration().then(registration => {
                      // console.log(registration);
                      if (registration) {
                        // if (registration.length > 0) {
                        return registration.showNotification(
                          notificationTitle,
                          notificationOptions
                        )
                      }
                    });
                  });
                }
              }).catch(() => {
                // console.log("Err ", err);
              });
          })
          .catch(() => {
            // console.log("Unable to get token ", err);
          });
    },
    getNotifPrivate(){
      this.axios
      .get(`${LMS_API.NOTIFICATION.PRIVATE.GET_LIST}?limit=-1&page=1&sort=updated_at&sort_type=asc`)
      .then((response) => {
        let res = response.data.data;
        this.notifPrivate = res.list;
      });
    },
    getNotifPrivateTotal(){
      this.axios
      .get(`${LMS_API.NOTIFICATION.PRIVATE.GET_TOTAL_UNREAD}`)
      .then((response) => {
        this.totalPrivate= response.data.data.total_unread;
      });
    },
    async getNotif(){
      this.isLoading = true;
      await this.axios
      .get(`${LMS_API.NOTIFICATION.GET}?limit=${this.limitNotif}&page=1&sort_by=_id&dir=desc&${this.reads ? this.reads : ''}`)
      .then((response) => {
        let res = response.data.data;
        this.notif = res.list;
        this.isLoading = false;
        this.totalNotif=res.total_data;
      });
    },
    getNotifUnread(){
      this.axios
      .get(`${LMS_API.NOTIFICATION.GET}?limit=10&page=1&sort_by=_id&dir=desc&reads=0`)
      .then((response) => {
        let res = response.data.data;
        this.total=res.total_data;
      });
    },
    currentDateTime(a) {
      return moment.utc(a).locale('ID').fromNow();
    },
    changeStatusPrivate(item) {

      if(item.unread_message > 0){
        this.axios
        .put(`${LMS_API.NOTIFICATION.PRIVATE.READ(item.id)}`)
        .then(() => {
          this.getAllNotifPrivate();
          this.$router.push(`/class?program=${item.program.id}`);
          if(this.$route.name == 'my-class'){
            setTimeout(() => {
              window.location.reload(true);
            }, 10);
          }
        });
      }else{
        this.getAllNotifPrivate();
        this.$router.push(`/class?program=${item.program.id}`);
        if(this.$route.name == 'my-class'){
          setTimeout(() => {
            window.location.reload(true);
          }, 10);
        }
      }
    },
    changeStatus(id) {
      // console.log(id)
      this.axios
        .put(`${LMS_API.NOTIFICATION.READ(id)}`)
        .then(() => {
          this.snackbar.text = `Notifikasi sudah terbaca`;
          this.snackbar.state = true;   
          this.getAll(this.reads);
        });
    },
    async Logout() {
      // make axios request to logout with this url /v1/logout 
      // and then redirect to login page
      await this.axios.post(`${LMS_API.AUTH.LOGOUT}`).then(() => {
        this.$store.dispatch("logout").then(() => {
          firebase.messaging()
          .deleteToken({ token: localStorage.getItem("fcm_token") })
          .then(() => {
            this.$router.push("/");
            console.log("Token deleted successfully");
          }).catch((err) => {
            this.$router.push("/");
            console.log("Unable to delete token. ", err);
          });
          localStorage.removeItem("fcm_token");
        });
        this.dialog.menuAuth = false;
      });
    },
    async goBack() {
      // const stream = await navigator.mediaDevices.getUserMedia({ video: true });

      // stream.getTracks().forEach((track) => {
      //   if (track.readyState == 'live' && track.kind === 'video') {
      //       track.stop();
      //   }
      // });

      localStorage.setItem('sistem_program_name', "")
      this.$store.commit("SET_PROGRAM_NAME", "");
      
      this.$router.go(-1);
      this.dialog.menuAuth = false;

    },
    Setting() {
      this.$router.push("/account");
      this.dialog.menuAuth = false;
    },
    Jobs() {
      this.$router.push("/jobs");
      this.dialog.menuAuth = false;
    },
    Applyment() {
      this.$router.push("/application");
      this.dialog.menuAuth = false;
    },
    Dashboard() {

      localStorage.setItem('sistem_program_name', "")
      this.$store.commit("SET_PROGRAM_NAME", "");
      
      this.$router.push("/dashboard");
      this.dialog.menuAuth = false;

    },
    Kelas() {
      localStorage.setItem('sistem_program_name', "")
      this.$store.commit("SET_PROGRAM_NAME", "");

      
      this.$router.push("/class");
      this.dialog.menuAuth = false;

    },
    Sertifikat() {
      this.$router.push("/sertifikat");
            window.location.reload(true);
      
      this.dialog.menuAuth = false;
    },
    Tele() {
      window.open("https://chat.whatsapp.com/GJ7R9A6e6qvLMOWHYFFtwV", "_blank");
    },
    menu() {
      this.$router.push("/");
    },
    pageChanged(segment, location = null) {
      // console.log(segment);
      // console.log(location);
      this.pathnya = this.$route.path;
      // console.log("pathpage", this.pathnya);
      this.breadcrumbs.splice(segment);
      if (location != null) {
        this.pageTitle = location.title;
        this.breadcrumbs.map((item) => (item.disabled = false));
        this.breadcrumbs.push({
          text: location.title,
          disabled: true,
          href: location.link,
        });
      }
      // window.location.reload(true);
      document.title =
        this.breadcrumbs.map((val) => val.text).join(" -> ") +
        " - " +
        process.env.VUE_APP_TITLE;
    },
  },
};
</script>

<style>
.v-app-bar.v-app-bar--fixed .v-toolbar__content {
  border-bottom: 1px solid #0000001f;
}

.v-alert__dismissible .v-btn__content .mdi-close {
  color: #1A56DB;
  font-size: 16px;
}
.notif .v-tabs-slider {
    background-color: white;
    height: 100%;
    width: 100%;
}
.bg-list-notif{
  background-color: rgba(116, 185, 255, 0.1);
}
.content-wrapper {
  position: relative;
}
/*.menu-active::after {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  left: 0;
  background: var(--v-primary-base);
}*/
.img-yec {
  height: 50px;
  width: 100%;
  object-fit: contain;
}
/*.img-yec:hover {
  cursor: pointer;
}*/
.img-yec-test {
  height: 40px;
  object-fit: contain;
}
#list-menu-user .v-list-item {
  padding: 8px 16px;
}
/*.img-yec-test:hover {
  cursor: pointer;
}*/
</style>