<template>
  <v-card outlined flat class="mt-4 rounded-lg">
    <v-card-title>
      Skill Pekerjaan 
      <v-spacer></v-spacer>
      <v-btn 
        v-if="!showSkill" 
        color="#1F2A37"
        style="border: 1px solid #E5E7EB"
        class="rounded-lg"
        x-small
        outlined
        @click="onOpenForm()">
        Tambah
      </v-btn>
      <div v-else>
        <v-btn 
          color="primary"
          x-small
          depressed
          class="rounded-lg"
          @click="onSaveSkills()">
          Simpan
        </v-btn>
        <v-btn 
          color="#1F2A37"
          style="border: 1px solid #E5E7EB"
          class="ml-2 rounded-lg"
          x-small
          outlined
          @click="showSkill = !showSkill">
          Tutup
        </v-btn>
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text v-show="!showSkill">

      <div class="my-3 text-left" v-if="profileData.length > 0">

        <v-row>
          <div v-for="(skill, index) in profileData" :key="index">
            <v-chip
              class="ma-2"
              small
              label
              color="#E5E7EB"
              @click="onDeleteSkill(index)">
              <div class="mr-2 black--text">{{ skill.talent_skill_name }}</div>
              <v-icon right size="16" color="#1F2A37">mdi-close</v-icon>
            </v-chip>
          </div>
        </v-row>

      </div>

      <div class="text-center" v-else>
        Beritahu Skill yang anda miliki sebagai penunjang pekerjaan anda!
      </div>

    </v-card-text>
    <v-expand-transition>
      <v-card-text v-show="showSkill">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
          <div>
            <div class="mb-1 font-weight-medium">Skill</div>
            <v-autocomplete
              v-model="skillsSelected"
              :items="skills"
              outlined
              :rules="[rules.required]"
              class="rounded-lg"
              dense
              item-text="label"
              item-value="value"
              multiple
              placeholder="Pilih Skill Anda">
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  small
                  :input-value="data.selected"
                  @click="data.select">
                  {{ data.item.label }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.label"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
            <!-- <v-text-field
              outlined
              class="rounded-lg"
              dense
              v-model="talent_skill_search"
              @keyup="fetchSkills()"
              placeholder="Pilih Skill Anda">
            </v-text-field> -->
          </div>
        </v-form>

        <!-- {{ skills }}

        <v-row no-gutters>
          <v-col cols="3" v-for="(skill, index) in skills" :key="index">
            <v-checkbox
              v-model="skillsSelected"
              :label="skill.label"
              :value="skill.value"
              hide-details>
            </v-checkbox>
          </v-col>
        </v-row>

        {{ skillsSelected }} -->

      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { JOB_PORTAL_API } from "@/constants/api";

export default {
  props: {
    profileData: {
      type: Array,
      default: () => ([])
    },
  },
  data: () => ({
    valid: true,
    showSkill: false,
    
    skills: [],
    skillsSelected: [],
    talent_skill_search: '',

    rules: {
      // array required
      required: value => value.length > 0 || 'field tidak boleh kosong',
    }
  }),
  methods: {
    onOpenForm() {
      this.showSkill = !this.showSkill
      let skills = this.profileData.map((skill) => {
        return {
          label: skill.talent_skill_name,
          value: skill.talent_skill_name
        }
      })
      
      this.profileData.length > 0 ? this.skillsSelected = skills : this.skillsSelected = []
      this.fetchSkills()
      
      this.$refs.form.resetValidation()
    },

    async fetchSkills() {
      // await this.axios.get(`${JOB_PORTAL_API.SKILLS.GET_LIST(this.talent_skill_search)}`)
      await this.axios.get(`${JOB_PORTAL_API.MASTER_DATA.GET_LIST("skill")}`)
      .then((response) => {
        this.skills = response.data
      }).catch((error) => {
        this.$refs.snackbar.open('error', error.response.data.message)
      })
    },

    // Save Skills
    async onSaveSkills() {

      if(!this.$refs.form.validate()) return
      
      let talentSkills = []

      this.skillsSelected.forEach((skill) => {
        let filters = this.skills.find((talentSkill) => talentSkill.value == skill)
        

        talentSkills.push({
          talent_skill_name: filters.label,
        })
      })

      // this.profileData = talentSkills

      this.skillsSelected = []

      this.$emit("save", talentSkills)

      this.showSkill = false
    
    },

    // Delete Skill
    onDeleteSkill(index) {

      this.skillsSelected = [...this.profileData]
      
      this.skillsSelected.splice(index, 1)

      // this.$refs.snackbar.open('success', 'Skill berhasil dihapus!')

      this.$emit("save", this.skillsSelected)
    },
  }
}
</script>