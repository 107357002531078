import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "remixicon/fonts/remixicon.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

// const MY_ICONS = {
//   // menu
//   dashboard: "ri-dashboard-3-line",
//   beranda: "ri-home-3-line",
//   kelas: "ri-slideshow-line",
//   notification: "ri-notification-2-fill",
//   guide: "ri-profile-line",
//   exercise: "ri-book-read-line",
//   dropdown: "ri-arrow-down-s-line",
//   message:"ri-message-2-line",

//   arrdown: "ri-arrow-down-s-fill",
//   next: "ri-arrow-right-s-line",
//   prev: "ri-arrow-left-s-line",
//   checkboxOff: "ri-checkbox-blank-line",
//   checkboxOn: "ri-checkbox-fill",
//   expand: "ri-arrow-down-s-line",
//   play: "ri-play-fill",
//   pause: "ri-pause-fill",

//   //epot
//   bookOpen: "ri-book-open-fill",
//   newsPapper: "ri-newspaper-line",
//   brain: "ri-brain-fill",
//   search: "ri-search-line",
// };

const opts = {
  theme: {
    themes: {
      light: {
        grey: { base: "#EFF2F7" },
        "grey-soft": { base: "#F9FAFB" },
        navy: { base: "#3C4858" },
        "navy-soft": { base: "#6B7280" },
        primary: "#1A56DB",
        secondary: "#1C64F2",
      },
    },
    options: {
      customProperties: true,
    },
  },
  icons: {
    // values: MY_ICONS,
    iconfont: "mdi",
  },
};
export default new Vuetify(opts);
