<template>
  <div 
    v-if="user_sistem.talent_social_medias.length > 0"
    style="
      margin-top: 2rem;
      margin-bottom: 2rem;
      border: 1px solid #E5E7EB; 
      border-radius: 10px;
      font-family: 'Inter', sans-serif;
      padding: 20px;">
    <div 
      style="
        font-size: 18px; 
        font-weight: 600; 
        margin-bottom: 1rem;
      ">
      Sosial Media
    </div>
    <div v-for="(socialMedia, i) in user_sistem.talent_social_medias" :key="i">
      <div 
        style="
          display: flex;
          justify-content: start;
          align-items: center;
          align-content: center;
          gap: 15px;
          margin-top: 1rem;
        ">
        <i
          :style="`
            font-size: 20px;
            color: ${setMediaColor(socialMedia.talent_social_media_name)}
          `" 
          :class="`ri-${socialMedia.talent_social_media_name.toLowerCase()}-fill`"></i>
        <!-- <v-icon size="20" :color="setMediaColor(socialMedia.talent_social_media_name)">ri-{{ socialMedia.talent_social_media_name.toLowerCase() }}-fill</v-icon> -->
        <a 
          :href="socialMedia.talent_social_media_url"
          target="_blank"
          :style="`
            font-size: 14px;
            color: ${setMediaColor(socialMedia.talent_social_media_name)}
          `">
          <div
            style="
              font-weight: 500;
            ">
            {{ socialMedia.talent_social_media_name }}
          </div>
          <div 
            style="
              font-size: 11px;
            ">
            {{ socialMedia.talent_social_media_url }}
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'AccountResumeSocialMedia',
  computed: {
    ...mapGetters(['user_sistem']),
    socialMediaColors() {
      return {
        Facebook: "#1877F2",
        Instagram: "#E1306C",
        LinkedIn: "#0077B5",
        Twitter: "#1DA1F2"
      }
    }
  },
  methods: {
    setMediaColor(media) {
      return this.socialMediaColors[media]
    },
  }
}
</script>