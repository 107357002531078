<template>
  <div 
    v-if="certificate_list.length > 0"
    style="
      margin-top: 2rem;
      margin-bottom: 2rem;
      border: 1px solid #E5E7EB; 
      border-radius: 10px;
      font-family: 'Inter', sans-serif;">
    <div 
      style="
        font-size: 18px; 
        font-weight: 600; 
        padding: 20px;
      ">
      Sertifikat
    </div>
    <v-divider></v-divider>
    <div v-for="(certificate, i) in certificate_list" :key="i">
      <div 
        style="
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: start;
          align-content: center;
          gap: 6px;
          padding: 10px 20px 10px 20px;
        ">
        <div
          style="
            font-size: 14px;
            font-weight: 600;
          "
          >
          {{ certificate.talent_certificate_institution }} - {{ certificate.talent_certificate_name }}
        </div>
        <ul 
          style="
            display: flex; 
            justify-content: start; 
            align-items: center;
            gap: 25px;
            font-size: 12px;
            color: #374151;
          ">
          <li>{{ certificate.talent_certificate_type }}</li>
          <!-- <li>SISTEM Talent Scouting</li> -->
          <li>{{ convertDate(certificate.talent_certificate_date) }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { JOB_PORTAL_API } from "@/constants/api";

import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'AccountResumeCertificates',
  data() {
    return {
      certificate_list: []
    }
  },
  computed: {
    ...mapGetters(['user_sistem'])
  },
  mounted() {
    this.fetch()
  },
  methods: {
    convertDate(date) {
      return date ? moment(date).locale("id").format("DD MMMM YYYY") : "No Date"
    },
    async fetch() {
      await this.axios.get(`${JOB_PORTAL_API.CERTIFICATE.GET_LIST}`, {
        params: {
          talent_id: this.user_sistem.talent_id
        }
      })
      .then((response) => {
        this.certificate_list = response.data.data
      }).catch((error) => {
        this.$refs.snackbar.open('error', error.response.data.message)
      })
    }
  }
}
</script>