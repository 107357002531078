<template>
  <v-card outlined flat class="mt-4 rounded-lg">
    <v-card-title>
      Pendidikan 
      <v-spacer></v-spacer>
      <v-btn 
        v-if="!showEducation" 
        color="#1F2A37"
        style="border: 1px solid #E5E7EB"
        class="rounded-lg"
        x-small
        outlined
        @click="onOpenForm()">
        Tambah
      </v-btn>
      <div v-else>
        <v-btn 
          color="primary"
          x-small
          depressed
          class="rounded-lg"
          @click="state_form == 'create' ? onAddEducations() : onUpdateEducations()">
          Simpan
        </v-btn>
        <v-btn 
          color="#1F2A37"
          style="border: 1px solid #E5E7EB"
          class="ml-2 rounded-lg"
          x-small
          outlined
          @click="onOpenForm()">
          Tutup
        </v-btn>
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text v-show="!showEducation">

      <div class="text-left" v-if="profileData.length > 0">

        <v-row v-for="(education, index) in profileData" :key="index">
          <v-col cols="3">
            <div class="black--text">{{ convertDateToMonth(education.talent_education_start_date) }} - {{ education.talent_education_is_currently_studying ? "Saat ini" : convertDateToMonth(education.talent_education_end_date) }}</div>
          </v-col>
          <v-col cols="7" class="text-left">
            <div class="font-weight-medium black--text">{{ education.talent_education_institution_name }}</div>
            <div class="black--text">{{ education.talent_education_major }}</div>
            <!-- <div class="black--text">{{ education.talent_work_experience_description }}</div> -->
          </v-col>
          <v-col cols="2" class="justify-end text-right d-flex align-end">
            <v-btn
              color="#1F2A37"
              class="rounded-lg"
              x-small
              icon
              @click="onOpenEditEducations(index)">
              <v-icon size="20">ri-pencil-line</v-icon>
            </v-btn>
            <v-btn
              color="#1F2A37"
              class="rounded-lg"
              x-small
              icon
              @click="onDeleteEducations(index)">
              <v-icon size="20">ri-delete-bin-7-line</v-icon>
            </v-btn>
          </v-col>
        </v-row>

      </div>

      <div class="text-center" v-else>
        Silahkan isi riwayat pendidikan Anda
      </div>

    </v-card-text>
    <v-expand-transition>
      <v-card-text v-show="showEducation">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
          <!-- <div>
            <div class="mb-1 font-weight-medium">Jenjang Pendidikan</div>
            <v-select
              outlined
              class="rounded-lg"
              dense
              :items="['SD', 'SMP', 'SMA', 'D3', 'S1', 'S2', 'S3']"
              placeholder="Pilih Jenjang Pendidikan">
            </v-select>
          </div> -->
  
          <div>
            <div class="mb-1 font-weight-medium">Nama Institusi</div>
            <v-text-field
              outlined
              class="rounded-lg"
              dense
              :rules="[rules.required]"
              v-model="talent_educations.talent_education_institution_name"
              placeholder="Tuliskan Nama Institusi">
            </v-text-field>
          </div>
  
          <v-row class="mt-0">
            <v-col>
              <div>
                <div class="mb-1 font-weight-medium">Detail Nama Jurusan</div>
                <v-text-field
                  outlined
                  class="rounded-lg"
                  dense
                  :rules="[rules.required]"
                  v-model="talent_educations.talent_education_major"
                  placeholder="Tuliskan Detail Nama Jurusan">
                </v-text-field>
              </div>
            </v-col>
            <!-- <v-col>
              <div>
                <div class="mb-1 font-weight-medium">IPK/Nilai Rata-rata ujian sekolah</div>
                <v-text-field
                  outlined
                  class="rounded-lg"
                  dense
                  placeholder="Tuliskan IPK">
                </v-text-field>
              </div>
            </v-col> -->
          </v-row>
  
          <v-row>
            <v-col>
              <div>
                <div class="mb-1 font-weight-medium">Bulan dan tahun masuk</div>
                <v-menu
                  v-model="talent_education_start_date"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      v-on="on"
                      v-bind="attrs"
                      class="rounded-lg"
                      dense
                      readonly
                      :rules="[rules.required]"
                      v-model="talent_educations.talent_education_start_date"
                      placeholder="Tuliskan Tahun Mulai">
                    </v-text-field>
                  </template>
                  <v-date-picker
                    type="month"
                    v-model="talent_educations.talent_education_start_date"
                    @input="talent_education_start_date = false">
                  </v-date-picker>
                </v-menu>
              </div>
            </v-col>
            <v-col>
              <div>
                <div class="mb-1 font-weight-medium">Bulan dan tahun selesai</div>
                <v-menu
                  v-model="talent_education_end_date"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      v-on="on"
                      v-bind="attrs"
                      class="rounded-lg"
                      dense
                      :hide-details="talent_educations.talent_education_is_currently_studying"
                      :rules="talent_educations.talent_education_is_currently_studying ? [] : [rules.required]"
                      :readonly="talent_educations.talent_education_is_currently_studying"
                      :disabled="talent_educations.talent_education_is_currently_studying"
                      v-model="talent_educations.talent_education_end_date"
                      placeholder="Tuliskan Tahun Selesai">
                    </v-text-field>
                  </template>
                  <v-date-picker
                    type="month"
                    :min="talent_educations.talent_education_start_date"
                    v-model="talent_educations.talent_education_end_date"
                    @input="talent_education_end_date = false">
                  </v-date-picker>
                </v-menu>
  
                <v-checkbox
                  dense
                  @change="() => talent_educations.talent_education_end_date = ''"
                  v-model="talent_educations.talent_education_is_currently_studying"
                  label="Masih sekolah di institusi ini">
                </v-checkbox>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    profileData: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    valid: true,
    showEducation: false,

    rules: {
      required: value => !!value || 'field tidak boleh kosong.',
    },

    state_index: 0,
    state_id: "",
    state_form: "create",

    // MENU DATE PICKER EDUCATION
    talent_education_start_date: false,
    talent_education_end_date: false,

    talent_educations: {
      talent_education_institution_name: "",
      talent_education_major: "",
      talent_education_start_date: "",
      talent_education_end_date: "",
      talent_education_description: "",
      talent_education_is_currently_studying: true
    },
  }),
  methods: {
    convertDateToMonth(date) {
      return date ? moment(date).locale("id").format("MMMM YYYY") : "No Date"
    },

    onOpenForm() {
      this.showEducation = !this.showEducation

      this.talent_educations = {
        talent_education_institution_name: "",
        talent_education_major: "",
        talent_education_start_date: "",
        talent_education_end_date: "",
        talent_education_description: "",
        talent_education_is_currently_studying: true
      }

      this.$refs.form.resetValidation()
    },
    onValidation() {
      let isValid = true

      if(!this.talent_educations.talent_education_is_currently_studying) {
        if(this.talent_educations.talent_education_end_date == "") {
          isValid = false
        }
      }

      return isValid
    },

    // ====================================================================================
    // EDUCATIONS FUNCTIONS
    // educations Add
    async onAddEducations() {
      // if(!this.onValication()) return

      if(!this.$refs.form.validate() || this.onValidation() === false) return

      let talentEducations = this.profileData

      if(this.talent_educations.talent_education_institution_name) {
        talentEducations.push(this.talent_educations)
      }

      this.profileData = talentEducations

      this.$emit("save", talentEducations)

      this.talent_educations = {
        talent_education_institution_name: "",
        talent_education_major: "",
        talent_education_start_date: "",
        talent_education_end_date: "",
        talent_education_description: "",
        talent_education_is_currently_studying: true
      }

      this.showEducation = false
      // this.$refs.snackbar.open('success', 'Pendidikan berhasil ditambahkan!')

      // await this.onSaveTalentProfileResume()
    },

    // educations Edit
    async onOpenEditEducations(index) {
      let talentEducations = this.profileData[index]

      this.talent_educations = {
        talent_education_institution_name: talentEducations.talent_education_institution_name,
        talent_education_major: talentEducations.talent_education_major,
        talent_education_start_date: moment(talentEducations.talent_education_start_date).format("YYYY-MM"),
        talent_education_end_date: talentEducations.talent_education_end_date ? moment(talentEducations.talent_education_end_date).format("YYYY-MM") : "",
        talent_education_description: talentEducations.talent_education_description,
        talent_education_is_currently_studying: talentEducations.talent_education_is_currently_studying
      }

      this.state_index = index

      this.showEducation = true

      this.state_form = "edit"
    },

    // educations Update
    async onUpdateEducations() {

      if(!this.$refs.form.validate() || this.onValidation() === false) return
      
      let talentEducations = this.profileData

      talentEducations[this.state_index] = this.talent_educations

      this.profileData = talentEducations

      this.$emit("save", talentEducations)

      this.talent_educations = {
        talent_education_institution_name: "",
        talent_education_major: "",
        talent_education_start_date: "",
        talent_education_end_date: "",
        talent_education_description: "",
        talent_education_is_currently_studying: true
      }

      // this.$refs.snackbar.open('success', 'Pendidikan berhasil diubah!')

      // await this.onSaveTalentProfileResume()

      this.showEducation = false

      this.state_form = "create"
      this.state_index = 0
    },

    // educations Delete
    async onDeleteEducations(index) {
      let talentEducations = [...this.profileData]

      talentEducations.splice(index, 1)

      this.profileData = talentEducations

      this.$emit("save", talentEducations)

      // this.$refs.snackbar.open('success', 'Pendidikan berhasil dihapus!')

      // await this.onSaveTalentProfileResume()
      
      this.showEducation = false

    },
  }
}  
</script>