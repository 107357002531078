<template>
  <v-row>
    <v-col cols="12">
      <v-container fluid class="px-4">
        <!-- <v-breadcrumbs :items="breadcrumbs">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

        <v-tabs v-model="tab">
          <v-tab class="text-capitalize">Profile & Resume</v-tab>
          <v-tab class="text-capitalize">Ubah Password</v-tab>
        </v-tabs> -->

        <!-- <v-divider class="mb-4"></v-divider> -->

        <v-row>
          <v-col cols="12" md="4" lg="4">
            <AccountProfile />
          </v-col>
          <v-col cols="12" md="8" lg="8">
            <AccountResume />
          </v-col>
        </v-row>

      </v-container>
    </v-col>
  </v-row>

</template>

<script>
import { LMS_API } from "@/constants/api"

import { mapGetters } from "vuex";
import AccountProfile from "./_components/AccountProfile.vue";
import AccountResume from "./_components/AccountResume.vue";

export default {
  name: "account-setting",
  components: {
    AccountProfile,
    AccountResume,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "Dashboard",
          disabled: false,
          color: "primary",
          href: "/dashboard",
        },
        {
          text: "Profile & Resume",
          disabled: true,
          href: "#",
        },
      ],
      tab: null,

      show: false,
      show2: false,
      show3: false,
      valid: true,
      menu: false,
      date: null,
      age: "",
      nomor: "",
      imageData: "",
      nameRules: [
        (v) => !!v || "Nama Lengkap Tidak Boleh Kosong",
        // v => /^[A-Za-z ]+$/.test(v) || 'Tidak boleh ada simbol',
      ],      
      codeRules: [(v) => !!v || "Nomor Kartu Prakerja Tidak Boleh Kosong"],
      phoneRules: [
        (v) => !!v || "Nomor Tidak Boleh Kosong",
        // v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      emailRules: [
        (v) => !!v || "Email Tidak Boleh Kosong",
        v => /.+@.+\..+/.test(v) || 'E-mail tidak valid',
      ],
      dateRules: [(v) => !!v || "Tanggal Lahir Tidak Boleh Kosong"],

      validPsw: true,
      current_psw: "",
      new_psw: "",
      confirm_psw: "",
      current_rules: [
        (v) => !!v || "Password tidak boleh kosong",
        (v) => (v && v.length >= 8) || "Password minimal harus 8 karakter",
      ],
      new_rules: [
        (v) => !!v || "Password tidak boleh kosong",
        (v) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/.test(v) || 'Password harus mengandung huruf besar, huruf kecil, angka dan karakter spesial (minimal 8 karakter)',
        (v) => (v && v.length >= 8) || "Password minimal harus 8 karakter",
      ],
      confirm_rules: [
        (v) => !!v || "Konfirmasi password tidak boleh kosong",
        (v) => v === this.new_psw || "Konfirmasi password tidak sesuai dengan password baru",
        (v) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/.test(v) || 'Password harus mengandung huruf besar, huruf kecil, angka dan karakter spesial (minimal 8 karakter)',
      ],

      submiting: false,
      dialog: {
        confirm: false,
      },
      response: null,
      responsesukses: null,
      resSuccess: null,
      resFailed: null,
      changeRead: false,
      changeStatus: false,
      detail: {},
      fullname:'',
      code:'12345'
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    fullname(value) {
      this.fullname = value
        .split(" ")
        .map((val) => {
          return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
        })
        .join(" ");
    },
  },
  computed: {
    ...mapGetters(["user"]),
    
  },
  mounted() {
    this.nomor = this.user.phone;
    this.fullname=this.user.fullname;

    window.scrollTo(0, 0, { behavior: "smooth" });
  },
  created(){
    // this.code = this.user.meta?.code || '';
    
  },
  methods: {
    
    previewThumbnail: function getPreview(event) {
      const input = event.target;
      if (input.files && input.files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    save(date) {
      this.$refs.menu.save(date);
      var today = new Date();
      var birthDate = new Date(this.date);
      this.age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        this.age--;
      }
      return this.age;
    },
    changePsw() {
      if (this.user.provider == "email") {
        this.showEmail = true;
      } else {
        this.showEmail = false;
      }
    },
    saveSetting() {
      this.dialog.confirm = false;
      this.submiting = true;
      const sk = this.$refs.form.validate();
      if (sk == true) {
        this.submiting = false;
        this.changeRead = true;

        this.axios
          .put(
            `/users/v1/member/update`,
            {
              fullname: this.fullname,
              date_of_birth: this.user.date_of_birth,
              phone: this.user.phone,
              email:this.user.email,
              meta:{code:'12345'}
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            this.loading = false;

            this.$store.dispatch("get_user_sistem");
            if (response.status == 200) {
              this.loading = false;
              this.responsesukses = "Data Anda berhasil disimpan";
              this.getDetail();
              setTimeout(() => {
                this.responsesukses = null;
                this.$router.push({ name: "my-class" });
              }, 3000);
              // window.location.reload(true);
            }
          });
        // .catch(error => {
        //   // Code here
        // })
      } else {
        this.submiting = false;
        // this.user.email = "";
        // this.user.fullname = "";
        // this.user.date_of_birth = null;
        // this.user. = "";

        this.response = "Silahkan cek kembali data Anda";
        setTimeout(() => {
          this.response = null;
        }, 3000);
      }
    },
    validation() {

      if(this.new_psw == '') {
        this.resFailed = "Kolom password tidak boleh kosong";
        setTimeout(() => {
          this.resFailed=null;
        },3000);
      }else {
        if(this.new_psw.length < 8 || (this.confirm_psw.length < 8) ) {
          this.resFailed = "Password kurang dari 8 character";
          setTimeout(() => {
            this.resFailed=null;
          },3000);
        }

        // check if the new password must contain at least one uppercase letter, one lowercase letter, one number, and one special character

        if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]$/.test(this.new_psw)) {
          this.resFailed = "Password harus mengandung huruf besar, huruf kecil, angka dan karakter spesial";
          setTimeout(() => {
            this.resFailed=null;
          },3000);
        }
        
      }

      if(this.confirm_psw == '') {
        this.resFailed = "Kolom konfirmasi password tidak boleh kosong";
        setTimeout(() => {
          this.resFailed=null;
        },3000);
      }else {
        if(this.confirm_psw != this.new_psw) {
          this.resFailed = "Konfirmasi password tidak sama dengan password baru";
          setTimeout(() => {
            this.resFailed=null;
          },3000);
        }
      }
      
    },
    changePassword(){
      if (this.$refs.formChange.validate()) {
          this.submiting= true;
          let current_password=this.current_psw;
          let new_password=this.new_psw;
          let conf_new_password=this.confirm_psw;

          this.axios
          .put(`${LMS_API.USER.CHANGE_PASSWORD}`, {current_password,new_password,conf_new_password},{
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then((response) => {
            let res = response.data;
            this.loading = false;
            if (res.status == 200) {
              this.loading = false;
              this.resSuccess = "Password Anda berhasil diubah ";
              setTimeout(() => {
                this.resSuccess=null;
              },3000);
              let email = this.user.email;
              let password = this.new_psw;
              // console.log('password',password)
              this.$store
                .dispatch("login", { email, password })
                .then(() => {
                  this.submiting = false;
                  if (localStorage.getItem("ytc_lms_redirect") != null) {
                    this.$router.push(localStorage.getItem("ytc_lms_redirect"));
                    localStorage.removeItem("ytc_lms_redirect");
                  }
                })

              this.$refs.formChange.reset();
            }
        })
           .catch((error) => {
            this.submiting = false;
            this.$refs.form.reset();
            let errorData = error.response.data;

            this.resFailed = errorData.message;

            setTimeout(() => {
              this.resFailed = null;
            }, 2000);
          });
      }
      
    }
  },
};
</script>

<style>
.accent-blue {
  background-color: #d4e5fe !important;
  /*opacity: 0.4;*/
  border-radius: 4px;
}
.photo-upload {
  height: auto;
  margin: 10px;
  position: relative;
  text-align: center;
  width: 100%;
}
.input-file {
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0.1px;
  z-index: -1;
}
.input-file:focus + label {
  border-color: #e5e5e5;
}
.input-file + label {
  background: transparent;
  border: 2px solid #e5e5e5;
  border-radius: 50%;
  color: #5b5b5b;
  display: inline-block;
  font-size: 12pt;
  font-weight: 500;
  height: 100px;
  line-height: 100px;
  text-align: center;
  width: 100px;
}
.input-file + label:hover,
.input-file + label:focus {
  border-color: #e5e5e5;
  cursor: pointer;
}
.image-preview {
  border-radius: 50%;
  height: 100px;
  left: -4px;
  margin: 0 auto;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
}
.image-preview-change {
  border-radius: 50%;
  height: 100px;
  left: -4px;
  margin: 0 auto;
  pointer-events: none;
  position: relative;
  right: 0;
  top: 0;
  width: 100px;
  border: 2px solid #e5e5e5;
}
.image-preview__img {
  border: 2px solid #e5e5e5;
  border-radius: 50%;
  height: 100px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  width: 100px;
}

</style>