const PREFIX = 'member';
const PREFIX_ADMIN = 'admin';
const LMS = `${PREFIX}/lms`;
const PUBLIC_LMS = 'public/lms';
const JOB_PORTAL = `${PREFIX}/jobportal`;

const LMS_API = {
  AUTH : {
    LOGIN: `${PREFIX}/auth/login`,
    REGISTER: `${PREFIX}/auth/register`,
    LOGOUT: `${LMS}/users/v1/member/auth/logout`,
    SET_PASSWORD: `${PREFIX}/auth/reset-password`,
    FORGOT_PASSWORD: `${PREFIX}/auth/forgot-password`,
    VERIFY_TOKEN: `${PREFIX}/auth/verify-token`,
    RESEND_TOKEN: (email) => `${PREFIX}/auth/resend-token/${email}`,
    // SEND_OTP: `${LMS}/users/v1/member/auth/send-otp`,
    // VERIFY_OTP: `${LMS}/users/v1/member/auth/verify-otp`,
    // VERIFY_TOKEN: `${PREFIX}/auth/verify-token`,
  },
  COMPANY: {
    GET: (access_key) => `${PUBLIC_LMS}/company/setting/${access_key}`,
  },
  USER: {
    GET_ME: `${LMS}/users/v1/member/detail`,
    UPDATE: `${LMS}/users/v1/member/update`,
    CHANGE_PASSWORD: `${LMS}/users/v1/member/change-password`,
  },

  TOPIC: {
    DETAIL: (id, topic_id) => `${LMS}/users/v1/myprogram/detail_topic/${id}/${topic_id}`
  },

  ACTIVITY: {
    DETAIL: (id, topic_id, act_id) => `${LMS}/users/v1/myprogram/detail_activity/${id}/${topic_id}/${act_id}`,

    MEETING_CLICKED: `${LMS}/users/v1/member/activity/update_meeting_is_clicked`,
    HAS_DONE: `${LMS}/users/v1/member/activity/update-has-done`,

    UNLOCK: `${LMS}/users/v1/member/activity/unlock`,

    GAMEPLAY: {
      DETAIL_MULTIPLE: `${LMS}/gameplay/v1/public/detail-multiple`,

      ANSWER: `${LMS}/gameplay/v1/answer`,
      DETAIL_ANSWER: (license, topic_id, act_id) => `${LMS}/gameplay/v1/detail-answer/${license}/${topic_id}/${act_id}`
    },

    UPLOAD_ACTIVITY: `${LMS}/users/v1/myprogram/upload_activity`,

    QUESTION: {
      LIST: (id, topic_id, act_id) => `${LMS}/users/v1/myprogram/question_list/${id}/${topic_id}/${act_id}`,
      ANSWER: `${LMS}/users/v1/myprogram/answer`,
      ANSWER_LIST: (id, topic_id, act_id) => `${LMS}/users/v1/myprogram/answer_list/${id}/${topic_id}/${act_id}`,

      DETAIL: (question_id) => `${LMS}/users/v1/myprogram/question/detail/${question_id}`,

      BANK: {
        DETAIL_MULTIPLE: `${LMS}/bank/v1/question/public/detail_multiple`
      },
    },
  },

  LIVENESS: {
    CREATE: `${LMS}/users/v1/liveness/create`
  },

  PROGRAM: {
    LIST: `${LMS}/users/v1/myprogram/list`,
    DETAIL: (id) => `${LMS}/users/v1/myprogram/detail/${id}`,

    DETAIL_PUBLIC: (id) => `${PUBLIC_LMS}/program/detail/${id}`,

    SET_START_TEST: `${LMS}/users/v1/myprogram/set-start-test-activity`,

    LIST_REVIEW: `${LMS}/program_review/v1/list`,
    DETAIL_REVIEW: `${LMS}/program_review/v1/detail`,
    CREATE_REVIEW: `${LMS}/program_review/v1/create`,

    CREATE_LIVENESS: `${LMS}/users/v1/myprogram/create-liveness`,
    CHECK_CALLBACK: `${LMS}/users/v1/member/check-callback`,

    REDEEM_CODE: `${LMS}/users/v1/myprogram/redeem`,
    REDEEM_PRAKERJA: `${LMS}/users/v1/myprogram/redeem-prakerja`,

    PRAKERJA_CALLBACK: (state, endcodingURL) => `${LMS}/users/v1/myprogram/check-status-prakerja/${state}${endcodingURL}`,

    UPDATE_IS_COMPLETE: `${LMS}/users/v1/member/activity/update_is_complete`,

    FACE_CAPTURE: `${LMS}/users/v1/myprogram/face_capture`,

    RE_UPLOAD_EPM: `${LMS}/users/v1/myprogram/re-upload`,

    PUBLIC: {
      DETAIL: (id) => `${LMS}/users/v1/public/program/detail/${id}`
    },

    SUB_TOTAL: {
      PROGRAM_ACTIVE: `${LMS}/users/v1/member/total-program-active`,
      PROGRAM_COMPLETED: `${LMS}/users/v1/member/total-program-completed`,
      PROGRAM_CERTIFICATE: `${LMS}/users/v1/member/total-certificate`
    },

    VIOLATION_REPORT: `${LMS}/users/v1/myprogram/violation/report`
  },

  MEMBER: {
    ORDER: {
      UPDATE_SETTING: (license) => `${LMS}/users/v1/member/order/update/setting/${license}`,

      UPDATE_START_TEST_MEMBER: (id) => `${LMS}/users/v1/member/order/update-start-test/${id}`,

      UPDATE_CERTIFICATE: `${LMS}/users/v1/member/order/update-sertificate`,
      // DETAIL_CERTIFICATE: (license) => `${LMS_PUBLIC}/users/v1/public/order/detail/${license}`,
      DETAIL_CERTIFICATE: (license) => `${PUBLIC_LMS}/order/detail/${license}`,

      DETAIL_ACTIVITY_SCORE: (license) => `${LMS}/users/v1/member/detail-activity-score/${license}`,

      LIST_CERTIF: `${LMS}/users/v1/member/order/list-sertificate`,
      DETAIL_FULL: (id) => `${LMS}/users/v1/member/order/detail-full/${id}`
    }
  },

  MEDIA: {
    DETAIL: (id) => `${LMS}/storage/v1/media/user/detail/${id}`
  },

  DISCUSSION: {
    LIST: `${LMS}/discussion/v1/list`,
    PUBLIC_CREATE: `${LMS}/discussion/v1/create`,
    GET_ROOM_ID: `${LMS}/discussion/v1/discussion-private/get-room-id`,
    GET_ROOM_DETAIL: (roomId) => `${LMS}/discussion/v1/discussion-private/detail-room/${roomId}`,

    SEND_MESSAGE: (roomId) => `${LMS}/discussion/v1/discussion-private/room/${roomId}/send-message`
  },
  
  NOTIFICATION: {
    GET: `${LMS}/notification/v1/member/list`,
    SUBSCRIBE: `${LMS}/notification/v1/member/subscribe`,
    READ: (id) => `${LMS}/notification/v1/member/read/${id}`,
    PRIVATE: {
      GET_LIST: `${LMS}/discussion/v1/discussion-private/list`,
      GET_TOTAL_UNREAD: `${LMS}/discussion/v1/discussion-private/get-total-unread`,
      READ_ALL: `${LMS}/discussion/v1/discussion-private/read-all`,
      READ: (id) => `${LMS}/discussion/v1/discussion-private/read/${id}`,
    },
  },

  SUBSCRIBE: {
    CURRENT_PACKAGE: `${LMS}/subscribe/v1/current_package`,
    HISTORY: `${LMS}/subscribe/v1/history/list`
  }
}

const JOB_PORTAL_API = {
  AUTH : {
    LOGIN: `${PREFIX_ADMIN}/job-portal/auth/login`,
    LOGOUT: `${JOB_PORTAL}/users/v1/member/auth/logout`,
    REGISTER: `${JOB_PORTAL}/users/v1/member/auth/register`,
    SEND_OTP: `${JOB_PORTAL}/users/v1/member/auth/send-otp`,
    VERIFY_OTP: `${JOB_PORTAL}/users/v1/member/auth/verify-otp`,
  },
  COMPANY: {
    LIST: `${JOB_PORTAL}/server/api/v1/company/paginate`,
    DETAIL: (companyId) => `${JOB_PORTAL}/server/api/v1/company/${companyId}`,
  },
  USER: {
    GET_ME: `${JOB_PORTAL}/server/api/v1/talent/me`,
    UPDATE: `${JOB_PORTAL}/server/api/v1/talent/update`,
    UPLOAD_PHOTO: `${JOB_PORTAL}/server/api/v1/talent/upload-photo`,
    OPEN_TO_WORK: `${JOB_PORTAL}/server/api/v1/talent/update-open-to-work`,
  },
  LOCATION: {
    PROVINCE: `${JOB_PORTAL}/server/api/v1/prepare/administrative/province/label-values`,
    DISTRICT: (provinceCode) => `${JOB_PORTAL}/server/api/v1/prepare/administrative/district/label-values?provinceCode=${provinceCode}`,
    SUB_DISTRICT: (districtCode) => `${JOB_PORTAL}/server/api/v1/prepare/administrative/sub-district/label-values?districtCode=${districtCode}`,
    VILLAGE: (subDistrictCode) => `${JOB_PORTAL}/server/api/v1/prepare/administrative/village/label-values?subDistrictCode=${subDistrictCode}`,
  },
  LOWONGAN: {
    GET_LIST: `${JOB_PORTAL}/server/api/v1/talent-apply-job/talent-apply-job-list`,
    DETAIL: `${JOB_PORTAL}/users/v1/member/detail`,
    CREATE: (jobId) => `${JOB_PORTAL}/server/api/v1/talent-apply-job/talent-apply/${jobId}`,
    BOOKMARK: {
      GET: `${JOB_PORTAL}/server/api/v1/talent-bookmark-job/me`,
      CREATE: (jobId) =>  `${JOB_PORTAL}/server/api/v1/talent-bookmark-job/save/${jobId}`,
      DELETE: (jobId) =>  `${JOB_PORTAL}/server/api/v1/talent-bookmark-job/remove/${jobId}`
    }
  },
  JOBS: {
    GET_LIST: `${JOB_PORTAL}/server/api/v1/job/paginate`,
    GET_RECOMMENDED: `${JOB_PORTAL}/server/api/v1/job/recomended`,
    DETAIL: (jobId) => `${JOB_PORTAL}/server/api/v1/job/${jobId}`,
    JOB_BY_COMPANY: `${JOB_PORTAL}/server/api/v1/job/my-company/paginate`
  },
  SKILLS: {
    GET_LIST: (search) => `${JOB_PORTAL}/server/api/v1/master-skill/paginate?search=${search}&limit=1000`,
  },
  MASTER_DATA: {
    GET_LIST: (type) => `${JOB_PORTAL}/server/api/v1/master-${type}/label-values`,
    GET_LIST_WITH_ID: (type, id) => `${JOB_PORTAL}/server/api/v1/master-${type}/label-values/${id}`,
  },
  CERTIFICATE: {
    GET_LIST: `${JOB_PORTAL}/server/api/v1/talent-certificate/paginate`,
    DETAIL: (certificateId) => `${JOB_PORTAL}/server/api/v1/talent-certificate/${certificateId}`,
    CREATE: (talentId) => `${JOB_PORTAL}/server/api/v1/talent-certificate/${talentId}`,
    UPDATE: (certificateId) => `${JOB_PORTAL}/server/api/v1/talent-certificate/${certificateId}`,
    DELETE: (certificateId) => `${JOB_PORTAL}/server/api/v1/talent-certificate/${certificateId}`,
  }
}

export { LMS_API, JOB_PORTAL_API }