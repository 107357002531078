<template>
  <v-row>
    <v-col cols="12">
      <v-container>

        <v-row>
        <!-- <v-col  cols="12" md="4" lg="4">
          <v-autocomplete
            dense
            outlined
            :items="provinces"
            item-text="label"
            item-value="value"
            hide-details
            v-model="province_filter"
            clearable
            @click:clear="province_filter = ''; fetch()"
            class="rounded-lg mb-5"
            placeholder="Pilih Lokasi Pekerjaan">
          </v-autocomplete>
        </v-col> -->
        <v-col  cols="12" md="12" lg="12">
          <div class="d-flex justify-center align-center mb-5" style="gap: 5px">
            <v-text-field
              dense
              outlined
              hide-details
              class="rounded-lg"
              prepend-inner-icon="ri-search-line"
              v-model="searching"
              clearable
              @click:clear="searching = ''; fetch()"
              placeholder="Cari Perusahaan">
            </v-text-field>
            <v-btn
              color="primary"
              class="rounded-lg"
              depressed
              fab
              @click="fetch()"
              small>
              <v-icon size="20">ri-search-line</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <p class="fs-16 font-weight-bold text-gray-900">Daftar Perusahaan</p>

      <v-row>
        <!-- <v-col cols="12" md="4" lg="4">

          <v-card 
            class="rounded-lg px-4"
            outlined>
            <v-card-text class="black--text">

              <div>
                <p class="fs-16 font-weight-bold text-gray-900">Bidang Perusahaan</p>
                <v-checkbox
                  v-for="(company_field, index) in company_fields"
                  :key="index"
                  hide-details
                  dense
                  v-model="selected.company_field"
                  :label="company_field.label"
                  :value="company_field.value">
                </v-checkbox>
              </div>

              <div class="mt-3">
                <p class="fs-16 font-weight-bold text-gray-900">Tipe Perusahaan</p>
                <v-checkbox
                  v-for="(company_type, index) in company_types"
                  :key="index"
                  hide-details
                  dense
                  v-model="selected.company_type"
                  :label="company_type.label"
                  :value="company_type.value">
                </v-checkbox>
              </div>

              <div class="mt-3">
                <p class="fs-16 font-weight-bold text-gray-900">Tipe Pekerjaan</p>
                <v-checkbox
                  v-for="(work_type, index) in ['Internship', 'Freelance', 'Part Time', 'Full Time', 'Contract']"
                  :key="index"
                  hide-details
                  dense
                  v-model="selected.work_type"
                  :label="work_type"
                  :value="work_type">
                </v-checkbox>
              </div>

            </v-card-text>
          </v-card>

        </v-col> -->

        <v-col cols="12" md="12" lg="12">
          <v-row>
            <v-col cols="12" md="3" lg="3" v-for="(company, i) in companys" :key="i">
              <v-card 
                class="rounded-lg px-4"
                style="height: 150px;"
                outlined
                @click="goToDetail(company)">
                <v-card-text class="px-0 black--text">
                  <div class="d-flex mb-4" :class="$vuetify.breakpoint.name == 'xs' ? 'flex-column justify-start align-start ' : 'justify-space-between align-center '" style="width: 100%;">
                    <div class="d-flex justify-center align-center" style="gap: 5px">
                      <img 
                        v-if="company.company_logo_url"
                        :src="company.company_logo_url" 
                        class="rounded-circle"
                        alt="logo"
                        width="50">
                      <v-avatar 
                        color="primary"
                        class="fs-16 white--text font-weight-medium"
                        size="45"
                        v-else>
                        {{ initialName(company.company_name) }}
                      </v-avatar>
                      <div class="d-flex flex-column">
                        <span class="font-weight-medium fs-16 text-gray-900">{{ company.company_name }}</span>
                        <span class="font-weight-medium fs-12 text-gray-400" style="color: #9CA3AF;">Karyawan : {{ company.company_number_of_employee ? `${company.company_number_of_employee.label} Orang` : "-" }}</span>
                      </div>
                    </div>
                    <v-chip small color="#F3F4F6" label v-if="$vuetify.breakpoint.name != 'xs'" class="fs-12 font-weight-medium text-gray-900">
                      {{ convertCreatedAt(company.createdAt) }}
                    </v-chip>
                  </div>
                  
                  <v-chip small color="#F3F4F6" label v-if="$vuetify.breakpoint.name == 'xs'" class="fs-12 font-weight-medium text-gray-900">
                    {{ convertCreatedAt(company.createdAt) }}
                  </v-chip>

                  <v-row no-gutters>
                    <v-col cols="12" class="d-flex align-center mb-1">
                      <v-icon class="mr-2" color="#1F2A37" size="16">mdi-information-slab-circle-outline</v-icon>
                      <div class="fs-12 font-weight-medium text-gray-900">{{ company.company_business_industry ? company.company_business_industry.label : "-" }}</div>
                    </v-col>
                    <v-col cols="12" class="d-flex align-center mb-1">
                      <v-icon class="mr-2" color="#1F2A37" size="16">ri-map-pin-line</v-icon>
                      <div class="fs-12 font-weight-medium text-gray-900">{{ company.company_address.address_province ? company.company_address.address_province.province_name : "-" }}</div>
                    </v-col>
                  </v-row>

                </v-card-text> 
              </v-card>
            </v-col>
          </v-row>


        </v-col>
      </v-row>

      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { JOB_PORTAL_API } from "@/constants/api";

import moment from 'moment';

export default {
  name: "CompanyList",
  data: () => ({
    selected: {
      company_field: "",
      company_type: "",
      work_type: "",
      work_place: "",
    },

    searching: "",
    province_filter: "",
    provinces: [],

    company_types: [
      {
        label: "Nasional",
        value: "Nasional",
      },
      {
        label: "Internasional",
        value: "Internasional",
      }
    ],
    company_fields: [
      {
        label: "Jasa TI dan Konsultan TI",
        value: "11",
        description: "Jasa TI dan Konsultan TI"
      },
      {
        label: "Software House",
        value: "12",
        description: "Software House"
      }
    ],
    companys: [],
  }),
  watch: {
    province_filter(value) {
      this.province_filter = value
      this.fetch()
    }
  },
  computed: {
    convertCreatedAt() {
      return (date) => {
        return moment(date).locale("id").fromNow();
      };
    },
    initialName() {
      return (company_name) => {
        let firstAlias = "";
        let secondAlias = "";
          let name = company_name;
          let splitname = name.split(" ");
  
          if (splitname[0]) {
            firstAlias = splitname[0].substring(0, 1);
          }
  
          if (splitname[1]) {
            secondAlias = splitname[1].substring(0, 1);
          }
        return firstAlias + secondAlias;
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0, { behavior: 'smooth' });

    this.province();
    this.fetch();
  },
  methods: {
    async province() {
      await this.axios.get(`${JOB_PORTAL_API.LOCATION.PROVINCE}`)
      .then((response) => {
        this.provinces = response.data
      })
    },

    async fetch() {

      let params = {
        page: 1,
        limit: 10,
      }

      if(this.searching) {
        params.search = this.searching
      }

      if(this.province_filter) {
        params['filter.province_code'] = this.province_filter
      }

      this.isLoading = true;
      await this.axios.get(`${JOB_PORTAL_API.COMPANY.LIST}`, { params }).then((response) => {
        this.companys = response.data.data;
        this.isLoading = false;
      }).catch((error) => {
        this.isLoading = false;
        this.$refs.snackbar.open(`error`, error.response.data.message);
      });
    
    },

    goToDetail(company) {
      this.$router.push({
        name: 'company-detail',
        params: {
          id: company.company_id
        }
      })
    }
  }
}
</script>