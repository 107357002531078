<template>
  <v-row>
    <v-col cols="12">

      <Snackbar ref="snackbar" />

      <!-- ===== Tentang Saya ===== -->
      <AboutMe 
        :profile-data="form.talent_about_me"
        @save="onSaveTalentAboutMe"
      />

      <!-- ===== Pengalaman Kerja ===== -->
      <Experiences 
        :profile-data="form.talent_work_experiences"
        @save="onSaveTalentWorkExperiences"
      />

      <!-- ===== Pendidikan ===== -->
      <Educations 
        :profile-data="form.talent_educations"
        @save="onSaveTalentEduExperiences"
      />

      <!-- ===== Sertifikat ===== -->
      <Certificates
        :talent_id="user_sistem.talent_id"
      />
      
      <!-- ===== Pengalaman Organisasi ===== -->
      <Organizations 
        :profile-data="form.talent_organization_experiences"
        @save="onSaveTalentOrgExperiences"
      />
      
      <!-- ===== Skill Pekerjaan ===== -->
      <Skills
        :profile-data="form.talent_skills"
        @save="onSaveTalentSkills"
      />

      <!-- ===== Bahasa ===== -->
      <Languages
        :profile-data="talent_language"
        @save="onSaveLanguage"
      />
      
      <!-- ===== Minat dan Preferensi Pekerjaan  ===== -->
      <Preferences 
        :profile-data="form.talent_preference"
        @save="onSaveTalentPreference"
      />

      <!-- ===== Social Media ===== -->
      <SocialMedia 
        :profile-data="form.talent_social_medias"
        @save="onSaveTalentSocialMedia"
      />

    </v-col>
  </v-row>
</template> 

<script>
import { JOB_PORTAL_API } from "@/constants/api";

import { mapGetters } from "vuex";
import Snackbar from "@/components/Snackbar";

// COMPONENTS FORM RESUME
import Experiences from "./_components_form_resume/experiences.vue";
import Educations from "./_components_form_resume/educations.vue";
import Certificates from "./_components_form_resume/certificates.vue";
import Organizations from "./_components_form_resume/organizations.vue";
import Skills from "./_components_form_resume/skills.vue";
import Languages from "./_components_form_resume/languages.vue";
import Preferences from "./_components_form_resume/preferences.vue";
import SocialMedia from "./_components_form_resume/social_media.vue";
import AboutMe from "./_components_form_resume/about_me.vue";

export default {
  name: "AccountResume",
  components: {
    Snackbar,
    
    AboutMe,
    Experiences,
    Educations,
    Certificates,
    Organizations,
    Skills,
    Languages,
    Preferences,
    SocialMedia
  },
  data: () => ({ 

    talent_language: [],

    form: {
      talent_id: "",
      talent_social_medias: [
        {
          talent_social_media_name: "",
          talent_social_media_url: "",
        }
      ],
      talent_first_name: "",
      talent_last_name: "",
      talent_phone: "",
      talent_birth_date: "",
      talent_gender: "",
      talent_about_me: "",
      talent_address: {
        address_address_line: "",
        address_address_type: "",
        address_province_code: "",
        address_district_code: "",
        address_sub_district_code: "",
        address_village_code: "",
        address_postal_code: "",
        address_rt: "",
        address_rw: "",
      },
      talent_work_experiences: [],
      talent_educations: [],
      talent_skills: [],
      talent_preference: {
        talent_preference_salary: "",
        talent_preference_province: "",
        talent_preference_location: "",
        talent_preference_type_of_workplace: "",
        talent_preference_job_categories: [
          {
            talent_preference_job_category_name: "",
            talent_preference_job_category_job_titles: [
              {
                talent_preference_job_title_job_title: "",
              }
            ]
          }
        ]
      },
      talent_organization_experiences: [],
      talent_language: "",
    }
  }),
  computed: {
    ...mapGetters(["user_sistem"]),
  },
  mounted() {
    this.setForm()
  },
  methods: {

    async setForm() {
      await this.axios.get(`${JOB_PORTAL_API.USER.GET_ME}`)
      .then((response => {
        let res = response.data

        this.form = {
          talent_id: res.talent_id,
          talent_social_medias: res.talent_social_medias,
          talent_first_name: res.talent_first_name,
          talent_last_name: res.talent_last_name,
          talent_phone: res.talent_phone,
          talent_birth_date: res.talent_birth_date,
          talent_about_me: res.talent_about_me,
          talent_address: res.talent_address,
          talent_work_experiences: res.talent_work_experiences || [],
          talent_educations: res.talent_educations,
          talent_skills: res.talent_skills,
          // talent_preference: res.talent_preference,
          talent_organization_experiences: res.talent_organization_experiences,
          talent_preference: {
            talent_preference_salary: res.talent_preference.talent_preference_salary,
            talent_preference_location: res.talent_preference.talent_preference_location,
            talent_preference_type_of_workplace: res.talent_preference.talent_preference_type_of_workplace,
            talent_preference_job_categories: res.talent_preference.talent_preference_job_categories
          },
          talent_language: res.talent_language
        }

        this.talent_language = res.talent_language.length > 0 ? res.talent_language.split(",") : []

        
        // if(res.talent_skills.length > 0) {
        //   res.talent_skills.forEach((skill) => {
        //     this.skillsSelected.push(skill.talent_skill_name)
        //   })
        // }

      })).catch((error) => {
        this.$refs.snackbar.open('error', error.response.data.message)
      })
    },

    async onSaveTalentAboutMe(aboutMe) {
      this.form.talent_about_me = aboutMe

      await this.onSaveTalentProfileResume()
    },

    async onSaveTalentWorkExperiences(workExperiences) {
      this.form.talent_work_experiences = workExperiences
      
      await this.onSaveTalentProfileResume()
      
    },

    async onSaveTalentEduExperiences(eduExperiences) {
      this.form.talent_educations = eduExperiences
      
      await this.onSaveTalentProfileResume()
      
    },

    // ====================================================================================
    // CERTIFICATE EXPERIENCES FUNCTIONS

    // ====================================================================================
    // ORGANIZATION EXPERIENCES FUNCTIONS
    async onSaveTalentOrgExperiences(orgExperiences) {
      this.form.talent_organization_experiences = orgExperiences
      
      await this.onSaveTalentProfileResume()
      
    },

    // ====================================================================================
    // SKILLS FUNCTIONS

    async onSaveTalentSkills(skills) {
      
      this.form.talent_skills = skills

      // this.$refs.snackbar.open('success', 'Skill berhasil disimpan!')

      await this.onSaveTalentProfileResume()
    },

    // ====================================================================================
    // LANGUAGE FUNCTIONS
    async onSaveLanguage(language) {
      this.form.talent_language = language
      
      await this.onSaveTalentProfileResume()
    },
    // ====================================================================================
    // INTEREST AND PREFERENCE FUNCTIONS
    async onSaveTalentPreference(preference) {
      this.form.talent_preference = preference

      await this.onSaveTalentProfileResume()
    },

    // ====================================================================================
    // SOCIAL MEDIA FUNCTIONS
    async onSaveTalentSocialMedia(social_medias){
      this.form.talent_social_medias = social_medias

      await this.onSaveTalentProfileResume()
    },

    // ON SAVE TALENT PROFILE RESUME
    async onSaveTalentProfileResume () {

      let formData = {
        ...this.form
      }
      // console.log("🚀 ~ onSaveTalentProfileResume ~ formData:", formData)

      await this.axios.put(`${JOB_PORTAL_API.USER.UPDATE}`, formData)
      .then((response => {

        localStorage.setItem("sistem_auth", JSON.stringify(response.data));
        this.$store.dispatch("get_user_sistem");

        this.setForm()

        this.$refs.snackbar.open('success', 'Data diri berhasil disimpan!')
      })).catch((error) => {
        this.$refs.snackbar.open('error', error.response.data.message)
      })
    },
  }
};
</script>