<template>
  <v-form
    class="mt-6"
    @submit.prevent="submit"
    lazy-validation
    ref="form"
    v-model="valid">

    <div>
      <div class="my-2 font-weight-medium">Email</div>
      <v-text-field
        v-model="form.email"
        placeholder="Input Email"
        :rules="emailRules"
        class="rounded-lg"
        type="email"
        dense
        required
        outlined
        hide-details="auto">
      </v-text-field>
    </div>

    <div>
      <div class="my-2 font-weight-medium">Password</div>
      <v-text-field
        v-model="form.password"
        placeholder="Input Password"
        :rules="passwordRules"
        class="rounded-lg"
        dense
        required
        outlined
        :append-icon="showPassword ? 'ri-eye-off-line' : 'ri-eye-line'"
        @click:append="showPassword = !showPassword"
        :type="showPassword ? 'text' : 'password'"
        hide-details="auto">
      </v-text-field>
      <router-link to="/auth/forgot-password">
        <div class="my-2 text-right font-weight-medium body-2">Forgot Password ?</div>
      </router-link>
    </div>

    <v-alert
      v-if="response.message"
      dense
      text
      class="mt-3"
      :type="response.type"
      v-html="response.message">
    </v-alert>

    <v-btn
      class="mt-8 rounded-lg"
      type="submit"
      color="primary"
      block
      small
      depressed
      :loading="process.run"
      :disabled="process.run">
      Login
    </v-btn>

    <div class="mt-4 text-center fs-12" v-if="typeForm == 'company'">
      <div class="my-2 font-weight-medium text-gray-500">Belum memiliki akun Perusahaan ? 
        <a href="https://company.joinsistem.com/register" target="_blank" class="primary--text">Daftar disini</a>
      </div>
    </div>

    <!-- <div class="overline fs-12 text-center my-2" v-if="$vuetify.breakpoint.smAndUp">
      OR
    </div>

    <v-btn
      v-if="$vuetify.breakpoint.smAndUp"
      class="rounded-lg"
      color="#1F2A37"
      style="border: 1px solid #E5E7EB"
      block
      small
      outlined
      depressed
      @click="() => { typeForm == 'user' ? $router.push({ name: 'registration' }) : $router.push({ name: 'registration-company' }) }"
      :loading="process.run"
      :disabled="process.run">
      Registrasi
    </v-btn> -->

  </v-form>
</template>

<script>
import { JOB_PORTAL_API } from "@/constants/api";

import { mapGetters } from "vuex";
import firebase from "@/firebase";

export default {
  name: "auth-login-user",
  props: {
    typeForm: {
      type: String,
      default: "user"
    }
  },
  data() {
    return {
      valid: true,
      form: {
        email: "",
        password: "",
        token: ""
      },
      showPassword: false,
      process: {
        run: false
      },
      emailRules: [
        (v) => !!v || "Email tidak boleh kosong",
        (v) => /.+@.+\..+/.test(v) || "Email tidak valid",
      ],
      passwordRules: [
        (v) => !!v || "Password tidak boleh kosong",
        (v) => (v && v.length >= 8) || "Password harus minimal 8 karakter",
      ],

      response: {
        message: "",
        type: "",
      }
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["user_sistem"]),
  },
  mounted() {
    this.getToken();
  },
  methods: {
    async getToken() {
      this.isLoading = true;
      if (firebase.messaging.isSupported()) {
        if (!this.form.token) {
          // firebase messaging request permission
          firebase.messaging().requestPermission()
          .then(async () => {
            console.log('Notification permission granted.');

            // get user agent for detect browser if safari then set isSafari to true
            let userAgent = navigator.userAgent || navigator.vendor || window.opera;
            if (/iPad|iPhone|iPod/.test(userAgent) || !window.MSStream || navigator.userAgent.indexOf("Safari") > -1) {
              this.isLoading = false;
            }
            
            try {
              const token = await firebase.messaging().getToken();
              // console.log("🚀 ~ file: login.vue:203 ~ .then ~ token:", token)
              if (token) {
                this.form.token = token;
                localStorage.setItem('fcm_token', token);
                setTimeout(() => {
                  this.isLoading = false;
                }, 1000);
              } else {
                this.isLoading = false;
                console.log('No registration token available. Request permission to generate one.');
              }
            } catch (error) {
              this.isLoading = false;
              console.log('An error occurred while retrieving token. ', error);
            }
          })
          .catch((err) => {
            this.isLoading = false;
            console.log('Unable to get permission to notify.', err);
          });
        }
      }else {
        if (!this.form.token) {
          console.log('Firebase messaging is not supported.');

          if(navigator.permissions && navigator.permissions.query) {
            navigator.permissions.query({ name: 'notifications' })
            .then((permission) => {
              if (permission.state === 'granted') {
                this.isLoading = true;
                // If push notification is allowed then get token from firebase messaging
                try {
                  firebase.messaging().getToken()
                  .then((token) => {
                    if (token) {
                      this.form.token = token;
                      localStorage.setItem('fcm_token', token);
                      this.isLoading = false;
                    } else {
                      this.isLoading = false;
                      console.log('No registration token available. Request permission to generate one.');
                    }
                  })
                } catch (error) {
                  this.isLoading = false;
                  console.log('An error occurred while retrieving token. ', error);
                }


              } else if (permission.state === 'denied') {
                this.isLoading = false;
                console.log('User has blocked notifications.');
              } else if (permission.state === 'prompt') {
                this.isLoading = false;
                console.log('User has not yet been asked for notification permission');
              }
            }).catch((err) => {
              this.isLoading = false;
              console.log('An error occurred while retrieving token. ', err);
            })
          }else if(Notification.requestPermission) {

            try {
              Promise.resolve(Notification.requestPermission().then(async (permission) => {
  
                if(permission === 'granted') {
                  let userAgent = navigator.userAgent || navigator.vendor || window.opera;
                  if (/iPad|iPhone|iPod/.test(userAgent) || !window.MSStream || navigator.userAgent.indexOf("Safari") > -1) {
                    this.isLoading = false;
                  }
                  
                  try {
                    const token = await firebase.messaging().getToken();
                    // console.log("🚀 ~ file: login.vue:203 ~ .then ~ token:", token)
                    if (token) {
                      this.form.token = token;
                      localStorage.setItem('fcm_token', token);
                      setTimeout(() => {
                        this.isLoading = false;
                      }, 1000);
                    } else {
                      this.isLoading = false;
                      console.log('No registration token available. Request permission to generate one.');
                    }
                  } catch (error) {
                    this.isLoading = false;
                    console.log('An error occurred while retrieving token. ', error);
                  }
                  
                } else {
                  this.isLoading = false;
                  console.log('User has blocked notifications.');
                }
              }));
              
            } catch (error) {
              this.isLoading = false;
              console.log('An error occurred while retrieving token. ', error);
            }
          }
          
        }

      }

    },
    async submit() {
      this.process.run = true;
      if (this.$refs.form.validate()) {
        let email = this.form.email;
        let password = this.form.password;
        let token = this.form.token;

        if(this.typeForm == 'user') {
          this.$store
            .dispatch("login", { email, password, token })
            .then(async () => {
              this.process.run = false;
              await this.$store.dispatch('get_user')
              await this.$store.dispatch('get_user_sistem')
              this.$root.$emit("setDialog",true);
              this.redirectAfterLogin();
            })
            .catch((error) => {
              this.process.run = false;
              // this.$refs.form.reset();
              this.response.type = "error"
              this.response.message = error.response.data.message;
            });
        }else {
          let access_key = process.env.VUE_APP_ACCESS_KEY;
          await this.axios.post(`${JOB_PORTAL_API.AUTH.LOGIN}`, { email, password, access_key }).then(async (response) => {
            this.process.run = false;
            let res = response.data;
            // console.log("🚀 ~ file: AuthLoginUser.vue ~ line 202 ~ submit ~ res", res);
            this.redirecttoCompanyVeritification(res.data.token);
          }).catch((error) => {
            this.process.run = false;
            this.response.type = "error"
            if (error.response.data.message !== "error validation") {
              this.response.message = error.response.data.message;
            }else {
              Object.keys(error.response.data.validation).forEach((key) => {
                this.response.message += error.response.data.validation[key] + "<br>";
              });
            }
          });
          
        }
        
      }else {
        this.process.run = false;
      }
    },
    redirecttoCompanyVeritification(token) {
      window.open(`https://company.joinsistem.com/verification/${token}`, '_blank');
      // window.open(`http://localhost:3001/verification/${token}`, '_blank');
    },
    redirectAfterLogin() {
      if (this.user.is_verified && this.user.fullname && this.user_sistem.talent_first_name) {
        setTimeout(() => {
          this.$router.push("/dashboard");
        }, 20);
      }else {
        this.$router.push("/account/edit");     
      }
    },
  }
};
</script>