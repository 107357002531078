// if (navigator.userAgent.indexOf(' UCBrowser/') >= 0) {
//   const message = "Mohon maaf website tidak dapat diakses dari browser ini. Silahkan gunakan browser lain untuk mengakses website ini.";
//   document.querySelector("body").innerHTML = `<h4 style="color: #1A56DB;text-align:center;padding: 10px;font-weight: 600;width: 80%;margin: 10px auto;border: 1px solid;border-radius: 8px;">${message}</h4>`;
//   throw new Error(message);
// }

require("./lib");
import "./assets/style.scss";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./lib/vuetify";
import convert from "./lib/convert";
import firebaseMessaging from './firebase'
import VueYoutube from 'vue-youtube'
import VueAnalytics from 'vue-analytics';

if (firebaseMessaging.messaging.isSupported()) {
    const messaging = firebaseMessaging.messaging();

    messaging.usePublicVapidKey(
      process.env.VUE_APP_FIREBASE_VAPID_KEY
    );
    Vue.prototype.$firebaseIsSupported = true
    Vue.prototype.$messaging = messaging
    // Vue.prototype.$registration = []
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      // console.log("registrations", registrations);
      if (registrations.length == 0) {
        navigator.serviceWorker
          .register("/firebase-messaging-sw.js")
          .then(async (registration) => {
            await messaging.useServiceWorker(registration);
            Vue.prototype.$registration = registration;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        Vue.prototype.$registration = registrations;
      }
    })
    .catch((err) => {
      console.log(err);
    });
} else {
  Vue.prototype.$firebaseIsSupported = false
}

// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: 'UA-116046913-3',
  router
});


Vue.use(VueYoutube)

Vue.config.productionTip = false;

(async () => {
  await store.dispatch("get_user");
  await store.dispatch("get_user_sistem");
  new Vue({
    convert,
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
})();
