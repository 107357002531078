<template>
  <v-card outlined flat class="mt-4 rounded-lg">
    <v-card-title>
      Minat dan Preferensi Pekerjaan 
      <v-spacer></v-spacer>
      <v-btn 
        v-if="!showInterest" 
        color="#1F2A37"
        style="border: 1px solid #E5E7EB"
        class="rounded-lg"
        x-small
        outlined
        @click="() => {
          showInterest = !showInterest
          this.setForm()
        }">
        Ubah
      </v-btn>
      <div v-else>
        <v-btn 
          color="primary"
          x-small
          depressed
          class="rounded-lg"
          @click="onAddMinatAndPreference()">
          Simpan
        </v-btn>
        <v-btn 
          color="#1F2A37"
          style="border: 1px solid #E5E7EB"
          class="ml-2 rounded-lg"
          x-small
          outlined
          @click="showInterest = !showInterest">
          Tutup
        </v-btn>
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text v-show="!showInterest">

      <div>

        <div 
          style="
            display: grid;
            grid-template-columns: 1fr 2fr;
            margin-top: 1rem;
            gap: 30px
          ">
          <div class="text-h6 black--text">Bidang Minat</div>

          <div v-if="profileData.talent_preference_job_categories.length > 0">
            <div v-for="(job, i) in profileData.talent_preference_job_categories" :key="i">
              <v-chip
                class="mb-2"
                small
                label
                color="#E5E7EB">
                <div class="mr-2 black--text">{{ job.talent_preference_job_category_name }}</div>
              </v-chip>

              <div v-for="(subJob, j) in job.talent_preference_job_category_job_titles" :key="j">
                <div class="items-center justify-start mb-1 d-flex">
                  <v-icon size="15" color="primary" left>ri-checkbox-circle-line</v-icon>
                  <div class="mr-2 black--text">{{ subJob.talent_preference_job_title_job_title }}</div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div 
          style="
            display: grid;
            grid-template-columns: 1fr 2fr;
            margin-top: 1rem;
            gap: 30px
          ">
          <div class="text-h6 black--text">Tipe Tempat Kerja</div>
          <div class="subtitle-1 font-weight-bold black--text">{{ profileData.talent_preference_type_of_workplace }}</div>
        </div>
        
        <div 
          style="
            display: grid;
            grid-template-columns: 1fr 2fr;
            margin-top: 1rem;
            gap: 30px
          ">
          <div class="text-h6 black--text">Ekspektasi Gaji Bulanan</div>
          <div class="subtitle-1 font-weight-bold black--text">{{ profileData.talent_preference_salary }}</div>
        </div>

        <div 
          style="
            display: grid;
            grid-template-columns: 1fr 2fr;
            margin-top: 1rem;
            gap: 30px
          ">
          <div class="text-h6 black--text">Preferensi Kota Kerja</div>
          <div class="subtitle-1 font-weight-bold black--text">{{ profileData.talent_preference_location }}</div>
        </div>
        
      </div>

    </v-card-text>
    <v-expand-transition>
      <v-card-text v-show="showInterest">

        <v-tabs v-model="tab">
          <v-tab class="text-capitalize">Minat</v-tab>
          <v-tab class="text-capitalize">Preferensi</v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab" class="mt-3">
          <v-tab-item>

            <!-- {{ talent_preference_job_categories }} -->

            <div v-if="talent_preference_job_categories">
              <div v-for="(item, i) in talent_preference_job_categories" :key="i" class="my-2">
                <div class="mb-1 font-weight-medium">Bidang Pekerjaan</div>
                <div class="justify-start d-flex align-center" style="gap: 10px;">
                  <v-autocomplete
                    dense
                    outlined
                    single-line
                    autocomplete="off"
                    v-model="item.talent_preference_job_category_name"
                    hide-details
                    required
                    @change="onChangeMinat(item.talent_preference_job_category_name, i)"
                    :items="jobCategory"
                    item-text="label"
                    item-value="label"
                    class="rounded-lg"
                    placeholder="Pilih Bidang Pekerjaan">
                  </v-autocomplete>
                  <v-icon size="20" color="red" @click="onDeleteMinat(i)">mdi-delete</v-icon>
                </div>
                
                <v-row no-gutters v-if="item.job_category_job_titles.length > 0">
                  <v-chip-group
                    v-model="item.job_category_job_titles_selected"
                    column
                    multiple>
                    <v-chip
                      v-for="(job_title) in item.job_category_job_titles"
                      :key="job_title.label"
                      small
                      :value="job_title.label"
                      active-class="primary--text"
                      >
                      {{ job_title.label }}
                    </v-chip>
                  </v-chip-group>
                  <!-- <v-col cols="4" v-for="(job_title, index) in item.job_category_job_titles" :key="index">
                    <v-checkbox
                      v-model="item.job_category_job_titles_selected"
                      :label="job_title.label"
                      :value="job_title.label"
                      hide-details>
                    </v-checkbox>
                  </v-col> -->
                </v-row>
              </div>
            </div>

            <v-alert 
              v-show="error.response" 
              dense text color="error"
              transition="scale-transition">
              {{ error.response }}
            </v-alert>

            <v-btn
              x-small
              class="mt-3"
              color="primary"
              @click="onAddMinat()"
              text>
              Tambah Bidang Pekerjaan
              <v-icon right>mdi-plus</v-icon>
            </v-btn>

          </v-tab-item>
          <v-tab-item>

            <div>
              <div class="mb-1 font-weight-medium">Tipe Tempat Kerja</div>
              <v-autocomplete
                dense
                outlined
                single-line
                autocomplete="off"
                v-model="form.talent_preference.talent_preference_type_of_workplace"
                required
                :items="workPlace"
                item-text="label"
                item-value="label"
                class="rounded-lg"
                placeholder="Pilih Tipe Tempat Kerja">
              </v-autocomplete>
              <!-- <v-select
                outlined
                class="rounded-lg"
                dense
                placeholder="Pilih Tipe Tempat Kerja">
              </v-select> -->
            </div>

            <div>
              <div class="mb-1 font-weight-medium">Ekspektasi Gaji Anda</div>
              <v-autocomplete
                dense
                outlined
                single-line
                autocomplete="off"
                v-model="form.talent_preference.talent_preference_salary"
                required
                :items="expectationSalary"
                class="rounded-lg"
                placeholder="Pilih Ekspektasi Gaji Anda">
              </v-autocomplete>
              <!-- <v-select
                outlined
                class="rounded-lg"
                dense
                placeholder="Pilih Ekspektasi Gaji Anda">
              </v-select> -->
            </div>

            <div>
              <div class="mb-1 font-weight-medium">Provinsi Bekerja</div>
              <v-autocomplete
                dense
                outlined
                single-line
                autocomplete="off"
                v-model="form.talent_preference.talent_preference_province"
                required
                :items="provinces"
                item-text="label"
                item-value="value"
                class="rounded-lg"
                placeholder="Pilih Provinsi Bekerja">
              </v-autocomplete>
            </div>

            <div>
              <div class="mb-1 font-weight-medium">Kota Bekerja</div>
              <v-autocomplete
                dense
                outlined
                single-line
                autocomplete="off"
                v-model="form.talent_preference.talent_preference_location"
                required
                :items="district"
                item-text="label"
                item-value="label"
                class="rounded-lg"
                placeholder="Pilih Kota Bekerja">
              </v-autocomplete>
              <!-- <v-select
                outlined
                class="rounded-lg"
                dense
                placeholder="Pilih Kota Bekerja">
              </v-select> -->
            </div>

          </v-tab-item>
        </v-tabs-items>
        
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { JOB_PORTAL_API } from "@/constants/api";

export default {
  props: {
    profileData: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    showInterest: false,

    tab: 0,

    state_index: 0,
    state_id: "",
    state_form: "create",

    talent_preference_job_categories: [],

    jobTitlesSelected: [],

    jobCategory: [],
    jobTitle: [],

    workPlace: [],

    provinces: [],
    district: [],

    error: {
      response: "",
    },

    form: {
      talent_preference: {
        talent_preference_salary: "",
        talent_preference_province: "",
        talent_preference_location: "",
        talent_preference_type_of_workplace: "",
        talent_preference_job_categories: [
          {
            talent_preference_job_category_name: "",
            talent_preference_job_category_job_titles: [
              {
                talent_preference_job_title_job_title: "",
              }
            ]
          }
        ]
      },
    }
  }),
  watch: {
    "form.talent_preference.talent_preference_province": function() {
      this.location().district()
    }
  },
  computed: {
    expectationSalary() {
      return [
        "Kurang dari Rp 1.000.000",
        "Rp 1.000.000 - Rp 2.000.000",
        "Rp 2.000.000 - Rp 3.000.000",
        "Rp 3.000.000 - Rp 4.000.000",
        "Rp 4.000.000 - Rp 5.000.000",
        "Rp 5.000.000 - Rp 6.000.000",
        "Rp 6.000.000 - Rp 7.000.000",
        "Rp 7.000.000 - Rp 8.000.000",
        "Rp 8.000.000 - Rp 9.000.000",
        "Rp 9.000.000 - Rp 10.000.000",
        "Lebih dari Rp 10.000.000"
      ]
    },
  },
  mounted() {
    this.location().province()
    this.fetchWorkPlace()
    this.fetchJobCategory()
  },
  methods: {
    async setForm() {
      this.tab = 0

      await this.axios.get(`${JOB_PORTAL_API.USER.GET_ME}`)
      .then((response => {
        let res = response.data
        console.log(res);

        this.form = {
          talent_preference: {
            talent_preference_salary: res.talent_preference.talent_preference_salary,
            talent_preference_location: res.talent_preference.talent_preference_location,
            talent_preference_type_of_workplace: res.talent_preference.talent_preference_type_of_workplace,
            talent_preference_job_categories: res.talent_preference.talent_preference_job_categories
          },
        }

        res.talent_preference.talent_preference_job_categories.forEach((jobCategory, index) => {
          jobCategory.job_category_job_titles = []
          jobCategory.job_category_job_titles_selected = []

          jobCategory.talent_preference_job_category_job_titles.forEach((jobTitle) => {
            jobCategory.job_category_job_titles_selected.push(jobTitle.talent_preference_job_title_job_title)
            jobCategory.job_category_job_titles.push({
              label: jobTitle.talent_preference_job_title_job_title,
              value: jobTitle.talent_preference_job_title_job_title
            })
          })

          let jobCategoryId = this.jobCategory.find((job) => job.label === jobCategory.talent_preference_job_category_name).master_job_category_id

          this.fetchJobTitle(jobCategoryId, index);
          
        })

        // console.log(res.talent_preference.talent_preference_job_categories);
        
        this.talent_preference_job_categories = res.talent_preference.talent_preference_job_categories
      }))
      
      // this.form = this.profileData
      
      this.talent_preference_job_categories = [...this.talentPreferenceJobCategories]
    },
    location() {
      let _self = this
      return {
        async province() {
          await _self.axios.get(`${JOB_PORTAL_API.LOCATION.PROVINCE}`)
          .then((response) => {
            _self.provinces = response.data
          })
        },
        async district() {
          await _self.axios.get(`${JOB_PORTAL_API.LOCATION.DISTRICT(_self.form.talent_preference.talent_preference_province)}`)
          .then((response) => {
            _self.district = response.data
          })
        },
      }
    },

    async fetchWorkPlace() {
      await this.axios.get(`${JOB_PORTAL_API.MASTER_DATA.GET_LIST("type-of-workplace")}`)
      .then((response) => {
        this.workPlace = response.data
      }).catch((error) => {
        this.$refs.snackbar.open('error', error.response.data.message)
      })
    },

    async fetchJobCategory() {
      await this.axios.get(`${JOB_PORTAL_API.MASTER_DATA.GET_LIST("job-category")}`)
      .then((response) => {
        this.jobCategory = response.data
      }).catch((error) => {
        this.$refs.snackbar.open('error', error.response.data.message)
      })
    },

    async fetchJobTitle(id, index) {
      await this.axios.get(`${JOB_PORTAL_API.MASTER_DATA.GET_LIST_WITH_ID("job-title", id)}`)
      .then((response) => {
        let newJobCategories = [...this.talent_preference_job_categories]

        newJobCategories[index].job_category_job_titles = response.data

        this.talent_preference_job_categories = newJobCategories

      }).catch((error) => {
        this.$refs.snackbar.open('error', error.response.data.message)
      })
    },

    // ====================================================================================
    // INTEREST AND PREFERENCE FUNCTIONS

    onAddMinat() {
      this.talent_preference_job_categories.push({
        talent_preference_job_category_job_titles: [],
        job_category_job_titles: [],
        job_category_job_titles_selected: [],
        talent_preference_job_category_name: '',
      })
    },

    onChangeJobTitle(job_title, index) {

      this.talent_preference_job_categories[index].talent_preference_job_category_job_titles.push({
        talent_preference_job_title_job_title: job_title
      })
    },

    onChangeMinat(job_category_name, index) {
      
      const newTalentPreferenceJobCategories = [...this.talent_preference_job_categories];

      const tempTalentPreferenceJobCategories = [...this.talent_preference_job_categories];
      
      // newTalentPreferenceJobCategories[index].talent_preference_job_category_name = job_category_name;
      // newTalentPreferenceJobCategories[index].talent_preference_job_category_job_titles = [];
      newTalentPreferenceJobCategories[index].job_category_job_titles_selected = [];

      tempTalentPreferenceJobCategories.splice(index, 1)
      
      let isExist = tempTalentPreferenceJobCategories.find((jobCategory) => jobCategory.talent_preference_job_category_name === job_category_name)

      if(isExist) {
        this.setForm()
        this.error.response = `Bidang Pekerjaan ${job_category_name} sudah ada!`
        setTimeout(() => {
          this.error.response = ""
        }, 4000);
        return 
        // this.$refs.snackbar.open('error', `Bidang Pekerjaan ${job_category_name} sudah ada!`)
      }

      this.talent_preference_job_categories = newTalentPreferenceJobCategories;
      
      let jobCategoryId = this.jobCategory.find((job) => job.label === job_category_name).master_job_category_id
      
      this.fetchJobTitle(jobCategoryId, index)

    },

    onAddMinatAndPreference() {

      // mapping job category name
      let arrJobCategoryName = this.talent_preference_job_categories.map((jobCategory) => jobCategory.talent_preference_job_category_name)

      let isError = false
      let isErrorMsg = ""

      this.talent_preference_job_categories.forEach((jobCategory) => {

        if (!jobCategory.talent_preference_job_category_name) {
          isError = true
          isErrorMsg = "Pilih Bidang Pekerjaan, bidang pekerjaan masih ada yang kosong!"
          // return this.$refs.snackbar.open('error', 'Pilih Bidang Pekerjaan, bidang pekerjaan masih ada yang kosong!')
        }

        // filter and check if job category name is more than 1
        let filterCategoryName = arrJobCategoryName.filter((item, index) => arrJobCategoryName.indexOf(item) !== index)

        if(filterCategoryName.length > 0) {
          isError = true
          isErrorMsg = `Bidang Pekerjaan ${jobCategory.talent_preference_job_category_name} lebih dari 1 data!`
          // return this.$refs.snackbar.open('error', `Bidang Pekerjaan ${jobCategory.talent_preference_job_category_name} lebih dari 1 data!`)
        }
        
        // if (jobCategory.job_category_job_titles_selected.length === 0) {
        //   return this.$refs.snackbar.open('error', `Pilih Pekerjaan untuk Bidang Pekerjaan ${jobCategory.talent_preference_job_category_name}!`)
        // }

        jobCategory.talent_preference_job_category_job_titles = jobCategory.job_category_job_titles_selected.map((jobTitle) => {
          return {
            talent_preference_job_title_job_title: jobTitle
          }
        })
      })

      if(isError) {
        return this.$refs.snackbar.open('error', isErrorMsg)
      }

      this.form.talent_preference.talent_preference_job_categories = this.talent_preference_job_categories


      // console.log(this.form.talent_preference);

      this.showInterest = false

      // this.$refs.snackbar.open('success', 'Minat dan Preferensi Pekerjaan berhasil disimpan!')

      this.$emit("save", this.form.talent_preference)

      // this.onSaveTalentProfileResume()
    },

    onDeleteMinat(index) {
      this.talent_preference_job_categories.splice(index, 1)

      this.form.talent_preference.talent_preference_job_categories = this.talent_preference_job_categories

      // this.$refs.snackbar.open('success', 'Minat dan Preferensi Pekerjaan berhasil dihapus!')

      this.$emit("save", this.form.talent_preference)

      // this.onSaveTalentProfileResume()
    },
  }
}
</script>