<template>
  <v-row>
    <v-col cols="12">
      <v-container>

        <v-breadcrumbs :items="breadcrumbs" class="px-0">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

        <Snackbar ref="snackbar" />

        <v-card outlined class="rounded-lg">
          <v-card-title>
            <div class="headline font-weight-medium">Ubah Password</div>
          </v-card-title>
          <v-card-text>
            <v-form 
              ref="form"
              v-model="valid"
              lazy-validation>
              <div>
                <div class="mb-1 font-weight-medium required">Password Lama</div>
                <v-text-field
                  outlined
                  dense
                  v-model="form.current_password"
                  :rules="current_rules"
                  hide-details="auto"
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                  @click:append="show = !show"
                  autocomplete="new-password"
                  class="rounded-lg"
                  placeholder="Tuliskan Password Lama">
                </v-text-field>
              </div>
              <div class="my-4">
                <div class="mb-1 font-weight-medium required">Password Baru</div>
                <v-text-field
                  outlined
                  dense
                  v-model="form.new_password"
                  :rules="new_rules"
                  hide-details="auto"
                  :type="show_new ? 'text' : 'password'"
                  :append-icon="show_new ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                  @click:append="show_new = !show_new"
                  autocomplete="new-password"
                  class="rounded-lg"
                  placeholder="Tuliskan Password Baru">
                </v-text-field>
              </div>
              <div>
                <div class="mb-1 font-weight-medium required">Konfirmasi Password Baru</div>
                <v-text-field
                  outlined
                  dense
                  v-model="form.conf_new_password"
                  :rules="conf_new_rules"
                  hide-details="auto"
                  :type="show_conf_new ? 'text' : 'password'"
                  :append-icon="show_conf_new ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                  @click:append="show_conf_new = !show_conf_new"
                  autocomplete="new-password"
                  class="rounded-lg"
                  placeholder="Tuliskan Konfirmasi Password Baru">
                </v-text-field>
              </div>
            </v-form>
            <v-divider class="mt-4"></v-divider>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="black--text rounded-lg px-4"
              style="border: 1px solid #E5E7EB;"
              depressed
              outlined
              small
              :disabled="process.run"
              @click="() => { $router.push('/account') }">
              Kembali
            </v-btn>
            <v-btn
              class="rounded-lg px-4"
              color="primary"
              dark
              depressed
              :loading="process.run"
              @click="onUpdatePassword()"
              small>
              Ubah Password
            </v-btn>
          </v-card-actions>
        </v-card>

      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { LMS_API } from "@/constants/api"
import Snackbar from "@/components/Snackbar";

export default {
  name: "AccountPassword",
  components: {
    Snackbar
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Profile & Resume",
        disabled: false,
        href: "/account",
      },
      {
        text: "Ubah Password",
        disabled: true,
        href: "/akun",
      },
    ],

    valid: true,

    show: false,
    show_new: false,
    show_conf_new: false,

    form: {
      current_password: "",
      new_password: "",
      conf_new_password: ""
    },
    process: {
      run: false
    },

    current_rules: [
      (v) => !!v || "Password tidak boleh kosong",
      (v) => (v && v.length >= 8) || "Password minimal harus 8 karakter",
    ],
    new_rules: [
      (v) => !!v || "Password tidak boleh kosong",
      (v) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/.test(v) || 'Password harus mengandung huruf besar, huruf kecil, angka dan karakter spesial (minimal 8 karakter)',
      (v) => (v && v.length >= 8) || "Password minimal harus 8 karakter",
    ],
    conf_new_rules: [
      (v) => !!v || "Konfirmasi password tidak boleh kosong",
      (v) => v === this.new_psw || "Konfirmasi password tidak sesuai dengan password baru",
      (v) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/.test(v) || 'Password harus mengandung huruf besar, huruf kecil, angka dan karakter spesial (minimal 8 karakter)',
    ],
  }),
  methods: {

    async onUpdatePassword () {

      if (this.$refs.formChange.validate()) {
        this.process.run = true

        await this.axios.post(`${LMS_API.USER.CHANGE_PASSWORD}`, this.form)
        .then((response) => {
          let res = response.data;
          if(res.status == 200) {
            this.process.run = false

            this.$refs.snackbar.open("primary", "Berhasil mengubah password");
          }
        }).catch((error) => {
          console.log(error);
          this.process.run = false
          this.$refs.snackbar.open("error", "Gagal mengubah password");
        })
      }

    }
  },
}
</script>