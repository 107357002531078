<template>
  <div>
    <!-- <div class="px-4 py-2">Mini Quiz. Jawab Pertanyaan Dibawah Ini!</div> -->
    <v-divider></v-divider>
    <div class="pa-4">
      <!-- navigation -->
      <div class="d-flex justify-space-between mb-4">
        <div class="d-flex align-center">
          <v-btn width="48" min-width="48" outlined small color="primary" :disabled="gameplay.current <= 1"  @click="beforeQuestion()">
            <v-icon>$prev</v-icon>
          </v-btn>
          <div class="mx-4">
            Soal no
            <span class="primary--text">{{ gameplay.current }}</span>
            dari
            {{ questions.length }}
          </div>
          <v-btn width="48" min-width="48" small outlined color="primary" :disabled="questions.length == gameplay.current" @click="nextQuestion()">
            <v-icon>$next</v-icon>
          </v-btn>
        </div>
        <!-- <div>
          <v-btn v-if="questions.length == gameplay.current" color="primary" elevation="0" class="rounded-pill" @click="submitAnswer">
            Submit
          </v-btn>
        </div> -->
        <div v-if="!review">
          <v-btn v-if="questions.length == gameplay.current" :loading="isLoading" color="primary" elevation="0" class="rounded-pill" @click="onUpdateAnswer('submit')">
            Submit
          </v-btn>
        </div>
        <div v-else>
          <v-btn v-if="questions.length == gameplay.current" :loading="isLoading" color="primary" elevation="0" class="rounded-pill" @click="$emit('click:back-to-complete')">
            Kembali ke Nilai
          </v-btn>
        </div>
      </div>

      <!-- content -->
      <!-- {{ currentQuestion }} -->
      <div class="d-flex mb-4">
        <div class="flex-grow-0 mr-4" style="width: 2rem">
          <div>{{ gameplay.current }}.</div>
        </div>
        <div class="flex-grow-1">
          <div v-if="currentQuestion.question.audio">
            <audio controls>
              <source :src="currentQuestion.question.audio">
            </audio>
          </div>
          <div v-if="currentQuestion.question.image">
            <img style="height: 300px;" :src="currentQuestion.question.image" :title="currentQuestion.question.text"/>
          </div>
          <div class="mb-4" v-html="currentQuestion.question.text"></div>
          <div class="d-flex flex-column align-start" v-if="!review">
            <v-btn
              class="pa-4 flex-grow-0 mb-4"
              :outlined="answer == option.text ? false : true"
              v-for="(option, index) in currentQuestion.options"
              :color="answer == option.text ? 'primary' : ''"
              :key="option.text"
              :value="option.text"
              @click="answer = option.text;"
              style="text-transform: none">
              <span class="mr-2"> {{ (index + 10).toString(36) }}. </span>
              <span>{{ option.text }}</span>
              <!-- <v-img class="mr-2" contain :src="option.image" alt=""></v-img> -->
            </v-btn>
          </div>

          <!-- REVIEW OPTION -->
          <div class="d-flex flex-column align-start" v-else>
            <v-btn
              class="pa-4 flex-grow-0 mb-4"
              outlined
              v-for="(option, index) in currentQuestion.options"
              :color="currentQuestion.correct_answers[0] == option.text ? 'green' : ''"
              :key="option.text"
              :value="option.text"
              style="text-transform: none">
              <span class="mr-2"> {{ (index + 10).toString(36) }}. </span>
              <span>{{ option.text }}</span>
              <!-- <v-img class="mr-2" contain :src="option.image" alt=""></v-img> -->
            </v-btn>
          </div>
        </div>
      </div>

      <!-- timer -->
      <div v-if="!review">
        <div v-if="quizData.setting.time.enable" class="d-flex align-center">
          <v-icon left class="ritimer">ri-time-line</v-icon>
          <div class="mr-2">Sisa Waktu Berlangsung:</div>
          <div class="font-weight-bold">{{ timer }}</div>
        </div>
      </div>

      <!-- review -->
      <div v-else>
        <div class="font-weight-bold mb-2">Pembahasan Soal</div>
        <v-alert outlined>
          <span>Jawaban: &nbsp;</span>
          <span class="font-weight-bold" v-if="quizData.setting.show_answer_explanation">{{ currentQuestion.correct_answers[0] }}</span>
          <div style="font-size: 13px;" v-if="quizData.setting.show_explanation" v-html="currentQuestion.explanation.text"></div>
          <v-img 
            style="cursor: pointer;"
            v-if="currentQuestion.explanation.image" 
            :src="currentQuestion.explanation.image" width="100"
            @click="dialogPreview = true">
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-alert>
      </div>
    </div>

    <v-dialog v-model="dialogPreview" width="200">
      <v-img 
        class="mx-auto"
        :src="currentQuestion.explanation.image" width="200">
      </v-img>
    </v-dialog>

    <v-snackbar top v-model="snackbar.state" :timeout="5000" color="primary" outlined>
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn small icon color="error" v-bind="attrs" @click="snackbar.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { LMS_API } from "@/constants/api"

export default {
  props: {
    review: Boolean,
    quizData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data: () => ({
    seq: 1,
    dialogPreview: false,
    isLoading: false,
    answer: undefined,
    // question: {
    //   text: "Biasa sa cinta satu sa pinta?",
    //   options: [
    //     "Jang terlalu mengekang rasa",
    //     "Karna kalau sa su bilang",
    //     "Janji tak mungkin ku ingkari",
    //     "Karna alasan tak kabari",
    //     "Biarkan cinta tumbuh sebisanya",
    //   ],
    //   corect_feedback:
    //     "karena norma kesusilaan merupakan aturan dasar hidup tentang perilaku yang dinilai baik maupun buruk.",
    // },
    questions: [],
    saveAnswer:[],
    gameplay: {},
    snackbar: {
      state: false,
      text: "",
    },
    intervalTimer: null,
    timer: 0,
  }),
  computed: {
    currentQuestion() {
      try {
        const index = this.gameplay.current - 1;
        return this.questions[index];
      } catch (error) {
        return {};
      }
    },
  },
  watch: {
    answer: {
      handler() {
        // console.log(val);
      },
      deep: true
    }
  }, 
  mounted() {
    this.setQuestions()
  },
  unmounted() {
    clearInterval(this.intervalTimer)
  },
  methods: {
    handleCountdownTimer () {
      let setting = this.quizData.setting
      var timer = setting.time.duration * 60;
      let minutes;
      let seconds;

      this.intervalTimer = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        this.timer =  minutes + ":" + seconds;

        // console.log(this.timer);

        if (--timer <= 0) {
          // timer = 60 * 10;
          clearInterval(this.intervalTimer)
          this.saveAnswerQuiz()
        }
        
      }, 1000);
    },

    beforeQuestion() {
      this.currentQuestion.answer = this.answer

      this.gameplay.current--

      this.answer = this.currentQuestion.answer
    },

    nextQuestion() {
      this.currentQuestion.answer = this.answer
      
      this.gameplay.current++

      this.answer = this.currentQuestion.answer
    },

    onUpdateAnswer(type) {
      // this.$set(this.currentQuestion, 'answer', this.answer)

      if(type == 'next') {
        this.gameplay.current++
      }else {
        // Last Question set answer
        this.currentQuestion.answer = this.answer

        this.submitAnswer()
      }
    },

    async setQuestions () {

      await this.axios
      .get(`${LMS_API.ACTIVITY.GAMEPLAY.DETAIL_MULTIPLE}`, {
        params: {
          ids: this.quizData.gameplays.toString()
        }
      })
      .then((res) => res.data)
      .then(async (res) => {

        // console.log(res.data);

        this.$set(this.gameplay, "current", 1);
        this.$set(this.gameplay, "list_question", res.data);

        res.data.forEach((item) => {
          item.answer = undefined
        })

        this.questions = res.data
      })

      if(this.quizData.setting.time.enable) {
        this.handleCountdownTimer()
      }
    },
    submitAnswer() {

      this.questions.forEach((item) => {
        // console.log('question answer',item);
        let findindex = this.saveAnswer.findIndex((v) => v.gameplay_id == item.id)
        let find = this.saveAnswer.find((v) => v.gameplay_id == item.id)

        if (!find) {
          this.saveAnswer.push({
            gameplay_id: item.id,
            answer: item.answer == undefined ? [] : [item.answer]
          })
        }else {
          this.saveAnswer[findindex].answer = item.answer == undefined ? [] : [item.answer]
        }
        // if(item.answer) {
        // }
      })

      if (this.saveAnswer.length == 0) {
        this.snackbar = {
          state: true,
          text: "Belum ada soal yang terjawab"
        }
        return
      }

      if (this.quizData.setting.retake == -1) {
        return this.saveAnswerQuiz()
      }

      if(this.quizData.retake != null && this.quizData.retake >= this.quizData.setting.retake) {
        this.$emit('click:close-modal')
        clearInterval(this.intervalTimer)
        return  this.snackbar = {
          state: true,
          text: `Kesempatan mengulang quiz sudah mencapai batas maksimal pada aktifitas ini sebanyak ${this.quizData.setting.retake} kali.`
        }
      }

      this.saveAnswerQuiz()

    },
    async saveAnswerQuiz() {

      this.isLoading = true

      let setting_timer = this.quizData.setting.time.duration * 60;
      let last_duration_minutes = 0
      let minutes = 0
      let seconds = 0

      if(this.quizData.setting.time.enable){
        last_duration_minutes = Number(this.timer.split(':')[0]) + Number(this.timer.split(':')[1] / 60)
  
        minutes = parseInt(setting_timer / 60, 10) - last_duration_minutes
  
        seconds = parseInt(minutes * 60, 10);
      }

      let data = {
        member_redeem_id: this.$route.params.id,
        topic_id: this.quizData.topic_id,
        activity_id: this.quizData.activity_id,
        answer_duration: seconds,
        answer: this.saveAnswer
      }


      await this.axios.post(`${LMS_API.ACTIVITY.GAMEPLAY.ANSWER}`, data).then((response) => {
        let res = response.data
        this.snackbar = {
          state: true,
          text: "Jawaban berhasil dikirimkan"
        }
        clearInterval(this.intervalTimer)
        
        this.$emit('click:submit', res.data)
        this.isLoading = false

      }).catch((error) => {
        clearInterval(this.intervalTimer)
        this.isLoading = false
        let message = ""
        if(error instanceof Error) {
          message = error.message;
          if (error.response?.data?.message) {
            message = error.response.data.message;
          }
        }

        this.snackbar = {
          state: true,
          text: message
        }

        if(message == 'kesempatan mengulang quiz sudah mencapai batas maksimal pada aktifitas ini') {  
          setTimeout(() => {
            this.$emit('click:close-modal')
          }, 2000);
        }
      })
      
    }
  }
};
</script>

<style>
.ql-align-right {
  text-align: right;
}
.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}
</style>
