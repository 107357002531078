<template>
  <div>
    <!-- <div class="px-4 py-2">Mini Quiz. Jawab Pertanyaan Dibawah Ini!</div> -->
    <v-divider></v-divider>
    <!-- <v-skeleton-loader v-if="loading" class="mx-auto" type="image">
    </v-skeleton-loader> -->
    <div class="pa-4">
      <!-- navigation -->
      <div class="d-flex justify-space-between mb-4">
        <div class="d-flex align-center">
          <v-btn
            width="48"
            min-width="48"
            outlined
            small
            color="primary"
            :disabled="gameplay.current <= 1"
            @click="beforeQuestion()"
          >
            <v-icon>$prev</v-icon>
          </v-btn>
          <div class="mx-4">
            Soal no
            <span class="primary--text">{{ this.gameplay.current }}</span>
            dari
            {{ gameplays.length }}
          </div>
          <v-btn
            width="48"
            min-width="48"
            small
            outlined
            color="primary"
            :disabled="gameplay.current == gameplays.length"
            @click="nextQuestion()"
          >
            <v-icon>$next</v-icon>
          </v-btn>
        </div>
      </div>
      <!-- end navigation -->

      <!-- {{ currentQuestion }} -->

      <div v-if="currentQuestion" class="mb-4">
        <div class="mb-4">
          <div v-if="currentQuestion.gameplay.question.audio">
            <audio controls>
              <source :src="currentQuestion.gameplay.question.audio" />
            </audio>
          </div>
          <div v-if="currentQuestion.gameplay.question.image">
            <img
              style="height: 200px"
              :src="currentQuestion.gameplay.question.image"
              :title="currentQuestion.gameplay.question.text"
            />
          </div>
        </div>
        <div
          class="mb-4 mx-4 text-h6"
          v-html="currentQuestion.gameplay.question.text"
        ></div>
        <div class="d-flex justify-center mb-4">
          <v-btn
            class="flex-grow-1 mx-4"
            v-for="option in currentQuestion.gameplay.options"
            :key="option.text"
            :value="option.text"
            rounded
            :outlined="
              gameplays[gameplay.current - 1].answer.answer[0] == option.text
                ? false
                : true
            "
            color="primary"
            style="text-transform: none; pointer-events: none"
          >
            {{ option.text }}
          </v-btn>
        </div>

        <v-card outlined>
          <div class="mx-2 pa-4">
            <div>
              Jawaban Anda
              <strong
                >{{
                  currentQuestion.total_correct ? "" : "Tidak"
                }}
                Tepat</strong
              >
            </div>
            <div v-if="!currentQuestion.total_correct">
              Jawaban yang tepat adalah
              <strong>{{
                currentQuestion.gameplay.correct_answer.join(",")
              }}</strong>
            </div>
          </div>
        </v-card>
      </div>

      <v-divider></v-divider>
      <v-row>
        <v-col cols="6">
          <table style="width: 100%">
            <tbody>
              <tr>
                <td class="pa-2">Total Benar</td>
                <td class="pa-2 text-right">{{ total_correct }}</td>
              </tr>
              <tr>
                <td class="pa-2">Total Point</td>
                <td class="pa-2 text-right">{{ total_point }}</td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { LMS_API } from "@/constants/api"

export default {
  props: {
    review: Boolean,
    quizData: {
      type: Object,
      default() {
        return {};
      },
    },
    gameplays: {
      type: Array,
      default() {
        return [];
      },
    },
    reviewData: {
      type: Object,
      default() {
        return {};
      },
    },
    total_correct: {
      type: Number,
      default: 0
    },
    total_point: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    // questions: [],
    // total_correct: 0,
    // total_point: 0,
    gameplay: {
      current: 1,
      answers: [],
    },
    loading: false,
    isLoading: false,
  }),
  computed: {
    currentQuestion() {
      try {
        const index = this.gameplay.current - 1;
        return this.$props.gameplays[index];
      } catch (error) {
        return {};
      }
    },
  },
  mounted() {
    // this.setQuestions();
  },
  methods: {
    async setQuestions() {
      this.loading = true;
      await this.axios
        .post(
          `${LMS_API.ACTIVITY.GAMEPLAY.DETAIL_ANSWER(this.quizData.license, this.quizData.topic_id, this.quizData.activity_id)}`
        )
        .then((res) => res.data)
        .then(async (res) => {
          this.$set(this.gameplay, "current", 1);
          // this.$set(this.gameplay, "list_question", res.data);

          this.questions = res.data.gameplays;
          this.total_correct = res.data.total_correct;
          this.total_point = res.data.total_point;
        })
        .finally(() => {
          this.loading = false;
        });

      // this.onUpdateData()
    },
    beforeQuestion() {
      this.gameplay.current--;
    },
    nextQuestion() {
      this.gameplay.current++;
    },
  },
};
</script>