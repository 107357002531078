import axios from "axios";
// import url from "./api";
import { LMS_API } from "@/constants/api"

const state = () => ({
	under_maintenance: null,
	phone_number: [
		"6282137194353", // Isa
		"6287758014542", // Rennis
		"6282217035188", // Linda
		"6285643302133", // Syihab
		"6282138525848", // Alvon
	],
});

const mutations = {
	setUnderMaintenance(state, data) {
		state.under_maintenance = data.under_maintenance;
	},
};

const actions = {
	async getCompanySetting({ commit }) {
		const access_key = process.env.VUE_APP_ACCESS_KEY;

		return axios.get(LMS_API.COMPANY.GET(access_key), {
			headers: { "Content-Type": "application/json" },
		})
		.then((res) => {
			let data = res.data.data;
			commit("setUnderMaintenance", data);
			return(data)
		}).catch((err) => {
			console.log(err);
		});
	},
}

export default { state, mutations, actions };