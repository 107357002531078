<template>
  <v-row style="position: absolute">
    <v-col cols="12" class="pa-0" style="z-index: 9999">
      <video
        ref="video"
        class="test-video"
        id="video"
        width="240"
        height="140"
        autoplay
      ></video>
      <canvas
        width="240"
        height="140"
        ref="canvas"
        style="
          z-index: 9999;
          position: fixed;
          right: -100%;
          bottom: -100%;
          width: 240px;
          height: 140px;
        "
      />
    </v-col>
    <v-dialog v-model="showDialog" persistent fullscreen>
      <v-card flat>
        <v-card-title style="color: #3c4858" class="font-weight-bold">
          {{ title }}
        </v-card-title>
        <v-card-text>
          <v-img
            v-if="cheatType == 'face' && imagePreview"
            width="315"
            height="176"
            contain
            class="mx-auto mb-5"
            :src="imagePreview"
          >
          </v-img>

          {{ message }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn large @click="handleOk" color="primary">
            Baik, Saya Mengerti
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import * as faceAPI from "face-api.js";

export default {
  props: {
    face: Boolean,
    voice: Boolean,
    tab: Boolean,
    // speech: {
    //   type: Object,
    //   default: () => ({}),
    // },
  },
  data: () => ({
    showDialog: false,
    cheatType: "",
    // speech: null,
    faceCount: 0,
    stream: null,
    imagePreview: null,
    option: null,
    speaking: false,
    speaked: [],
  }),
  computed: {
    title() {
      switch (this.cheatType) {
        case "tab":
          return "Anda Telah Membuka Tab Baru atau meninggalkan halaman tes";
        case "face":
          return "Multi-wajah terdeteksi";
        case "speech":
          return "Percakapan terdeteksi";
        default:
          return "";
      }
    },
    message() {
      switch (this.cheatType) {
        case "tab":
          return "Anda telah membuka tab baru atau meninggalkan halaman tes, mohon untuk tidak membuka tab atau meninggalkan halaman tes lain agar tidak mengurangi keakurasian hasil tes.";
        case "face":
          return "Sistem mendeteksi wajah lebih dari satu, pastikan pengerjaan tes dilakukan sendiri dan jujur.";
        case "speech":
          return "Peringatan! Sistem mendeteksi adanya percakapan. Mohon untuk menjaga ketenangan saat tes berlangsung, agar tidak mengurangi keakurasian hasil tes.";
        default:
          return "";
      }
    },
  },
  mounted() {
    this.initSpeech();
    this.initCamera();
    document.addEventListener("visibilitychange", this.tabCheat);
    // this.capture();
  },
  beforeDestroy() {
    // this.capture();
  },
  destroyed() {
    document.removeEventListener("visibilitychange", this.tabCheat);
    if (this.speech) {
      this.$delete(this.speech);
      this.speech.abort();
    }
    if (this.stream) {
      this.stream.getTracks().forEach(function (track) {
        track.stop();
      });
    }
  },
  methods: {
    capture() {
      const vid = this.$refs.video;
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");

      canvas.width = vid.width;
      canvas.height = vid.height;

      ctx.drawImage(vid, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        this.imagePreview = URL.createObjectURL(blob);
        this.$emit("captured", blob);
      });
    },
    tabCheat() {
      if (this.tab && !this.showDialog) {
        this.showDialog = true;
        this.cheatType = "tab";
        this.$emit("cheating-newtab");
      }
    },
    handleOk() {
      this.showDialog = false;
      if (this.cheatType == "face") this.runFaceAPI();
      if (this.cheatType == "speech" && !this.speaking && this.speech) {
        this.speech.start();
      }
      this.cheatType = "";
    },
    initSpeech() {
      if (!window.speech) {
        const SpeechRecognition =
          window.SpeechRecognition || window.webkitSpeechRecognition;
        window.speech = SpeechRecognition ? new SpeechRecognition() : null;
        this.speech = window.speech;
      }

      if (window.speech) {
        this.speech = window.speech;
      }

      if (!this.speech) {
        return false;
      }

      this.speech.lang = "id-ID";
      this.speech.interimResults = true;

      this.speech.onstart = () => {};

      this.speech.onspeechstart = () => {
        this.speaking = true;
      };

      this.speech.onspeechend = () => {
        this.speaking = false;
        this.speaked = [];
        // if (!this.speaking)
        // this.speech.start();
      };

      this.speech.onresult = (event) => {
        Array.from(event.results)
          .map((result) => result[0])
          .map((result) => this.speaked.push(result.transcript));

        let arr = this.speaked[this.speaked.length - 1];

        const text = Array.from(event.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");

        if (arr.split(" ").length > 3 && this.voice) {
          if (!this.showDialog) this.$emit("cheating-voice", text);
          this.showDialog = true;
          this.cheatType = "speech";
        }
      };

      this.speech.onend = () => {
        // this.speaking = false;
        if (!this.speaking) this.speech.start();
        // this.speech.start();
      };
      this.speech.onstop = () => {
        this.speaking = false;
        // if (!this.speaking) this.speech.start();
      };

      try {
        this.speech.start();
      } catch (e) {
        console.log(e.message);
      }
    },
    initCamera() {
      this.showDetector = true;
      this.showIconFace = true;

      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(async (mediaStream) => {
          this.stream = mediaStream;
          this.$refs.video.srcObject = mediaStream;
          this.$refs.video.play();
          await this.initOption();
          this.runFaceAPI();
        })
        .catch(() => {
          this.initCamera();
        });
    },
    async initOption() {
      await faceAPI.loadSsdMobilenetv1Model("/models");
      this.option = new faceAPI.SsdMobilenetv1Options({ minConfidence: 0.5 });
    },
    async runFaceAPI() {
      const vid = this.$refs.video;
      const canvas = this.$refs.canvas;
      const result = await faceAPI.detectAllFaces(vid, this.option);

      if (result) {
        const ctx = canvas.getContext("2d");
        const dims = faceAPI.matchDimensions(canvas, vid, true);
        const resizeResults = faceAPI.resizeResults(result, dims);

        this.faceCount = resizeResults.length;
        if (this.faceCount > 1 && this.face) {
          const canvas = this.$refs.canvas;

          canvas.width = vid.width;
          canvas.height = vid.height;
          ctx.drawImage(vid, 0, 0, canvas.width, canvas.height);
          canvas.toBlob((blob) => {
            this.imagePreview = URL.createObjectURL(blob);
            if (!this.showDialog) this.$emit("cheating-face", blob);
            this.showDialog = true;
            this.cheatType = "face";
          });
        }
      }
      if (!this.showDialog) {
        setTimeout(() => this.runFaceAPI(), 1e3);
      }
    },
  },
};
</script>

<style scoped>
.test-video {
  z-index: 9999;
  position: fixed;
  right: 1%;
  bottom: 1%;
  width: 240px;
  height: 140px;
  border-radius: 10%;
}

@media screen and (max-width: 600px){
  .test-video {
    z-index: 9999;
    position: fixed;
    right: 1%;
    bottom: 1%;
    width: 140px;
    height: 140px;
    border-radius: 10%;
  }  
}
</style>
