<template>
  <div 
    v-if="user_sistem.talent_about_me"
    style="
      margin-top: 2rem;
      margin-bottom: 2rem;
      border: 1px solid #E5E7EB; 
      border-radius: 10px;
      font-family: 'Inter', sans-serif;">
    <div 
      style="
        font-size: 18px; 
        font-weight: 600; 
        padding: 20px;
      ">
      Tentang Saya
    </div>
    <v-divider></v-divider>
    <div 
      style="
        display: flex;
        justify-content: start;
        align-items: start;
        gap: 15px;
        text-align: justify;
        line-height: 20px;
        font-size: 12px;
        color: #374151;
        padding: 20px;
      ">
      {{ user_sistem.talent_about_me }}
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'AccountResumeAboutMe',
  computed: {
    ...mapGetters(['user_sistem'])
  },
}
</script>