var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"transparent px-2",attrs:{"elevation":"0"}},[_c('vue-html2pdf',{ref:"certifPeserta",attrs:{"enable-download":true,"preview-modal":false,"show-layout":true,"pdf-quality":2,"filename":_vm.certificateName,"pdf-orientation":"landscape","manual-pagination":true,"pdf-content-width":"100%","pdf-format":"legal"},on:{"hasDownloaded":function($event){return _vm.hasGenerated($event)}}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('section',{staticClass:"pdf-item",style:({
          backgroundImage: `url('${require('@/assets/images/certificate/bg-new-certif.jpg')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `810px`,
          color: `#4B4B4B`,
        })},[_c('div',{staticClass:"style1"},[_c('div',{staticStyle:{"font-style":"normal","text-align":"center","font-family":"Urbanist, sans-serif","font-weight":"900","font-size":"50px","line-height":"auto","margin-top":"40dvh","color":"black"}},[_vm._v(" "+_vm._s(_vm.data.user_name)+" ")]),_c('div',{staticStyle:{"font-style":"normal","font-family":"Urbanist, sans-serif","font-weight":"800","font-size":"20px","text-align":"center","line-height":"20px","margin-top":"10dvh","color":"black"}},[_vm._v(" Periode "+_vm._s(_vm.certificatePeriode(_vm.data.created_at))+" ")]),_c('div',{staticStyle:{"font-style":"normal","font-family":"Inter, sans-serif","font-weight":"400","font-size":"18px","text-align":"center","line-height":"20px","margin-top":"8.5dvh","margin-left":"3%","color":"black"}},[_vm._v(" "+_vm._s(_vm.converDatetime(_vm.data.created_at))+" ")])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }