<template>
  <v-card elevation="0" class="transparent px-2">
    <vue-html2pdf
      ref="certifPeserta"
      :enable-download="true"
      :preview-modal="false"
      :show-layout="true"
      :pdf-quality="2"
      :filename="certificateName"
      pdf-orientation="landscape"
      :manual-pagination="true"
      pdf-content-width="100%"
      pdf-format="legal"
      @hasDownloaded="hasGenerated($event)"
    >
      <section slot="pdf-content">
        <section
          class="pdf-item"
          :style="{
            backgroundImage: `url('${require('@/assets/images/certificate/bg-new-certif.jpg')}')`,
            backgroundSize: `100% 100%`,
            backgroundPosition: `center center`,
            backgroundRepeat: `no-repeat`,
            width: `100%`,
            height: `810px`,
            color: `#4B4B4B`,
          }"
        >
          <div class="style1">
            <div
              style="
                font-style: normal;
                text-align: center;
                font-family: Urbanist, sans-serif;
                font-weight: 900;
                font-size: 50px;
                line-height: auto;
                margin-top: 40dvh;
                color: black;
              "
            >
              {{ data.user_name }}
            </div>

            <div
              style="
                font-style: normal;
                font-family: Urbanist, sans-serif;
                font-weight: 800;
                font-size: 20px;
                text-align: center;
                line-height: 20px;
                margin-top: 10dvh;
                color: black;
              "
            >
              Periode {{ certificatePeriode(data.created_at) }}
            </div>

            <div
              style="
                font-style: normal;
                font-family: Inter, sans-serif;
                font-weight: 400;
                font-size: 18px;
                text-align: center;
                line-height: 20px;
                margin-top: 8.5dvh;
                margin-left: 3%;
                color: black;
              "
            >
              {{ converDatetime(data.created_at) }}
            </div>
          </div>
        </section>
        <!-- <section class="pdf-item" :style="{
          backgroundImage: `url('${require('@/assets/images/certificate/bg-new-certif-2-transcript.jpg')}')`,
          backgroundSize: `100% 100%`,
          backgroundPosition: `center center`,
          backgroundRepeat: `no-repeat`,
          width: `100%`,
          height: `810px`,
          color: `#4B4B4B`,
        }">
          <div v-if="Object.keys(data).length > 0" style="padding-top: 195px;">
            <div style="text-align: center;margin-bottom: 15px;">
              <img src="@/assets/images/logo-dash.png" alt="" srcset="" style="width:200px" />
            </div>
            <div 
              style="
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 26px;
                line-height: 47px;
                text-align: center;
                letter-spacing: 0.015em;
                margin-top: 10px;
              ">
              KOMPETENSI YANG DILATIH
            </div>
            <div 
              style="
                font-family: Roboto;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                margin-top: 4px;
              ">
              {{ data.program_name }}
            </div>
            <div
              style="
                font-family: Roboto;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                text-align: left;
                margin-top: 30px;
                display: flex;
                justify-content: center
              ">
              <div>
                <p style="font-weight: bold; font-size: 16px; text-align: center; margin-bottom: 10px; margin-top: 0px;">
                  Kompetensi</p>
                <div style="padding-left: 0px; margin-left: 0px;" v-for="(item, index) in data.competence" :key="index">
                  <p style="margin-bottom: 2px;font-size:14px;">
                    {{ index + 1 }}. {{ item }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> -->
      </section>
    </vue-html2pdf>
  </v-card>
</template>

<script>
import { LMS_API } from "@/constants/api";

import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";
// import { mapGetters } from "vuex";

export default {
  name: "certif-list",
  data() {
    return {
      dialog: true,
      showbtn: false,
      false: false,
      output: null,
      tampil: false,
      data: {},
      test: "10",
      member: {},
      program: "",
      is_prakerja: null,
      competence: [],
      dialogCertif: false,
      styleObject: {
        paddingTop: "100px",
        paddingBottom: "180px",
      },
      styleObject2: {
        paddingTop: "100px",
        paddingBottom: "100px",
      },
      styleObject3: {
        paddingTop: "100px",
        paddingBottom: "80px",
      },
      timer: "",
    };
  },
  computed: {
    // ...mapGetters(["user"]),
  },
  components: { VueHtml2pdf },
  created() {
    this.getData();
    // this.timer = setInterval(this.getData, 2000)
  },
  mounted() {},
  methods: {
    async getData() {
      this.false = true;
      await this.axios
        .get(
          `${LMS_API.MEMBER.ORDER.DETAIL_CERTIFICATE(this.$route.params.id)}`,
          { headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY } }
        )
        .then((response) => {
          let res = response.data.data;
          this.data = res;
          this.certificateName = res.user_name + " - " + res.program_name;
          setTimeout(() => {
            this.showCertif();
          }, 200);
        });
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    reloadPage() {
      window.location.reload();
      this.showbtn = true;
    },
    styleku() {
      if (this.test >= 6) {
        return this.styleObject;
      } else if (this.test === 7) {
        return this.styleObject2;
      } else if (this.test === 8) {
        return this.styleObject3;
      } else {
        return this.styleObject3;
      }
    },
    certificatePeriode(created_at) {
      return moment(created_at).locale("id").format("MMMM YYYY") + " - " + moment(created_at).locale("id").add(10, "M").format("MMMM YYYY");
    },
    converDatetime(created_at) {
      return moment(created_at).locale("id").format("DD MMMM YYYY");
    },
    currentDateTime(a) {
      return moment(a).lang("nl").format("L");
    },
    dateBirthTest(a) {
      return a;
    },
    dateExpired(a) {
      let currentDate = moment(a);
      let futureYear = moment(currentDate).add(1, "Y");
      return moment(futureYear).format("MMMM D, YYYY");
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      html2pdf().set(options).from(pdfContent).toPdf().output("blob");
      // .then((blob) => {
      //   let formData = new FormData();
      //   let certif = blob;

      //   // formData.append("file", certif, "Sertifikat.pdf");
      //   // formData.append("source", "upload");
      //   // formData.append("title", "Sertifikat");

      //   // this.$store
      //   //   .dispatch("media/create", formData)
      //   //   .then((res) => {
      //   //     if (res.status == 200) {
      //   //       this.id_certif = res.data.id;
      //   //       this.updateCertif(this.id_certif);
      //   //       console.log("result", this.id_certif);
      //   //     }
      //   //   })
      //   //   .catch((error) => {
      //   //     console.log("error", error);
      //   //   });
      // });
    },
    updateCertif(id) {
      let order_id = this.details.id;
      let sertificate_id = id;

      this.axios
        .put(
          `${LMS_API.MEMBER.ORDER.UPDATE_CERTIFICATE}`,
          { order_id, sertificate_id },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          // let res = response.data.data;
          this.loading = false;
          if (response.status == 200) {
            this.loading = false;
            // this.members = res;
            this.getDetailCertif(sertificate_id);
            // console.log("berhasil", res);
          }
        });
    },
    hasGenerated() {
      // console.log($event);
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    getDetail(order) {
      // this.$set(order, 'submiting', true);
      this.id = order;
      this.axios.get(`${LMS_API.PROGRAM.DETAIL(this.id)}`).then((response) => {
        // this.loading = false;
        let res = response.data.data;
        // this.loading = false;
        this.detail = res.program;
        // console.log('data detail',this.detail)
        this.showCertif(this.id);
      });
    },
    showCertif() {
      this.$refs.certifPeserta.generatePdf();
    },
    showCertifPeserta() {
      this.$refs.certifPeserta.generatePdf();
    },
    sortDesc($event) {
      this.dir = $event[0] ? "desc" : "asc";
      this.$emit("refresh-list", this.sort, this.dir);
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Saira+Condensed:700");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
tr:nth-child(even) {
  background-color: #f9fafc;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: 0px solid transparent !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: 0px solid transparent !important;
  font-size: 14px;
  font-weight: 600;
  color: #8492a6;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr {
  color: #3c4858;
}
.theme--light.v-data-table .v-data-footer {
  border-top: 0px solid transparent !important;
  padding: 16px !important;
}
.v-data-footer__select {
  justify-content: left !important;
  position: absolute;
  left: 20px;
}
.vue-html2pdf .layout-container[data-v-1fd3ad26] {
  background: #fff !important;
}
.vue-html2pdf tr:nth-child(even) {
  background-color: #fff !important;
}
.style1 {
  padding-top: 100px;
  padding-bottom: 188px;
}
.style2 {
  padding-top: 100px;
  padding-bottom: 180px;
}
.style3 {
  padding-top: 100px;
  padding-bottom: 148px;
}
.style4 {
  padding-top: 100px;
  padding-bottom: 116px;
}
.style5 {
  padding-top: 100px;
  padding-bottom: 0px;
}
@media print {
  @page {
    size: landscape;
  }
}
</style>
