<template>
  <div>
    <Snackbar ref="snackbar" />
    
    <v-card outlined flat class="mt-4 rounded-lg">
      <v-card-title>
        Sertifikat 
        <v-spacer></v-spacer>
        <v-btn 
          v-if="!showCertification" 
          color="#1F2A37"
          style="border: 1px solid #E5E7EB"
          class="rounded-lg"
          x-small
          outlined
          @click="onResetFormCertificate()">
          Tambah
        </v-btn>
        <div v-else>
          <v-btn 
            color="primary"
            x-small
            depressed
            class="rounded-lg"
            @click="onSaveTalentCertificate()">
            Simpan
          </v-btn>
          <v-btn 
            color="#1F2A37"
            style="border: 1px solid #E5E7EB"
            class="ml-2 rounded-lg"
            x-small
            outlined
            @click="onResetFormCertificate()">
            Tutup
          </v-btn>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-show="!showCertification">
  
        <v-alert 
          color="#F3F4F6"
          class="rounded-lg body-2">
          Sertifikat resmi dari sistem akan otomatis terisi ke resume Anda, Jika Anda memiliki sertifikat lainnya silahkan tambahkan secara manual
        </v-alert>
  
        <div class="text-left" v-if="certificate_list.length > 0">
  
          <v-row v-for="(certificate, index) in certificate_list" :key="index">
            <v-col cols="10" class="text-left">
              <div class="justify-start d-flex align-center font-weight-bold" style="gap: 10px;">
                <div class="black--text">{{ certificate.talent_certificate_institution }}</div>
                <div>-</div>
                <div class="black--text">{{ certificate.talent_certificate_name }}</div>
              </div>
              
              <ul class="justify-between d-flex align-center" style="gap: 35px;">
                <li class="black--text">{{ certificate.talent_certificate_type }}</li>
                <li class="black--text">{{ convertDate(certificate.talent_certificate_date) }}</li>
              </ul>
            </v-col>
            <v-col cols="2" class="justify-end text-right d-flex align-end">
              <v-btn
                color="#1F2A37"
                class="rounded-lg"
                x-small
                icon
                @click="onOpenEditCertificate(certificate, index)">
                <v-icon size="20">ri-pencil-line</v-icon>
              </v-btn>
              <v-btn
                color="#1F2A37"
                class="rounded-lg"
                x-small
                icon
                @click="onDeleteCertificate(certificate)">
                <v-icon size="20">ri-delete-bin-7-line</v-icon>
              </v-btn>
            </v-col>
            <v-col cols=12>
              <v-divider></v-divider>
            </v-col>
          </v-row>
  
        </div>
  
      </v-card-text>
      <v-expand-transition>
        <v-card-text v-show="showCertification">
          <v-form 
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-alert 
              color="#F3F4F6"
              class="rounded-lg body-2">
              Sertifikat resmi dari sistem akan otomatis terisi ke resume Anda, Jika Anda memiliki sertifikat lainnya silahkan tambahkan secara manual
            </v-alert>
    
            <div>
              <div class="mb-1 font-weight-medium">Nama Sertifikasi</div>
              <v-text-field
                outlined
                class="rounded-lg"
                dense
                :rules="[rules.required]"
                v-model="certificates.talent_certificate_name"
                placeholder="Tuliskan Nama Sertifikasi">
              </v-text-field>
            </div>
    
            <div>
              <div class="mb-1 font-weight-medium">Jenis Ujian (Contoh: TOEFL)</div>
              <v-text-field
                outlined
                class="rounded-lg"
                dense
                :rules="[rules.required]"
                v-model="certificates.talent_certificate_type"
                placeholder="Tuliskan Jenis Ujian">
              </v-text-field>
            </div>
    
            <div>
              <div class="mb-1 font-weight-medium">Lembaga Sertifikasi</div>
              <v-text-field
                outlined
                class="rounded-lg"
                dense
                :rules="[rules.required]"
                v-model="certificates.talent_certificate_institution"
                placeholder="Tuliskan Nama Lembaga Sertifikasi">
              </v-text-field>
            </div>
    
            <div>
              <div class="mb-1 font-weight-medium">Tanggal Sertifikasi</div>
              <v-menu
                v-model="talent_certificate_date"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    v-on="on"
                    v-bind="attrs"
                    class="rounded-lg"
                    dense
                    readonly
                    :rules="[rules.required]"
                    v-model="convertTalentCertificateDate"
                    placeholder="Tuliskan Tahun Mulai">
                  </v-text-field>
                </template>
                <v-date-picker
                  type="date"
                  v-model="certificates.talent_certificate_date"
                  @input="talent_certificate_date = false">
                </v-date-picker>
              </v-menu>
              <!-- <v-text-field
                outlined
                class="rounded-lg"
                dense
                v-model="certificates.talent_certificate_date"
                placeholder="Tuliskan Tanggal Sertifikasi">
              </v-text-field> -->
            </div>
          </v-form>
        </v-card-text>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import { JOB_PORTAL_API } from "@/constants/api";

import  { mapGetters } from "vuex"
import moment from "moment";
import Snackbar from "@/components/Snackbar";

export default {
  props: {
    talent_id: {
      type: String,
      default: ""
    }
  },
  components: {
    Snackbar
  },
  data: () => ({
    valid: true,
    showCertification: false,

    state_index: 0,
    state_id: "",
    state_form: "create",

    // MENU DATE PICKER CERTIFICATE
    talent_certificate_date: false,

    rules: {
      required: value => !!value || 'field tidak boleh kosong',
    },

    // CERTIFICATE
    certificate_list: [],
    certificates: {
      talent_certificate_name: "",
      talent_certificate_type: "",
      talent_certificate_institution: "",
      talent_certificate_date: ""
    },
  }),
  computed: {
    ...mapGetters(["user_sistem"]),
    convertTalentCertificateDate() {
      return this.certificates.talent_certificate_date ? moment(this.certificates.talent_certificate_date).format("DD MMMM YYYY") : ""
    }
  },
  mounted() {
    this.onGetListCertificate()
  },
  methods: {
    convertDate(date) {
      return date ? moment(date).locale("id").format("DD MMM YYYY") : "No Date"
    },
    
    onResetFormCertificate() {
      
      this.showCertification = !this.showCertification

      this.certificates = {
        talent_certificate_name: "",
        talent_certificate_type: "",
        talent_certificate_institution: "",
        talent_certificate_date: ""
      }

      this.state_form = "create"
    },

    async onGetListCertificate() {
      await this.axios.get(`${JOB_PORTAL_API.CERTIFICATE.GET_LIST}`, {
        params: {
          talent_id: this.talent_id
        }
      })
      .then((response) => {
        this.certificate_list = response.data.data
      }).catch((error) => {
        this.$refs.snackbar.open('error', error.response.data.message)
      })
    },

    // Certificate Add
    async onAddCertificate() {

      if(!this.$refs.form.validate()) return

      let talentCertificates = this.form.talent_certificates

      if(this.certificates.talent_certificate_name) {
        talentCertificates.push(this.certificates)
      }

      this.form.talent_certificates = talentCertificates

      this.certificates = {
        talent_certificate_name: "",
        talent_certificate_type: "",
        talent_certificate_institution: "",
        talent_certificate_date: ""
      }

      this.showCertification = false

      this.$refs.snackbar.open('success', 'Sertifikat berhasil ditambahkan!')

      await this.onSaveTalentCertificate()
    },

    onValidateCertificate() {
      
      let isValid = true

      if(!this.certificates.talent_certificate_name) {
        this.$refs.snackbar.open('error', 'Nama sertifikat tidak boleh kosong!')
        isValid = false
      }

      if(!this.certificates.talent_certificate_type) {
        this.$refs.snackbar.open('error', 'Tipe sertifikat tidak boleh kosong!')
        isValid = false
      }

      if(!this.certificates.talent_certificate_institution) {
        this.$refs.snackbar.open('error', 'Institusi sertifikat tidak boleh kosong!')
        isValid = false
      }

      if(!this.certificates.talent_certificate_date) {
        this.$refs.snackbar.open('error', 'Tanggal sertifikat tidak boleh kosong!')
        isValid = false
      }

      return isValid

    },

    // save data certificate
    async onSaveTalentCertificate() {

      if(this.$refs.form.validate()) {
        if(this.state_form == "create") {
          await this.axios.post(`${JOB_PORTAL_API.CERTIFICATE.CREATE(this.talent_id)}`, this.certificates)
          .then(() => {
            this.$refs.snackbar.open('success', 'Data berhasil disimpan!')
          }).catch((error) => {
            this.$refs.snackbar.open('error', error.response.data.message)
          })
        }else {
          await this.axios.put(`${JOB_PORTAL_API.CERTIFICATE.UPDATE(this.state_id)}`, this.certificates)
          .then(() => {
            this.$refs.snackbar.open('success', 'Data berhasil disimpan!')
  
            
          }).catch((error) => {
            this.$refs.snackbar.open('error', error.response.data.message)
          })
        }
  
        await this.onGetListCertificate()
  
        this.showCertification = false
      }

    },

    // Certificate Edit
    async onOpenEditCertificate(item, index) {
      let talentCertificates = this.certificate_list[index]

      this.certificates = {
        talent_certificate_name: talentCertificates.talent_certificate_name,
        talent_certificate_type: talentCertificates.talent_certificate_type,
        talent_certificate_institution: talentCertificates.talent_certificate_institution,
        talent_certificate_date: talentCertificates.talent_certificate_date
      }

      
      this.showCertification = true
      
      this.state_index = index
      this.state_form = "edit"
      this.state_id = item.talent_certificate_id
    },

    // Certificate Update
    async onUpdateCertificate() {
      let talentCertificates = this.form.talent_certificates

      talentCertificates[this.state_index] = this.certificates

      this.form.talent_certificates = talentCertificates

      this.$emit("save", talentCertificates)

      this.certificates = {
        talent_certificate_name: "",
        talent_certificate_issuer: "",
        talent_certificate_date: "",
        talent_certificate_description: ""
      }

      // this.$refs.snackbar.open('success', 'Sertifikat berhasil diubah!')


      // await this.onSaveTalentProfileResume()

      this.showCertification = false

      this.state_form = "create"
      this.state_index = 0
    },

    // Certificate Delete
    async onDeleteCertificate(item) {
      await this.axios.delete(`${JOB_PORTAL_API.CERTIFICATE.DELETE(item.talent_certificate_id)}`)
      .then(() => {
        this.$refs.snackbar.open('success', 'Sertifikat berhasil dihapus!')
      }).catch((error) => {
        this.$refs.snackbar.open('error', error.response.data.message)
      })

      await this.onGetListCertificate()
    },
  }
}
</script>