<template>
  <div 
    v-if="user_sistem.talent_language.length > 0"
    style="
      margin-top: 2rem;
      margin-bottom: 2rem;
      border: 1px solid #E5E7EB; 
      border-radius: 10px;
      font-family: 'Inter', sans-serif;
      padding: 20px;">
    <div 
      style="
        font-size: 18px; 
        font-weight: 600; 
        margin-bottom: 1rem;
      ">
      Bahasa
    </div>
    <div v-for="(lang, i) in user_sistem.talent_language.split(',')" :key="i">
      <div 
        style="
          margin-top: 1rem;
          font-size: 14px;
          color: #374151;
        ">
        {{ lang }}
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'AccountResumeLanguage',
  computed: {
    ...mapGetters(['user_sistem'])
  }
}
</script>