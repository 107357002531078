<template>
	<v-row :no-gutters="$vuetify.breakpoint.smAndUp" class="px-4">
		<div class="font-weight-bold primary-text mb-4 text-h5">{{ act.title }}</div>
		<v-card 
			elevation="0" style="min-height: 40vh; width: 100%" outlined 
			class="rounded-lg pa-6">
			<div class="font-weight-medium mb-3" 
				v-html="act.title" 
				style="font-size: 24px;"></div>
			<p class="" v-html="act.theory.description"></p>
			<div class="pa-3 text-center">
				<img src="@/assets/images//illustrations/illustration-praktek-mandiri.svg" class="mx-auto mb-2" width="250" />
				<br />
				<v-btn
					download
					depressed
					color="primary"
					class="mx-auto text-center rounded-lg"
					:href="act.theory.file.url"
					target="_blank"
					:loading="btn_loading"
					v-if="act.is_complete === true"
				>
					Download Assesment
				</v-btn>
				<v-btn
					download
					depressed
					color="primary"
					class="mx-auto text-center rounded-lg"
					:href="act.theory.file.url"
					target="_blank"
					:loading="btn_loading"
					@click="getUnlock()"
					v-else>
					Download Assesment
				</v-btn>
			</div>
		</v-card>
	</v-row>
</template>

<script>
import { LMS_API } from "@/constants/api"

import moment from 'moment'

export default {
	props: {
		act: Object,
		detail_topic: Object,
		id_topic: String,
		id_act: String,
	},
	data() {
		return {
			btn_loading: false,
		}
	},
	methods: {
		async getHasDoneActivity() {
			const data = {
				member_redeem_id: this.$route.params.id,
				topic_id: this.id_topic,
				activity_id: this.id_act,
			};
			await this.axios.post(`${LMS_API.ACTIVITY.HAS_DONE}`, data)
			.then(() => {
				this.btn_loading = false
				this.$emit('getDetailClass')
			}).catch(() => {
				this.btn_loading = false
			});
		},
		getUnlock() {
			let next_topic_at = this.detail_topic.next_topic_at
			let date_now = new Date()
			let next_topic_date = moment.utc(next_topic_at).format('YYYY-MM-DD HH:mm:ss')
			let date_now_at = moment.utc(date_now).format('YYYY-MM-DD HH:mm:ss')
			let next_topic_date_res = moment.utc(next_topic_at).locale("id").format('DD MMMM YYYY HH:mm:ss')

			// Check apakah aktivitas ini sudah selesai atau belum
			if(!this.act.has_done_this_activity) {
				this.getHasDoneActivity()
			}
			
			// check if next topic is under next topci at
			if(next_topic_at != null && date_now_at < next_topic_date) {
				// Chek if this activity is last in topic
        if(this.act.is_last_activity) {
          return this.notification = {
            state: true,
            message: `Mohon Maaf, Topik selanjutnya dapat di akses pada <b class="font-weight-bold">${next_topic_date_res}</b>`,
          };
        }
      }

			this.btn_loading = true
			const data = {
				program_id: this.$route.params.id,
				topic_id: this.id_topic,
				activity_id: this.id_act,
			};
			this.axios.post(`${LMS_API.ACTIVITY.UNLOCK}`, data)
			.then(async () => {
				this.btn_loading = false
				this.$emit('getDetailClass')
				this.$emit('getActFalse', this.id_topic, this.id_act)
			})
			.catch(() => {
				this.btn_loading = false
			});
		},
	}
}
</script>