<template>
  <v-row>
    <v-col cols="12">
      <v-container>

        <v-breadcrumbs :items="breadcrumbs" class="px-0">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

        <Snackbar ref="snackbar" />

        <v-card flat>
          <v-card-title>
            <div class="headline font-weight-medium">Data Diri</div>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation>
              <v-row>
                <v-col>
                  <div>
                    <div class="mb-1 font-weight-medium required">Nama Depan</div>
                    <v-text-field
                      outlined
                      dense
                      v-model="form.first_name"
                      :rules="firstNameRules"
                      required
                      class="rounded-lg"
                      placeholder="Tuliskan Nama Depan">
                    </v-text-field>
                  </div>
                </v-col>
                <v-col>
                  <div>
                    <div class="mb-1 font-weight-medium required">Nama Belakang</div>
                    <v-text-field
                      outlined
                      dense
                      v-model="form.last_name"
                      :rules="lastNameRules"
                      required
                      class="rounded-lg"
                      placeholder="Tuliskan Nama Belakang">
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>
      
              <div>
                <div class="mb-1 font-weight-medium required">Tanggal Lahir</div>
                <v-menu
                  v-model="birthdateMenu"
                  :close-on-content-click="false"
                  max-width="290">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formatBirthOfDate"
                      :rules="birthDateRules"
                      required
                      clearable
                      outlined
                      dense
                      readonly
                      class="rounded-lg"
                      placeholder="Pilih Tanggal Lahir"
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="form.date_of_birth = null">
                    </v-text-field>
                  </template>
                  <v-date-picker
                    type="date"
                    v-model="form.date_of_birth"
                    @input="birthdateMenu = false">
                  </v-date-picker>
                </v-menu>
              </div>
      
              <v-row>
                <v-col>
                  <div>
                    <div class="mb-1 font-weight-medium required">Jenis Kelamin</div>
                    <v-select
                      dense
                      outlined
                      v-model="form.gender"
                      :rules="genderRules"
                      required
                      :items="genders"
                      class="rounded-lg"
                      item-text="label"
                      item-value="value"
                      placeholder="Pilih Jenis Kelamin">
                    </v-select>
                  </div>
                </v-col>
                <v-col>
                  <div>
                    <div class="mb-1 font-weight-medium required">Nomor Telephone</div>
                    <v-text-field
                      dense
                      outlined
                      v-model="form.phone"
                      :rules="phoneRules"
                      required
                      class="rounded-lg"
                      placeholder="Tuliskan Nomor Telephone">
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>
      
              <div>
                <div class="mb-1 font-weight-medium required">Alamat Domisili</div>
                <v-textarea
                  dense
                  outlined
                  v-model="form.meta.alamat_rumah"
                  :rules="addressRules"
                  required
                  auto-grow
                  class="rounded-lg"
                  placeholder="Tuliskan Alamat Domisili">
                </v-textarea>
              </div>
      
              <v-row>
                <v-col>
                  <div>
                    <div class="mb-1 font-weight-medium required">Provinsi</div>
                    <v-autocomplete
                      dense
                      outlined
                      single-line
                      autocomplete="off"
                      v-model="form.meta.provinsi"
                      :rules="provinceRules"
                      required
                      :items="provinces"
                      @change="() => {
                        location().district()
                        location().subdistrict()
                        location().village()
                        form.meta.kabupaten = ''
                        form.meta.kecamatan = ''
                        form.meta.kelurahan = ''
                        form.meta.kode_pos = ''
                      }"
                      item-text="label"
                      item-value="value"
                      class="rounded-lg"
                      placeholder="Pilih provinsi">
                    </v-autocomplete>
                  </div>
                </v-col>
                <v-col>
                  <div>
                    <div class="mb-1 font-weight-medium required">Kabupaten</div>
                    <v-autocomplete
                      dense
                      outlined
                      single-line
                      autocomplete="off"
                      v-model="form.meta.kabupaten"
                      :rules="cityRules"
                      required
                      :items="district"
                      @change="() => {
                        location().subdistrict()
                        form.meta.kecamatan = ''
                        form.meta.kelurahan = ''
                        form.meta.kode_pos = ''
                      }"
                      item-text="label"
                      item-value="value"
                      class="rounded-lg"
                      placeholder="Tuliskan Kabupaten">
                    </v-autocomplete>
                  </div>
                </v-col>
              </v-row>
      
              <v-row class="my-0">
                <v-col>
                  <div>
                    <div class="mb-1 font-weight-medium required">Kecamatan</div>
                    <v-autocomplete
                      dense
                      outlined
                      single-line
                      autocomplete="off"
                      v-model="form.meta.kecamatan"
                      :rules="subDistrictRules"
                      required
                      :items="sub_district"
                      @change="() => {
                        location().village()
                        form.meta.kelurahan = ''
                        form.meta.kode_pos = ''
                      }"
                      item-text="label"
                      item-value="value"
                      class="rounded-lg"
                      placeholder="Pilih Kecamatan">
                    </v-autocomplete>
                  </div>
                </v-col>
                <v-col>
                  <div>
                    <div class="mb-1 font-weight-medium required">Kelurahan</div>
                    <v-autocomplete
                      dense
                      outlined
                      single-line
                      autocomplete="off"
                      v-model="form.meta.kelurahan"
                      :rules="villageRules"
                      required
                      :items="villages"
                      item-text="label"
                      item-value="value"
                      class="rounded-lg"
                      placeholder="Tuliskan Kelurahan">
                    </v-autocomplete>
                  </div>
                </v-col>
              </v-row>
      
              <v-row class="my-0">
                <v-col>
                  <div>
                    <div class="mb-1 font-weight-medium required">RT</div>
                    <v-text-field
                      outlined
                      dense
                      v-model="form.meta.rt"
                      :rules="rtRules"
                      required
                      class="rounded-lg"
                      placeholder="Tuliskan RT">
                    </v-text-field>
                  </div>
                </v-col>
                <v-col>
                  <div>
                    <div class="mb-1 font-weight-medium required">RW</div>
                    <v-text-field
                      outlined
                      dense
                      v-model="form.meta.rw"
                      :rules="rwRules"
                      required
                      class="rounded-lg"
                      placeholder="Tuliskan RW">
                    </v-text-field>
                  </div>
                </v-col>
                <v-col>
                  <div>
                    <div class="mb-1 font-weight-medium required">Kode Pos</div>
                    <v-text-field
                      outlined
                      dense
                      v-model="form.meta.kode_pos"
                      :rules="postalCodeRules"
                      required
                      class="rounded-lg"
                      placeholder="Tuliskan Kode Pos">
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>
      
              <!-- <div>
                <div class="mb-1 font-weight-medium required">Status Perkawinan</div>
                <v-select
                  outlined
                  dense
                  v-model="form.meta.status_perkawinan"
                  class="rounded-lg"
                  placeholder="Pilih Status Perkawinan">
                </v-select>
              </div> -->
      
              <div>
                <img
                  v-if="form.photo_url"
                  class="mt-3 rounded-lg"
                  style="height: 200px; border-radius: 20px;"
                  contain
                  :lazy-src="form.photo_url"
                  :src="form.photo_url"/>
                <div class="mb-1 font-weight-medium">Update Foto Profile</div>
                <input
                  style="
                    display: block;
                    width: 100%;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #212529;
                    background-color: #fff;
                    background-clip: padding-box;
                    border: 1px solid #ced4da;
                    border-radius: 0.5rem;
                    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                    margin-bottom: 15px;
                    margin-top: 5px;
                    outline: none;
                    cursor: pointer;
                  "
                  accept="image/*"
                  @change="onFileChange"
                  ref="file"
                  name="file"
                  aria-describedby="file_input_help" 
                  id="file_input" 
                  type="file" />
                <span class="caption">Ukuran Maksimal 5 Mb</span>
              </div>
            </v-form>

            <v-divider class="mt-4"></v-divider>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="px-4 rounded-lg black--text"
              style="border: 1px solid #E5E7EB;"
              depressed
              outlined
              small
              :disabled="process.run"
              @click="() => { $router.push('/account') }">
              Kembali
            </v-btn>
            <v-btn
              class="px-4 rounded-lg"
              color="primary"
              dark
              depressed
              :loading="process.run"
              small
              @click="updateAccountJobPortal()">
              Simpan
            </v-btn>
          </v-card-actions>
        </v-card>


      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { LMS_API, JOB_PORTAL_API } from "@/constants/api";

import moment from "moment";
import { mapGetters } from "vuex";
import Snackbar from "@/components/Snackbar";

export default {
  name: "AccountPassword",
  components: {
    Snackbar
  },
  data: () => ({
    valid: true,

    firstNameRules: [
      v => !!v || 'Nama Depan tidak boleh kosong',
      // validation for only alphabet, space, -, `, and ' character
      v => /^[a-zA-Z\s-.`']*$/.test(v) || 'Nama Belakang hanya boleh berisi huruf, spasi, tanda -, tanda `, dan tanda \'',
    ],
    lastNameRules: [
      v => !!v || 'Nama Belakang tidak boleh kosong',
      // validation for only alphabet, space, -, `, and ' character
      v => /^[a-zA-Z\s-.`']*$/.test(v) || 'Nama Belakang hanya boleh berisi huruf, spasi, tanda -, tanda `, dan tanda \'',
    ],
    phoneRules: [
      v => !!v || 'Nomor Telephone tidak boleh kosong',
      // validation for only number
      v => /^[0-9]*$/.test(v) || 'Nomor Telephone hanya boleh berisi angka',
      // validation for exactly 10-13 digits
      v => v.length >= 10 && v.length <= 13 || 'Nomor Telephone harus 10-13 digit',
    ],
    birthDateRules: [
      v => !!v || 'Tanggal Lahir tidak boleh kosong',
    ],

    genderRules: [
      v => !!v || 'Jenis Kelamin tidak boleh kosong',
    ],

    addressRules: [
      v => !!v || 'Alamat Domisili tidak boleh kosong',
    ],
    provinceRules: [
      v => !!v || 'Provinsi tidak boleh kosong',
    ],
    cityRules: [
      v => !!v || 'Kabupaten tidak boleh kosong',
    ],
    subDistrictRules: [
      v => !!v || 'Kecamatan tidak boleh kosong',
    ],
    villageRules: [
      v => !!v || 'Kelurahan tidak boleh kosong',
    ],
    rtRules: [
      v => !!v || 'RT tidak boleh kosong',
    ],
    rwRules: [
      v => !!v || 'RW tidak boleh kosong',
    ],
    postalCodeRules: [
      v => !!v || 'Kode Pos tidak boleh kosong',
      // validation for only number
      v => /^[0-9]*$/.test(v) || 'Kode Pos hanya boleh berisi angka',
      // validation for exactly 5 digits
      v => v.length === 5 || 'Kode Pos harus 5 digit',
    ],

    birthdateMenu: false,

    process: {
      run: false,
    },

    form: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      date_of_birth: "",
      gender: "",
      photo_url: "",
      meta: {
        alamat_rumah: "",
        provinsi: "",
        kabupaten: "",
        kecamatan: "",
        kelurahan: "",
        nama_sekolah: "",
        rt: "",
        rw: "",
        kode_pos: "",
        status_perkawinan: ""
      }
    },

    filePhoto: null,

    genders: [
      {
        label: "Laki-laki",
        value: "Laki-laki",
      },
      {
        label: "Perempuan",
        value: "Perempuan",
      },
    ],
    breadcrumbs: [
      {
        text: "Profile & Resume",
        disabled: false,
        href: "/account",
      },
      {
        text: "Data Diri",
        disabled: true,
      },
    ],

    provinces: [],
    district: [],
    sub_district: [],
    villages: []
  }),
  watch: {
    'form.meta.provinsi' : function() {
      this.location().district()
    },
    'form.meta.kabupaten' : function() {
      this.location().subdistrict()
    },
    'form.meta.kecamatan' : function() {
      this.location().village()
    },
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["user_sistem"]),
    formatBirthOfDate() {
      return this.form.date_of_birth ? moment(this.form.date_of_birth).format("DD MMMM YYYY") : ''
    }
  },
  mounted() {
    this.setForm()
    this.location().province()
  },
  methods: {

    onFileChange(e) {
      const file = e.target.files[0];
      // this.form.photo_url = URL.createObjectURL(file);
      this.filePhoto = file

      if(file) {
        this.updatePhotoProfile(file)
      }
    },

    setForm() {
      this.axios.get(`${JOB_PORTAL_API.USER.GET_ME}`)
      .then((response => {
        let res = response.data
        this.form = {
          first_name: res.talent_first_name,
          last_name: res.talent_last_name,
          phone: res.talent_phone,
          email: res.talent_email,
          date_of_birth: res.talent_birth_date ? moment(res.talent_birth_date).format("YYYY-MM-DD") : "",
          gender: res.talent_gender,
          photo_url: res.talent_photo_url,
          meta: {
            alamat_rumah: res.talent_address?.address_address_line,
            provinsi: res.talent_address?.address_province?.province_code,
            kabupaten: res.talent_address?.address_district?.district_code,
            kecamatan: res.talent_address?.address_sub_district?.sub_district_code,
            kelurahan: res.talent_address?.address_village?.village_code,
            nama_sekolah: "",
            rt: res.talent_address?.address_rt,
            rw: res.talent_address?.address_rw,
            kode_pos: res.talent_address?.address_postal_code,
            status_perkawinan: ""
          }
        }
      }))
    },

    location() {
      let _self = this
      return {
        async province() {
          await _self.axios.get(`${JOB_PORTAL_API.LOCATION.PROVINCE}`)
          .then((response) => {
            _self.provinces = response.data
          })
        },
        async district() {
          await _self.axios.get(`${JOB_PORTAL_API.LOCATION.DISTRICT(_self.form.meta.provinsi)}`)
          .then((response) => {
            _self.district = response.data
          })
        },
        async subdistrict() {
          await _self.axios.get(`${JOB_PORTAL_API.LOCATION.SUB_DISTRICT(_self.form.meta.kabupaten)}`)
          .then((response) => {
            _self.sub_district = response.data
          })
        },
        async village() {
          await _self.axios.get(`${JOB_PORTAL_API.LOCATION.VILLAGE(_self.form.meta.kecamatan)}`)
          .then((response) => {
            _self.villages = response.data
          })
        }
      }
    },

    async updatePhotoProfile(file) {
      if(file) {
        const formData = new FormData()
        formData.append('file', file)
        this.process.run = true
        await this.axios.post(`${JOB_PORTAL_API.USER.UPLOAD_PHOTO}`, formData)
        .then((response) => {
          this.process.run = false
          this.form.photo_url = response.data.Location
          this.$refs.snackbar.open("primary", "Berhasil memperbaharui photo profile");
        }).catch(() => {
          this.process.run = false
          this.$refs.snackbar.open("error", "Gagal memperbaharui photo profile");
        })
      }else {
        this.$refs.snackbar.open("primary", "Photo profile file tidak ditemukan");
      }
    },

    async updateAccountJobPortal() {

      if (this.$refs.form.validate()) {
        
        this.process.run = true
  
        let FormData = {
          talent_first_name: this.form.first_name,
          talent_last_name: this.form.last_name,
          talent_email: this.form.email,
          talent_phone: this.form.phone,
          talent_birth_date: this.form.date_of_birth,
          talent_gender: this.form.gender,
          talent_address: {
            address_address_line: this.form.meta.alamat_rumah,
            address_address_type: "PHYSICAL",
            address_province_code: this.form.meta.provinsi,
            address_district_code: this.form.meta.kabupaten,
            address_sub_district_code: this.form.meta.kecamatan,
            address_village_code: this.form.meta.kelurahan,
            address_postal_code: this.form.meta.kode_pos,
            address_rt: this.form.meta.rt,
            address_rw: this.form.meta.rw,
          },
        }
  
        await this.axios.put(`${JOB_PORTAL_API.USER.UPDATE}`, FormData)
          .then((response) => {
            this.process.run = false;
  
            localStorage.setItem("sistem_auth", JSON.stringify(response.data));
            this.$store.dispatch("get_user_sistem");
  
  
            this.$refs.snackbar.open("primary", "Berhasil memperbaharui data diri");
  
            // await this.updatePhotoProfile()
            this.updateIsProfileSubmited()
  
            // setTimeout(() => {
            //   this.$router.push({ name: "my-class" });
            // }, 3000);
        }).catch(() => {
          this.process.run = false;
          this.$refs.snackbar.open("error", "Gagal memperbaharui data diri");
        })
      }else {
        this.process.run = false
        this.$refs.snackbar.open("error", "Silahkan lengkapi data diri");
      }
    },
    
    async updateIsProfileSubmited() {


      let formData = {
        fullname: this.form.first_name + ' ' + this.form.last_name,
        date_of_birth: moment(this.form.date_of_birth).format("YYYY-MM-DD"),
        email: this.form.email,
        phone: this.form.phone,
        gender: this.form.gender,
        meta: {
          code: this.form.meta.kode_pos,
          nis: "",
          alamat_rumah: this.form.meta.alamat_rumah,
          kabupaten: this.form.meta.kabupaten,
          kecamatan: this.form.meta.kecamatan,
          kelurahan: this.form.meta.kelurahan,
          nama_sekolah: "",
          provinsi: this.form.meta.provinsi
        }
      }

      await this.axios.put(`${LMS_API.USER.UPDATE}`, formData)
      .then((response) => {
        let res = response.data
        
        if(res.status == 200) {
          this.process.run = false;
  
          localStorage.setItem("bakat_auth", JSON.stringify(res.data));
          this.$store.dispatch("get_user");
          this.$refs.snackbar.open("primary", "Berhasil memperbaharui data diri");
  
          setTimeout(() => {
            this.$router.push({ name: "my-class" });
          }, 3000);
        }

      }).catch(() => {
        this.process.run = false;
        this.$refs.snackbar.open("error", "Gagal memperbaharui data diri");
      })

    },
  }
}
</script>

<style>
input[type=file]::file-selector-button {
  background: #000;
  color: #fff;
  padding: 10px 20px;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-right: 1rem;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
</style>