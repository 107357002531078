<template>
  <div>
    <v-snackbar
      :timeout="snackbar.timeout"
      v-model="snackbar.state"
      top
      :color="snackbar.color"
      pre
      text
    >
      <v-icon left :color="snackbar.color">{{ snackbar.icon }}</v-icon>
      {{ snackbar.text }}
    </v-snackbar>

    <v-dialog v-model="dialog.deviceUnsupported" width="800" persistent>
      <v-card>
        <v-card-text
          class="text-center content-check"
          style="padding: 90px; line-height: 36px"
        >
          <div
            v-if="dialog.type == 'camera'"
            style="
              font-weight: 600;
              font-size: 24px;
              color: #3c4858;
              margin-bottom: 38px;
            "
          >
            Perangkat Kamera Anda Tidak dapat Bekerja dengan baik
          </div>
          <div
            v-if="dialog.type == 'microphone'"
            style="
              font-weight: 600;
              font-size: 24px;
              color: #3c4858;
              margin-bottom: 38px;
            "
          >
            Perangkat Microphone Tidak Dapat Bekerja dengan baik. Untuk
            mendeteksi suara anda
          </div>
          <v-alert text warning>
            <span class="fs-14 color-warning-cutsom">
              Anda tidak diperkenankan melanjutkan tes. Silakan hubungi admin
              SISTEM melalui WA 0823-2565-5099 terkait kendala yang dihadapi atau
              mengganti perangkat/device yang digunakan dan pastikan
              perangkat/device dapat berfungsi dengan baik untuk dapat
              <b>melanjutkan tes</b> dan <b>sertifikat dapat dikirimkan.</b>
            </span>
          </v-alert>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            large
            color="primary"
            @click="
              () => {
                this.$route.meta.prevent_move = false;
                $router.push('/class');
              }
            "
          >
            Tunda Sesi Test</v-btn
          >
          <!--  <v-btn
            large
            :loading="process.run"
            @click="next_test(dialog.type)"
            elevation="0"
            color="primary">
            Baik, saya lanjutkan
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card
      rounded="lg"
      elevation="0"
      width="1200"
      height="630"
      class="ma-auto my-10"
    >
      <v-card-text class="pa-0">
        <v-row no-gutters>
          <v-col
            cols="3"
            class="px-8"
            style="
              border-radius: 12px 0px 0px 12px;
              height: 630px;
              background-color: #f9fafc;
            "
          >
            <div
              class="mb-7"
              style="
                margin-top: 160px;
                color: #273444;
                font-weight: 600;
                font-size: 18px;
              "
            >
              Verifikasi Perangkat
            </div>

            <!-- Audio -->
            <div class="mb-4">
              <div class="primary--text" style="font-size: 16px">
                <v-icon size="20" v-if="!checkedAudio" color="primary"
                  >mdi-circle-outline</v-icon
                >
                <v-icon size="20" v-else color="primary"
                  >mdi-check-circle</v-icon
                >
                Pengecekan Audio
              </div>
              <div
                v-if="showCheckAudioLayer_2" 
                class="primary--text"
                style=" font-size: 12px">
                Audio berhasil tersambung
              </div>
            </div>

            <!-- Camera -->
            <div class="mb-4">
              <div
                :style="
                  !showCheckCamera
                    ? 'color: #8492A6;font-size: 16px'
                    : 'color: #1C64F2;font-size: 16px;'
                "
              >
                <v-icon size="20" v-if="!checkedCamera" color="#8492A6"
                  >mdi-circle-outline</v-icon
                >
                <v-icon size="20" v-else color="#1C64F2"
                  >mdi-check-circle</v-icon
                >
                Pengecekan Camera
              </div>
              <div
                :style="
                  response.contentCamera == ''
                    ? 'color: #C0CCDA;font-size: 12px'
                    : 'color: #1C64F2;font-size: 12px'
                "
              >
                {{ response.contentCamera }}
              </div>
              <div
                :style="
                  response.contentFace == ''
                    ? 'color: #C0CCDA;font-size: 12px'
                    : 'color: #1C64F2;font-size: 12px'
                "
              >
                {{ response.contentFace }}
              </div>
            </div>

            <!-- Microfone -->
            <div class="mb-4">
              <div style="color: #8492a6; font-size: 16px">
                <v-icon size="20" v-if="!checkedMic" color="#8492A6"
                  >mdi-circle-outline</v-icon
                >
                <v-icon size="20" v-else color="#1C64F2"
                  >mdi-check-circle</v-icon
                >
                Pengecekan Microphone
              </div>
              <div
                :style="
                  response.contentMic == ''
                    ? 'color: #C0CCDA;font-size: 12px'
                    : 'color: #1C64F2;font-size: 12px'
                "
              >
                {{ response.contentMic }}
              </div>
              <div
                :style="
                  response.contentSpeech == ''
                    ? 'color: #C0CCDA;font-size: 12px'
                    : 'color: #1C64F2;font-size: 12px'
                "
              >
                {{ response.contentSpeech }}
              </div>
            </div>
          </v-col>
          <v-col cols="9" class="text-start pa-10" style="height: 630px">
            <!-- Audio -->
            <div v-show="showCheckAudio" class="content-check">
              <!-- Layer 1 -->
              <div v-if="showCheckAudioLayer_1">
                <v-img
                  width="360"
                  height="240"
                  :src="require('@/assets/images/detection/ilutration_audio.png')"
                >
                </v-img>
                <div
                  style="
                    font-weight: 600;
                    font-size: 24px;
                    color: #3c4858;
                    margin-top: 38px;
                    margin-bottom: 22px;
                  "
                >
                  Pastikan headset sudah terhubung ke perangkat
                </div>
                <ul
                  style="color: #8492a6; line-height: 24px; margin-bottom: 95px"
                >
                  <li>
                    Gunakan headseat untuk mendengarkan audio lebih jelas pada
                    saat test berlangsung
                  </li>
                </ul>
                <v-btn
                  depressed
                  large
                  @click="
                    showCheckAudioLayer_1 = false;
                    showCheckAudioLayer_2 = true;
                  "
                  class="mb-7"
                  width="300"
                  color="primary"
                >
                  Lanjutkan Pengecekan
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
                <p class="font-weight-medium color-state" style="bottom: 0px">
                  Mengalami kendala? Hubungi admin SISTEM di WhatsApp
                  <span class="primary--text">0823-2565-5099</span>
                </p>
              </div>
              <!-- Layer 2 -->
              <div v-if="showCheckAudioLayer_2">
                <div
                  style="
                    font-weight: 600;
                    font-size: 24px;
                    color: #3c4858;
                    margin-top: 46px;
                    margin-bottom: 22px;
                  "
                >
                  Menyambungkan Audio Anda
                </div>
                <ul style="color: #8492a6">
                  <li>
                    Proses ini berjalan secara otomatis dan mungkin membutuhkan
                    waktu beberapa saat. Harap menunggu
                  </li>
                  <li>
                    Periksa sambungan headset/speaker jika volume sudah maksimal
                    tetapi tidak terdengar suara
                  </li>
                </ul>
                <div class="text-center mt-10 mb-8">
                  <v-btn
                    ref="audio"
                    elevation="0"
                    color="primary"
                    fab
                    dark
                    @click.prevent="
                      playSound(
                        'https://sgp1.vultrobjects.com/epot/6278dc31bf43be5a2fc41344/audios/1653455839410211811-mixkit-happy-bells-notification-937.wav'
                      )
                    "
                  >
                    <!-- playSound('https://storage.googleapis.com/yec-cdn/5fad2ff6d9c15a03ece5272a/videos/1617006675978954662-whatsapp-audio-2021-03-29-at-15-25-59.mpeg') -->
                    <v-icon>mdi-play</v-icon>
                  </v-btn>
                  <p style="margin-top: 10px; color: #8492a6; font-size: 12px">
                    Play untuk mendengarkan
                  </p>
                </div>
                <v-alert text warning>
                  <span class="fs-14 color-warning-cutsom">
                    Pastikan Anda mendengar audio yang terputar di halaman ini.
                    Atur besar kecil audio dengan kontrol volume di bagian kiri
                    layar. Gunakan headset/earphone untuk pendengaran yang lebih
                    baik.
                  </span>
                </v-alert>
                <v-btn
                  :disabled="!successAudio"
                  depressed
                  large
                  @click="goToCamera()"
                  class="mt-5 mb-7"
                  width="300"
                  color="primary"
                >
                  Lanjutkan Pengecekan
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
                <p class="font-weight-medium color-state" style="bottom: 0px">
                  Mengalami kendala? Hubungi admin SISTEM di WhatsApp
                  <span class="primary--text">0823-2565-5099</span>
                </p>
              </div>
            </div>

            <!-- Camera -->
            <div v-show="showCheckCamera" class="content-check">
              <!-- Layer 1 -->
              <div v-if="showCheckCameraLayer_1">
                <v-img
                  width="360"
                  height="240"
                  :src="require('@/assets/images/detection/ilutration_camera.png')"
                >
                </v-img>
                <div
                  style="
                    font-weight: 600;
                    font-size: 24px;
                    color: #3c4858;
                    margin-top: 38px;
                    margin-bottom: 22px;
                  "
                >
                  Pastikan kamera menghadap ke wajah peserta
                </div>
                <ul style="color: #8492a6; line-height: 24px">
                  <li>
                    Pada saat pengecekan kamera diharapkan ruangan dengan
                    pencahayaan yang cukup
                  </li>
                  <li>
                    Peserta diharapkan menghadap kamera dengan posisi wajah full
                  </li>
                  <li>
                    Pada saat proses pengecekan sistem memerlukan waktu 15 detik
                    untuk mendeteksi kestabilan laptop/ komputer
                  </li>
                </ul>
                <v-btn
                  depressed
                  large
                  @click="goToCameraLayer2()"
                  class="mt-5 mb-7"
                  width="300"
                  color="primary"
                >
                  Lanjutkan Pengecekan
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
                <p class="font-weight-medium color-state" style="bottom: 0px">
                  Mengalami kendala? Hubungi admin SISTEM di WhatsApp
                  <span class="primary--text">0823-2565-5099</span>
                </p>
              </div>

              <!-- Layer 2 -->
              <div v-if="showCheckCameraLayer_2">
                <div v-if="!showGuide">
                  <div
                    style="
                      font-weight: 600;
                      font-size: 24px;
                      color: #3c4858;
                      margin-top: 20px;
                      margin-bottom: 16px;
                    "
                  >
                    {{ response.contentTitle }}
                  </div>
                  <div
                    style="
                      margin-bottom: 10px;
                      font-weight: 500;
                      font-size: 24px;
                    "
                    v-html="response.contentCountdown"
                  ></div>
                  <ul style="color: #8492a6; line-height: 24px">
                    <li>
                      Proses ini berjalan secara otomatis dan mungkin
                      membutuhkan waktu beberapa saat. Harap menunggu.
                    </li>
                  </ul>
                  <img
                    v-show="showIcon"
                    class="mx-auto mb-5 mt-3"
                    width="390"
                    height="200"
                    :src="require('@/assets/images/detection/proses_persiapan.png')"
                  />
                  <div v-show="!showIcon" class="content-check mt-6">
                    <div v-show="showVid">
                      <img
                        v-if="detection_loading == true"
                        :style="
                          response.contentCountdown == ''
                            ? 'position: absolute;object-position: center bottom;left:42%;top: 30%;'
                            : 'position: absolute;object-position: center bottom;left:42%;top: 35%;'
                        "
                        class="mx-auto"
                        width="160"
                        :src="require('@/assets/images/detection/success_detection.png')"
                      />
                      <img
                        v-if="detection_failed == true"
                        :style="
                          response.contentCountdown == ''
                            ? 'position: absolute;object-position: center bottom;left:42%;top: 30%;'
                            : 'position: absolute;object-position: center bottom;left:42%;top: 35%;'
                        "
                        class="mx-auto"
                        width="160"
                        :src="require('@/assets/images/detection/failed_detection.png')"
                      />
                      <img
                        v-if="detection_successful == true"
                        :style="
                          response.contentCountdown == ''
                            ? 'position: absolute;object-position: center bottom;left:42%;top: 30%;'
                            : 'position: absolute;object-position: center bottom;left:42%;top: 35%;'
                        "
                        class="mx-auto"
                        width="160"
                        :src="require('@/assets/images/detection/frame_detection.png')"
                      />
                    </div>
                    <div v-show="showVid" class="my-3">
                      <video
                        v-if="showDetector"
                        ref="video"
                        class="access-video"
                        id="video"
                        width="500"
                        height="250"
                        @loadedmetadata="runModel"
                        autoplay
                      ></video>
                      <video
                        v-else
                        ref="video"
                        class="access-video"
                        id="video"
                        width="500"
                        height="250"
                        autoplay
                      ></video>
                      <canvas ref="canvas" />
                    </div>
                  </div>
                  <v-alert text warning>
                    <span class="fs-14 color-warning-cutsom">
                      Izinkan penggunaan microphone & kamera yang terpasang di
                      laptop/komputer Anda. Tes tidak dapat berjalan jika
                      microphone & kamera dimatikan.
                    </span>
                  </v-alert>
                  <v-btn
                    depressed
                    large
                    class="mb-2"
                    width="300"
                    :loading="process.run"
                    :disabled="isSuccessCamera"
                    @click="goToMic()"
                    color="primary"
                  >
                    <!-- @click="goToTest()" -->
                    <!-- Mulai Test -->
                    Lanjutkan Pengecekan
                    <v-icon right>mdi-arrow-right</v-icon>
                  </v-btn>
                  <p class="font-weight-medium color-state" style="bottom: 0px">
                    Mengalami kendala? Hubungi admin SISTEM di WhatsApp
                    <span class="primary--text">0823-2565-5099</span>
                  </p>
                </div>

                <!-- Show Guide about connected camera -->
                <div v-if="showGuide">
                  <p
                    style="
                      font-weight: 600;
                      font-size: 24px;
                      color: #3c4858;
                      margin-top: 20px;
                      margin-bottom: 16px;
                    "
                  >
                    {{ response.info_message }}
                  </p>
                  <p
                    style="
                      font-weight: 400;
                      font-size: 16px;
                      color: #3c4858;
                      margin-top: 20px;
                      margin-bottom: 16px;
                    "
                  >
                    {{ response.information }}
                  </p>
                  <div
                    class="d-flex justify-center align-center color-disable mb-0 fs-18 mt-10 mb-10"
                    style="line-height: 30px"
                  >
                    <ol type="number" class="text-left">
                      <li>
                        Di kanan atas, Klik menu
                        <v-icon>mdi-dots-vertical</v-icon>
                      </li>
                      <li>Pilih Settings</li>
                      <li>Pilih Privacy and security</li>
                      <li>Pilih Site Settings</li>
                      <li>Pilih situs Amikom English Proficiency Test</li>
                      <li>Permissions Camera</li>
                      <li>Izin Block diubah menjadi Allow</li>
                    </ol>
                  </div>
                  <p
                    class="color-disable mb-0 fs-18"
                    style="white-space: pre-line"
                  >
                    Setelah Permissions berhasil diubah , Reload/Refresh halaman
                    ini.
                  </p>
                  <p class="font-weight-medium color-state" style="bottom: 0px">
                    Mengalami kendala? Hubungi admin SISTEM di WhatsApp
                    <span class="primary--text">0823-2565-5099</span>
                  </p>
                </div>
              </div>
            </div>

            <!-- Microfone -->
            <div v-show="showCheckMic" class="content-check">
              <!-- Layer 1 -->
              <div v-if="showCheckMicLayer_1">
                <v-img
                  width="360"
                  height="240"
                  :src="require('@/assets/images/detection/ilutration_mic.png')"
                >
                </v-img>
                <div
                  style="
                    font-weight: 600;
                    font-size: 24px;
                    color: #3c4858;
                    margin-top: 38px;
                    margin-bottom: 22px;
                  "
                >
                  Pastikan microphone dapat berfungsi dengan baik
                </div>
                <ul style="color: #8492a6; line-height: 24px">
                  <li>
                    Saat test berlangsung pastikan suasana ruangan dalam keadaan
                    tenang
                  </li>
                  <li>
                    Tidak diperkenankan menonaktifkan microphone saat test
                    berlangsung
                  </li>
                </ul>
                <v-btn
                  depressed
                  large
                  @click="goToMicLayer2()"
                  class="mt-5 mb-7"
                  width="300"
                  color="primary"
                >
                  Lanjutkan Pengecekan
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
                <p class="font-weight-medium color-state" style="bottom: 0px">
                  Mengalami kendala? Hubungi admin SISTEM di WhatsApp
                  <span class="primary--text">0823-2565-5099</span>
                </p>
              </div>

              <!-- Layer 2 -->
              <div v-if="showCheckMicLayer_2">
                <div
                  style="
                    font-weight: 600;
                    font-size: 24px;
                    color: #3c4858;
                    margin-top: 20px;
                    margin-bottom: 16px;
                  "
                >
                  {{ response.contentTitle }}
                </div>
                <div
                  style="margin-bottom: 10px; font-weight: 500; font-size: 24px"
                  v-html="response.contentCountdown"
                ></div>
                <!-- <div
                  style="
                    font-weight: 600;
                    font-size: 24px;
                    color: #3C4858;
                    margin-top: 46px;
                    margin-bottom: 22px;">
                  Menyambungkan Microphone Kamu
                </div> -->
                <ul style="color: #8492a6">
                  <li>
                    Proses ini berjalan secara otomatis dan mungkin membutuhkan
                    waktu beberapa saat. Harap menunggu.
                  </li>
                  <li>
                    Periksa sambungan headset/speaker jika volume sudah maksimal
                    tetapi tidak terdengar suara
                  </li>
                </ul>
                <div class="text-center mt-10 mb-5">
                  <speech-to-text
                    :text.sync="text"
                    @speechend="speechEnd"
                    @isconnected="isConnected"
                    @isdetected="isDetected"
                  ></speech-to-text>
                </div>
                <v-alert text warning>
                  <span class="fs-14 color-warning-cutsom">
                    Tes hanya dapat dilakukan di tempat ujian dengan suasana
                    tenang. Utamakan kejujuran dan pastikan Test dikerjakan
                    tanpa bantuan orang lain.
                  </span>
                </v-alert>
                <v-btn
                  depressed
                  large
                  class="mt-3 mb-4"
                  width="300"
                  :disabled="
                    text.toLowerCase().trim() !=
                    'Saya akan melakukan tes dengan baik.'.toLowerCase()
                  "
                  @click="goToTest()"
                  color="primary"
                >
                  Mulai Test
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
                <!-- <div class="d-flex align-center">
                  <div @click="dialog.deviceUnsupported = true; dialog.type = 'microphone'" class="ml-6">
                    <a>Skip Verification</a>
                  </div>
                </div> -->
                <p class="font-weight-medium color-state" style="bottom: 0px">
                  Mengalami kendala? Hubungi admin SISTEM di WhatsApp
                  <span class="primary--text">0823-2565-5099</span>
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { LMS_API } from "@/constants/api"

import * as faceAPI from "face-api.js";
import EpotCheckMic from "@/components/EpotCheckMic.vue";

export default {
  components: {
    "speech-to-text": EpotCheckMic,
  },
  data: () => ({
    successAudio: false,
    checkedAudio: false,
    checkedCamera: false,
    checkedMic: false,
    showCheckAudio: true,
    showCheckCamera: false,
    showCheckMic: false,
    showCheckAudioLayer_1: true,
    showCheckAudioLayer_2: false,
    showCheckCameraLayer_1: false,
    showCheckCameraLayer_2: false,
    showCheckMicLayer_1: false,
    showCheckMicLayer_2: false,
    isSuccessCamera: true,
    isSuccessMic: true,
    isFailMic: true,
    response: {
      contentTitle:
        "Menyambungkan kamera Anda, pastikan wajah anda terlihat di kamera.",
      contentCountdown: "",
      contentCamera: "",
      contentFace: "",
      contentMic: "",
      contentSpeech: "",
      info_message: "",
      information: "",
    },
    showIcon: true,
    showVid: false,
    showGuide: false,
    showDetector: false,
    detection_failed: false,
    detection_loading: false,
    detection_successful: false,
    board: {
      realTimeCountsFace: 0,
      counter_face: 0,
      isUnDetected: false,
    },
    timeleft: 10,
    timeleftMic: 10,
    CountdownTimerCamera: null,
    CountdownTimerMic: null,
    sentences: null,
    text: "",
    snackbar: {
      state: false,
      timeout: "3000",
      text: "",
      color: "",
      icon: "",
    },
    // lembaga: {
    //   active_camera: false,
    // },
    process: {
      run: false,
    },
    dialog: {
      deviceUnsupported: false,
      type: "",
    },
  }),
  created() {
    // window.history.forward();
  },
  watch: {
    "board.realTimeCountsFace": async function (face) {
      console.log(face);
      let _self = this;
      if (face == 0) {
        _self.detection_failed = true;
        _self.detection_loading = false;
        _self.detection_successful = false;
        _self.board.isUnDetected = false;
        _self.snackbar.state = true;
        _self.snackbar.timeout = 3000;
        _self.snackbar.text = "Kamera tidak dapat mendeteksi wajah anda";
        _self.snackbar.color = "red accent-2";
        _self.snackbar.icon = "mdi-information";
        _self.board.counter_face += 1;
      }
      if (face > 1) {
        _self.detection_failed = true;
        _self.detection_loading = false;
        _self.detection_successful = false;
        _self.board.isUnDetected = false;
        _self.snackbar.state = true;
        _self.snackbar.timeout = 3000;
        _self.snackbar.text = "Kamera mendeteksi terdapat lebih dari 1 wajah";
        _self.snackbar.color = "red accent-2";
        _self.snackbar.icon = "mdi-information-circle";
        _self.board.counter_face += 1;
      }

      if (face == 1) {
        _self.detection_failed = false;
        _self.detection_loading = false;
        _self.detection_successful = true;
        _self.snackbar.state = true;
        _self.snackbar.timeout = 3000;
        _self.board.isUnDetected = false;
        _self.snackbar.text = "Kamera berhasil mendeteksi wajah dengan baik";
        _self.snackbar.color = "success";
        _self.snackbar.icon = "mdi-check-circle";
      }
    },
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("sistem_auth"));
    },
    lembaga() {
      return JSON.parse(localStorage.getItem("ytc_lms_institution"));
    },
    initParams() {
      const data = {
        modelUri: "/models",
        option: new faceAPI.SsdMobilenetv1Options({ minConfidence: 0.5 }),
        // option: new faceAPI.TinyFaceDetectorOptions({ inputSize: 320 })
        // option: new faceAPI.MtcnnOptions({minFaceSize: 20})
      };
      return data;
    },
  },
  mounted() {
    // let _self = this;
    // Countdown Timer Camera
  },
  methods: {
    prevent_unload(e) {
      e.preventDefault();
      e.returnValue = "";
    },
    async goToTest() {
      this.process.run = true;
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // check navigator is from iphone
      if (navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Chrome") > -1) {
        this.$router.push({
          name: "detail-class",
          params: { id: this.$route.params.id },
        });
      } else {
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream){
          this.$router.push({ name: 'detail-class-v2', params: { id: this.$route.params.id } })
        } else {
          this.$router.push({ name: 'detail-class-v2', params: { id: this.$route.params.id } })
        }
      }
    },
    async next_test(type) {
      let data = {};
      if (type == "camera") {
        data = {
          use_face_detection: true,
          use_speech_detection: false,
        };
      }

      if (type == "microphone") {
        data = {
          use_face_detection:
            localStorage.getItem("ytc_lms_use_face_detection") == "true"
              ? true
              : false,
          use_speech_detection: true,
        };
      }
      this.process.run = true;
      let license = localStorage.getItem("ytc_lms_license");
      await this.axios
        .put(`${LMS_API.MEMBER.ORDER.UPDATE_SETTING(license)}`, data)
        .then((res) => {
          let data = res.data.data;
          localStorage.setItem(
            "ytc_lms_use_face_detection",
            data.settings.use_face_detection
          );
          localStorage.setItem(
            "ytc_lms_use_speech_detection",
            data.settings.use_speech_detection
          );
          if (type == "camera") {
            this.dialog.deviceUnsupported = false;
            this.process.run = false;
            this.goToMic();
          } else {
            this.axios
              .put(
                `${LMS_API.MEMBER.ORDER.UPDATE_START_TEST_MEMBER(this.$route.params.id)}`,
                {}
              )
              .then(() => {
                this.$route.meta.prevent_move = false;
                window.onbeforeunload = null;
                this.$router.push({
                  name: "epot-listening",
                  params: { id: this.$route.params.id },
                });
                this.process.run = false;
              });
          }
        });
    },
    playSound(sound) {
      if (sound) {
        var audio = new Audio(sound);
        audio.play();
        audio.onended = () => {
          this.successAudio = true;
        };
      }
    },
    goToCamera() {
      this.checkedAudio = true;
      this.checkedMic = false;
      this.showCheckAudio = false;
      this.showCheckCamera = true;
      this.showCheckCameraLayer_1 = true;
      this.showCheckCameraLayer_2 = false;
    },
    async goToMic() {
      this.checkedAudio = true;
      this.checkedCamera = true;
      this.checkedMic = false;
      this.showCheckAudio = false;
      this.showCheckCamera = false;
      this.showCheckCameraLayer_1 = false;
      this.showCheckCameraLayer_2 = false;

      this.showCheckMic = true;
      this.showCheckMicLayer_1 = true;
      this.showCheckMicLayer_2 = false;
    },
    speechEnd({ sentences }) {
      this.text = "";

      this.sentences = sentences;
    },
    // isDetected({MicDetected, text}) {
    //   console.log('text', text);
    //   if (MicDetected && text != "" || this.response.contentCountdown == "") {
    //     this.response.contentSpeech = "Microfone berhasil deteksi suara"
    //     this.response.contentTitle = "Menyambungkan Microphone Kamu";
    //     this.isSuccessMic = false
    //     clearInterval(this.CountdownTimerMic)
    //     this.response.contentCountdown = ""
    //   }else {
    //     this.response.contentSpeech = "Microfone berhasil deteksi suara"
    //     this.response.contentTitle = "Menyambungkan Microphone Kamu";
    //     this.dialog.deviceUnsupported = true
    //     this.dialog.type = "microphone"
    //     clearInterval(this.CountdownTimerMic)
    //     this.response.contentCountdown = ""
    //   }
    // },
    isDetected({ MicDetected, text }) {
      if (MicDetected && text != "") {
        this.response.contentSpeech = "Microfone berhasil deteksi suara";
        this.isSuccessMic = false;
        // clearInterval(this.CountdownTimerMic)
      } else {
        // this.dialog.deviceUnsupported = true
        // this.dialog.type = "microphone"
        // clearInterval(this.CountdownTimerMic)
      }
    },
    isConnected({ MicConnected }) {
      if (MicConnected) {
        this.response.contentMic = "Microphone berhasil tersambung";
      }

      let _self = this;

      _self.CountdownTimerMic = setInterval(function () {
        if (_self.timeleftMic <= 0) {
          clearInterval(_self.CountdownTimerMic);
          _self.response.contentTitle = "Menyambungkan Microphone Anda";
          _self.response.contentCountdown = "";
          _self.isSuccessCamera = false;
          if (_self.isSuccessMic) {
            _self.dialog.deviceUnsupported = true;
            _self.dialog.type = "microphone";
          }
        } else {
          _self.response.contentTitle =
            "Sistem memerlukan waktu untuk menyambungkan microfone Anda";
          _self.response.contentCountdown = `<span style="color: #652065">${_self.timeleftMic}</span> detik`;
        }
        _self.timeleftMic -= 1;
      }, 1000);
    },
    async goToCameraLayer2() {
      this.showCheckCamera = true;
      this.showCheckCameraLayer_1 = false;
      this.showCheckCameraLayer_2 = true;
      this.response.contentCamera = "Proses pengecekan camera...";
      await this.initModel();
    },
    async goToMicLayer2() {
      this.showCheckMicLayer_1 = false;
      this.showCheckMicLayer_2 = true;
      this.isSuccessMic = false;
      this.response.contentTitle = "Menyambungkan Microphone Anda";
    },
    async initModel() {
      await faceAPI.nets.ssdMobilenetv1.loadFromUri(this.initParams.modelUri);
      await this.startStream();
    },
    async startStream() {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((mediaStream) => {
          if (mediaStream) {
            this.$refs.video.srcObject = mediaStream;
            this.$refs.video.play();
            this.response.contentCamera = "Kamera berhasil tersambung";
            // this.response = "Berhasil menyambungkan perangkat anda";
            // this.info_message = "Pastikan wajah anda terlihat di kamera dan tidak ada orang lain selama tes berlangsung"
            this.showVid = true;
            this.showIcon = false;

            this.showDetector = true;
            this.detection_loading = false;
            this.detection_failed = false;
            this.detection_successful = true;
            this.response.contentFace = "Proses mendeteksi wajah...";
            this.runModel();
            let _self = this;
            _self.CountdownTimerCamera = setInterval(function () {
              if (_self.timeleft <= 0) {
                clearInterval(_self.CountdownTimerCamera);
                _self.response.contentTitle = "Menyambungkan kamera Anda";
                _self.response.contentCountdown = "";
                _self.isSuccessCamera = false;
                if (_self.board.counter_face >= 3 || _self.board.isUnDetected) {
                  _self.dialog.deviceUnsupported = true;
                  _self.dialog.type = "camera";
                }
              } else {
                _self.response.contentTitle =
                  "Sistem memerlukan waktu untuk menyambungkan kamera";
                _self.response.contentCountdown = `<span style="color: #652065">${_self.timeleft}</span> detik`;
              }
              _self.timeleft -= 1;
            }, 1000);
          } else {
            console.log("here not camera");
          }
        })
        .catch((error) => {
          this.response.contentCamera = "Kamera gagal tersambung";
          this.response.info_message = "Gagal menyambungkan perangkat anda";
          this.response.information =
            "Silahkan Cek Kembali Permission Access Device di browser Anda.";
          this.showGuide = true;
          this.showIcon = false;
          console.error("getUserMedia() error:", error);
        });

      const CAMERA = true;
      // # IF User B2B
      if (CAMERA) {
        this.detection_loading = true;
        if (CAMERA) {
          // this.$route.meta.prevent_move = true;
        } else {
          this.isSuccessCamera = false;
          this.detection_loading = true;
          this.detection_failed = false;
          this.detection_successful = false;
          // this.response ="Anda diperbolehkan tidak menggunakan kamera.";
          this.response.contentCamera = "Kamera tidak di wajibkan";
          this.showVid = true;
          this.showIcon = false;
          // setTimeout(() => {
          //   this.axios.put('/users/v1/member/order/update-start-test/'+this.$route.params.id, {}).then(() => {
          //     window.onbeforeunload = null;
          //     this.$router.push({ name: "epot-listening", params: { id: this.$route.params.id } });
          //   })
          // }, 10000);
        }
      }

      if (this.user.is_b2b == 0) {
        this.showDetector = true;
        this.detection_loading = true;
        this.$route.meta.prevent_move = true;
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((mediaStream) => {
            this.$refs.video.srcObject = mediaStream;
            this.$refs.video.play();
            this.response.contentCamera = "Kamera berhasil tersambung";
            // this.response = "Berhasil menyambungkan perangkat anda";
            // this.info_message = "Pastikan wajah anda terlihat di kamera dan tidak ada orang lain selama tes berlangsung"
            this.showVid = true;
            this.showIcon = false;
            this.showDetector = true;
            this.detection_loading = false;
            this.detection_failed = false;
            this.detection_successful = true;
            this.response.contentFace = "Proses mendeteksi wajah...";
            this.runModel();
            let _self = this;
            _self.CountdownTimerCamera = setInterval(function () {
              if (_self.timeleft <= 0) {
                clearInterval(_self.CountdownTimerCamera);
                _self.response.contentTitle = "Menyambungkan kamera Anda";
                _self.response.contentCountdown = "";
                _self.isSuccessCamera = false;
                if (_self.board.counter_face >= 3 || _self.board.isUnDetected) {
                  _self.dialog.deviceUnsupported = true;
                  _self.dialog.type = "camera";
                }
              } else {
                _self.response.contentTitle =
                  "Sistem memerlukan waktu untuk Menyambungkan kamera";
                _self.response.contentCountdown = `<span style="color: #652065">${_self.timeleft}</span> detik`;
              }
              _self.timeleft -= 1;
            }, 1000);
          })
          .catch((error) => {
            this.response.contentCamera = "Kamera gagal tersambung";
            this.response.info_message = "Gagal menyambungkan perangkat anda";
            this.response.information =
              "Silahkan Cek Kembali Permission Access Device di browser Anda.";
            this.showGuide = true;
            this.showIcon = false;
            this.detection_loading = false;
            this.detection_failed = true;
            this.detection_successful = false;
            console.error("getUserMedia() error:", error);
          });
      }
    },
    async runModel() {
      const result = await faceAPI.detectAllFaces(
        this.$refs.video,
        this.initParams.option
      );

      let canvas = this.$refs.canvas;
      let ctx = canvas.getContext("2d");
      let vid = this.$refs.video;

      canvas.width = vid.width;
      canvas.height = vid.height;
      ctx.drawImage(vid, 0, 0, canvas.width, canvas.height);

      if (result) {
        const dims = faceAPI.matchDimensions(canvas, this.$refs.video, true);
        const resizeResults = faceAPI.resizeResults(result, dims);
        this.board.realTimeCountsFace = resizeResults.length;
        if (this.board.realTimeCountsFace == 0) {
          this.snackbar.state = true;
          this.snackbar.timeout = 3000;
          this.snackbar.text = "Kamera tidak dapat mendeteksi wajah anda";
          this.snackbar.color = "red accent-2";
          this.snackbar.icon = "mdi-information";
          this.board.isUnDetected = true;
          this.detection_loading = false;
          this.detection_failed = true;
          this.detection_successful = false;
        }
        if (this.board.realTimeCountsFace > 1) {
          this.snackbar.state = true;
          this.snackbar.timeout = 3000;
          this.snackbar.text = "Kamera mendeteksi terdapat lebih dari 1 wajah";
          this.snackbar.color = "red accent-2";
          this.snackbar.icon = "mdi-information-circle";
          this.detection_loading = false;
          this.detection_failed = true;
          this.detection_successful = false;
        }
        this.response.contentFace = "Pendeteksi wajah Berhasil";
        // faceAPI.draw.drawDetections(canvas, resizeResults)
        // faceAPI.draw.drawFaceLandmarks(canvas, resizeResults)
      }
      setTimeout(() => this.runModel());
    },
  },
};
</script>

<style scoped>
.v-progress-circular {
  margin: 0.5rem;
}

.color-warning-cutsom {
  color: #8c7913 !important;
}

.icon-cek {
  height: 200px;
  padding: 10px;
  position: relative;
}

.pst {
  position: absolute;
  right: 0;
  left: 0;
  top: 40%;
}

.content-check .v-alert--text:before {
  background-color: #fefbd8 !important;
}

.content-check .v-alert--text {
  background: #fefbd8 !important;
}

.pst .v-progress-circular__underlay {
  stroke: transparent !important;
  z-index: 1;
}

.access-video {
  background-color: #000;
  border-radius: 5px;
  /* object-fit: cover; */
  /*  position: fixed;
  right: 0;
  bottom: 5%;*/
}

canvas {
  position: absolute;
  right: 28%;
  z-index: 10;
  width: 500px;
  height: 250px;
}
</style>
