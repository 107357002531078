<template>
  <v-row align-content="center">
    <Snackbar ref="snackbar" />

    <div v-if="isLoadingConfirm" class="d-flex flex-column justify-center align-center mx-auto" style="height: 100vh; width: 100vw;">
      <v-progress-circular
        :size="150"
        :width="10"
        color="primary"
        indeterminate>
      </v-progress-circular>
      <div class="mt-10">{{ message ? message : "Mengkonfirmasi token Anda untuk selanjutnya melakukan setting password" }}</div>
    </div>
    <v-row v-else no-gutters align-content="center">
      <v-col class="white pa-0">

        <v-row justify="center" align="center" :style="$vuetify.breakpoint.name == 'xs' ? '' : 'height: 100vh;'">
          <v-col cols="12" md="8" :class="$vuetify.breakpoint.name == 'xs' ? 'px-8 mt-6' : ''">
            <div class="fs-24 font-weight-bold">Atur Ulang Password Anda</div>
            <div class="fs-14 text-gray-500">Silahkan atur ulang password Anda</div>

            <AuthSetPassword :type-form="showAuth" />

          </v-col>
        </v-row>

      </v-col>
      <v-col class="pa-0" :order="$vuetify.breakpoint.name == 'xs' ? 'first': 'last'">
        <v-img
          style="object-fit: cover; object-position: center;"
          :style="$vuetify.breakpoint.name == 'xs' ? 'width: 100vw;' : 'height: 100vh;'"
          :src="require(`@/assets/images/${$vuetify.breakpoint.name == 'xs' ? 'bg-register-mobile.png' : 'bg-login.png'}`)">
        </v-img>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import { LMS_API } from "@/constants/api";

import AuthSetPassword from "./_components/AuthSetPassword.vue";
import Snackbar from "@/components/Snackbar";

export default {
  name: "login",
  components: {
    AuthSetPassword,
    Snackbar
  },
  data() {
    return {
      showAuth: "user",
      isLoading: false,
      message: "",

      isLoadingConfirm: false,
    };
  },
  created() {},
  computed: {},
  mounted(){
    if (this.$route.params.token) {
      this.setToken();
    }
  },
  methods: {
    async setToken() {
      this.isLoadingConfirm = true;
      
      await this.axios.post(`${LMS_API.AUTH.VERIFY_TOKEN}`, {
        token: this.$route.params.token
      }).then(() => {
        this.$refs.snackbar.open(`success`, "Token berhasil dikonfirmasi");
        this.isLoadingConfirm = false;
      }).catch((error) => {
        this.$refs.snackbar.open(`error`, `${error.response.data.message}, anda akan dialihkan ke halaman login`);

        this.message = `${error.response.data.message}, anda akan dialihkan ke halaman login`;

        setTimeout(() => {
          this.$router.push({ name: "login" });
          this.isLoadingConfirm = false;
        }, 5000);

      });
    },
  },
};
</script>

<style>

.bg-login {
  background-image: url("../../assets/images/bg-login.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.google-signin-button {
  color: white;
  background-color: red;
  height: 50px;
  font-size: 16px;
  border-radius: 10px;
  padding: 10px 20px 25px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
#line {
  width: 100%;
  height: max-content;
  text-align: center;
  position: relative;
}

#line:after {
  content: "";
  width: 100%;
  border-bottom: solid 1px #e0e6ed;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 1;
}

#line span {
  width: auto;
  background: #fff;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}
</style>
