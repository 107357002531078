<template>
  <v-row no-gutters align-content="center" class="pa-0">
    <v-col class="white pa-0">

      <v-row justify="center" align="center" :style="$vuetify.breakpoint.name == 'xs' ? '' : 'height: 100vh;'">
        <v-col cols="12" md="8" :class="$vuetify.breakpoint.name == 'xs' ? 'px-8 mt-6' : ''">
          <div class="fs-24 font-weight-bold">Lupa Password</div>
          <div class="fs-14 text-gray-500">Silahkan isikan email yang terdaftar</div>

          <AuthForgotPassword />

        </v-col>
      </v-row>

    </v-col>
    <v-col class="pa-0" :order="$vuetify.breakpoint.name == 'xs' ? 'first': 'last'">
      <v-img
        style="object-fit: cover; object-position: center;"
        :style="$vuetify.breakpoint.name == 'xs' ? 'width: 100vw;' : 'height: 100vh;'"
        :src="require(`@/assets/images/${$vuetify.breakpoint.name == 'xs' ? 'bg-register-mobile.png' : 'bg-login.png'}`)">
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {
    AuthForgotPassword: () => import('@/views/auth/_components/AuthForgotPassword.vue')
  }
}
</script>