<template>
	<v-row :no-gutters="$vuetify.breakpoint.smAndUp">
		<div class="font-weight-bold primary-text mb-4 text-h5 pl-4">{{ act.title }}</div>
		<v-card elevation="0" class="col-md-12 mx-4 rounded-lg" outlined>
			<div class="pa-4">
				<div class="pa-4">
					<p class="text-body-1 text-justify mb-4" v-html="this.act.theory.description"></p>
					<iframe v-if="this.act.theory.link_embed.url != ''" :src="this.act.theory.link_embed.url" style="height: 500px;border-style: hidden; width: 100%;"></iframe>
				</div>
			</div>
		</v-card>
	</v-row>
</template>

<script>
export default {
	props: {
		act: Object
	},
	data() {
		return {
		}
	}
}
</script>