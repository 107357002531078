import { LMS_API, JOB_PORTAL_API } from "@/constants/api"

import axios from "axios";

import store from ".";
// import url from "./api";

// const offline = true

const state = () => ({
  user: null,
  user_sistem: null,
  token: "",
  status: "",
});

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_USER_LOGIN(state, user) {
    state.user = user;
  },
  SET_USER_SISTEM(state, user_sistem) {
    state.user_sistem = user_sistem;
  },
  RESET_USER(state) {
    state.user = null;
    state.user_sistem = null;
  },
  SET_STATUS(state, status) {
    state.status = status;
  },
};

const actions = {
  async login({ commit }, user) {
    user.access_key = process.env.VUE_APP_ACCESS_KEY;
    user.provider = "email";

    return axios
      .post(`${LMS_API.AUTH.LOGIN}`, user, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => res.data)
      .then(async (res) => {
          let response = res.data;

          let token = `Bearer ${response.token}`;

          axios.defaults.headers.common["Authorization"] = token;
          
          localStorage.setItem("sistem_token", response.token);
          commit("SET_TOKEN", token);
          commit("SET_USER_LOGIN", {
            ...user,
            ...response
          });

          await store.dispatch("auth/get_user_sistem")
          await store.dispatch("auth/get_user")
          
          // return "success";
          return res.data;
      });
  },
  async logout({ commit }) {
    return new Promise((resolve) => {
      commit("RESET_USER");
      localStorage.removeItem("sistem_auth");
      localStorage.removeItem("bakat_auth");
      localStorage.removeItem("sistem_token");
      localStorage.clear();
      delete axios.defaults.headers.common["Authorization"];
      resolve("logged out");
    });
  },

  async get_user({ commit }) {
    // let user = JSON.parse(localStorage.getItem("bakat_auth"));
    let _token = localStorage.getItem("sistem_token");

    // if (!user) {
    //   localStorage.removeItem("bakat_auth");
    //   return;
    // }

    let token = _token;
    let bearer = `Bearer ${token}`;

    axios.defaults.headers.common["Authorization"] = bearer;

    return axios
      .get(`${LMS_API.USER.GET_ME}`)
      .then((res) => res.data)
      .then((res) => {
        let user = res.data;

        localStorage.setItem("bakat_auth", JSON.stringify(user));
        commit("SET_USER_LOGIN", user);
      })
      .catch((error) => {
        commit("RESET_USER");
        localStorage.removeItem("bakat_auth");
        delete axios.defaults.headers.common["Authorization"];
        console.error(error);
      });
  },

  async get_user_sistem({ commit }) {
    // let user_sistem = JSON.parse(localStorage.getItem("sistem_auth"));
    let _token = localStorage.getItem("sistem_token");

    let token = _token;
    let bearer = `Bearer ${token}`;

    axios.defaults.headers.common["Authorization"] = bearer;

    return axios
      .get(`${JOB_PORTAL_API.USER.GET_ME}`)
      .then((res) => res.data)
      .then((res) => {

        let response = res;

        response.token = token

        localStorage.setItem("sistem_auth", JSON.stringify(response));
        commit("SET_USER_SISTEM", response);
      })
      .catch((error) => {
        commit("RESET_USER");
        localStorage.removeItem("sistem_auth");
        delete axios.defaults.headers.common["Authorization"];
        console.error(error);
      });
  },
};

const getters = {
  user: (state) => state.user,
  user_sistem: (state) => state.user_sistem,
  isLoggedIn: (state) => !!state.user_sistem && !!state.user_sistem.token,
};

export default { state, mutations, actions, getters };
