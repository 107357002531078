<template>
  <v-form
    class="mt-6"
    @submit.prevent="submit"
    lazy-validation
    ref="form"
    v-model="valid">

    <Snackbar ref="snackbar" />

    <!-- <div>
      <div class="my-2 font-weight-medium">Token</div>
      <v-text-field
        v-model="$route.params.token"
        placeholder="Input Email"
        class="rounded-lg"
        dense
        disabled
        required
        outlined
        hide-details="auto">
      </v-text-field>
    </div> -->

    <div>
      <div class="my-2 font-weight-medium">Password</div>
      <v-text-field
        v-model="form.password"
        placeholder="Input Password"
        :rules="passwordRules"
        class="rounded-lg"
        dense
        required
        outlined
        :append-icon="showPassword ? 'ri-eye-off-line' : 'ri-eye-line'"
        @click:append="showPassword = !showPassword"
        :type="showPassword ? 'text' : 'password'"
        hide-details="auto">
      </v-text-field>
    </div>

    <div>
      <div class="my-2 font-weight-medium">Konfirmasi Password</div>
      <v-text-field
        v-model="form.conf_password"
        placeholder="Input Konfirmasi Password"
        :rules="confPasswordRules"
        class="rounded-lg"
        dense
        required
        outlined
        :append-icon="showConfirmPassword ? 'ri-eye-off-line' : 'ri-eye-line'"
        @click:append="showConfirmPassword = !showConfirmPassword"
        :type="showConfirmPassword ? 'text' : 'password'"
        hide-details="auto">
      </v-text-field>
    </div>

    <v-alert
      v-if="response.message"
      dense
      text
      class="mt-3"
      :type="response.type"
      v-html="response.message">
    </v-alert>

    <v-btn
      class="mt-8 rounded-lg"
      type="submit"
      color="primary"
      block
      small
      depressed
      :loading="process.run"
      :disabled="process.run">
      Konfirmasi Set Password
    </v-btn>

  </v-form>
</template>

<script>
import { LMS_API } from "@/constants/api";

import { mapGetters } from "vuex";
import Snackbar from "@/components/Snackbar";

export default {
  name: "auth-set-password",
  components: {
    Snackbar
  },
  props: {
    typeForm: {
      type: String,
      default: "user"
    }
  },
  data() {
    return {
      isLoadingConfirm: true,

      valid: true,
      form: {
        password: "",
        conf_password: "",
        token: ""
      },
      showPassword: false,
      showConfirmPassword: false,
      process: {
        run: false
      },
      emailRules: [
        (v) => !!v || "Email tidak boleh kosong",
        (v) => /.+@.+\..+/.test(v) || "Email tidak valid",
      ],
      passwordRules: [
        (v) => !!v || "Password tidak boleh kosong",
        (v) => (v && v.length >= 8) || "Password harus minimal 8 karakter",
        (v) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/.test(v) || "Password harus mengandung huruf besar, huruf kecil, dan angka",
      ],
      confPasswordRules: [
        (v) => !!v || "Konfirmasi Password tidak boleh kosong",
        (v) => (v && v.length >= 8) || "Konfirmasi Password harus minimal 8 karakter",
        (v) => v === this.form.password || "Konfirmasi Password tidak sama dengan password baru",
      ],

      response: {
        message: "",
        type: "",
      }
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["user_sistem"]),
  },
  mounted() {},
  methods: {
    async submit() {
      this.process.run = true;
      if (this.$refs.form.validate()) {
        let password = this.form.password;
        let conf_password = this.form.conf_password;
        let token = this.$route.params.token;

        await this.axios.post(`${LMS_API.AUTH.SET_PASSWORD}`, {
          password,
          conf_password,
          token
        }).then(async () => {
          this.process.run = false;
          this.$refs.snackbar.open(`success`, "Password berhasil diubah, anda akan dialihkan ke halaman login");

          setTimeout(() => {
            this.$router.push({ name: "login" });
          }, 3000);
        }).catch((error) => {
          this.process.run = false;
          this.$refs.snackbar.open(`error`, error.response.data.message);

          Object.keys(error.response.data.validation).forEach((key) => {
            this.$refs.snackbar.open(`error`, `${error.response.data.validation[key]} \n`);

            this.response.message += `${error.response.data.validation[key]} \n`;
            this.response.type = "error";
          });
        })
      }else {
        this.process.run = false;
      }
    },
  }
};
</script>