<template>
   <div 
    v-if="user_sistem.talent_organization_experiences.length > 0"
    style="
      margin-top: 2rem;
      margin-bottom: 2rem;
      border: 1px solid #E5E7EB; 
      border-radius: 10px;
      font-family: 'Inter', sans-serif;">
    <div 
      style="
        font-size: 18px; 
        font-weight: 600; 
        padding: 20px;
      ">
      Pengalaman Organisasi
    </div>
    <v-divider></v-divider>
    <div v-for="(organization, i) in user_sistem.talent_organization_experiences" :key="i">
      <div 
        style="
          display: grid;
          grid-template-columns: 1fr 2fr;
          /* justify-content: start;
          align-items: start;
          align-content: center; */
          gap: 15px;
          padding: 20px;
        ">
        <div 
          style="
            display: flex;
            justify-content: start;
            align-items: center;
            align-content: center;
            gap: 15px;
          ">
          <div 
            style="
              font-size: 12px;
              color: #374151;
            ">
            {{ convertDateToMonth(organization.talent_organization_experience_start_date) }} - {{ organization.talent_organization_experience_is_currently_working ? "Saat ini" : convertDateToMonth(organization.talent_organization_experience_end_date) }}
          </div>
        </div>
        <div 
          style="
            display: flex;
            justify-content: start;
            align-items: center;
            align-content: center;
            gap: 15px;
          ">
          <div>
            <div style="font-weight: 600; font-size: 14px">{{ organization.talent_organization_experience_organization_name }}</div>
            <div style="font-size: 12px; color: #374151;">{{ organization.talent_organization_experience_position }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'AccountResumeOrganizations',
  computed: {
    ...mapGetters(['user_sistem'])
  },
  methods: {
    convertDateToMonth(date) {
      return date ? moment(date).locale("id").format("MMMM YYYY") : "No Date"
    },
  }
}
</script>