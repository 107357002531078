<template>
  <div 
    v-if="user_sistem.talent_work_experiences.length > 0"
    style="
      margin-top: 2rem;
      margin-bottom: 2rem;
      border: 1px solid #E5E7EB; 
      border-radius: 10px;
      font-family: 'Inter', sans-serif;">
    <div 
      style="
        font-size: 18px; 
        font-weight: 600; 
        padding: 20px;
      ">
      Pengalaman Kerja
    </div>
    <v-divider></v-divider>
    <div v-for="(workExperience, i) in user_sistem.talent_work_experiences" :key="i">
      <div 
        style="
          display: grid;
          grid-template-columns: 1fr 2fr;
          /* justify-content: start;
          align-items: start; */
          gap: 15px;
          padding: 20px;
        ">
        <div 
          style="
            display: flex;
            grid-column: span 1 / span 2;
            justify-content: start;
            align-items: center;
            gap: 15px;
          ">
          <div 
            style="
              font-size: 12px;
              color: #374151;
            ">
            {{  convertDateToMonth(workExperience.talent_work_experience_start_date) }} - {{ workExperience.talent_work_experience_is_currently_working ? "Saat ini" : convertDateToMonth(workExperience.talent_work_experience_end_date) }}
          </div>
        </div>
        <div 
          style="
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 15px;
          ">
          <div>
            <div style="font-weight: 600; font-size: 14px">{{ workExperience.talent_work_experience_company_name }}</div>
            <div style="font-size: 12px; color: #374151;">{{ workExperience.talent_work_experience_position }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'AccountResumeExperiences',
  computed: {
    ...mapGetters(['user_sistem'])
  },
  methods: {
    convertDateToMonth(date) {
      return date ? moment(date).locale("id").format("MMMM YYYY") : "No Date"
    }
  }
}
</script>